import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { ConnectionFragment } from '../../../../graphql/generated';
import { LoadingScreen } from '../../../components/loading-screen';
import {
  RecordStateDispatcher,
  useRecordState,
} from '../../../hooks/use-record-state';
import { usePortalProgressionStore } from '../../../shared/portal-progression-provider';
import { graphql } from '../../../utils/graphql';
import { useUpdateConnection } from '../hooks/use-update-connection';
import { SelectedConfiguration } from '../interfaces/selected-configuration';
import { SsoFormMetadata } from '../interfaces/sso-form-metadata';

export interface SsoStoreProviderProps {
  children: React.ReactNode;
  initialConnection?: ConnectionFragment;
  initialSelectedConfiguration?: SelectedConfiguration;
}

export type SsoStore = {
  connection: ConnectionFragment;
  selectedConfiguration: SelectedConfiguration;
};

type SsoStoreContext = SsoStore & {
  setSsoStore: RecordStateDispatcher<SsoStore>;
  updateConnection: (formData: SsoFormMetadata) => Promise<void>;
};

const SsoContext = createContext<SsoStoreContext | null>(null);

export const useSsoStore = () => {
  const context = useContext(SsoContext);

  if (!context) {
    throw new Error('useSsoStore can only be used within SsoContext');
  }

  return context;
};

export const SsoStoreProvider: FC<Readonly<SsoStoreProviderProps>> = ({
  children,
  initialConnection,
  initialSelectedConfiguration,
}) => {
  const { setPortalProgressionStore } = usePortalProgressionStore();

  const [ssoStore, setSsoStore] = useRecordState<SsoStore>({
    connection: initialConnection,
    selectedConfiguration: initialSelectedConfiguration || 'dynamic',
  });

  const [updateState, updateConnection] = useUpdateConnection({
    ssoStore,
    setSsoStore,
  });

  const [loadingConnection, setLoadingConnection] = useState(
    !initialConnection,
  );

  useEffect(() => {
    const loadConnection = async () => {
      const { data } = await graphql().Connections();

      const [connection] =
        data?.portal_connections.data.filter(
          (connection) => connection.__typename === 'portal_Connection',
        ) ?? [];

      if (connection && connection.__typename === 'portal_Connection') {
        setSsoStore({
          connection,
        });

        setPortalProgressionStore({
          portalProgression: connection.portalProgression,
        });
      }

      if (data) {
        setLoadingConnection(false);
      }
    };

    if (!initialConnection) {
      void loadConnection();
    }
  }, [initialConnection, setPortalProgressionStore, setSsoStore]);

  useEffect(() => {
    if (initialConnection) {
      setPortalProgressionStore({
        portalProgression: initialConnection.portalProgression,
      });
    }
  }, [initialConnection, setPortalProgressionStore]);

  const context = useMemo<SsoStoreContext>(
    () => ({ ...ssoStore, setSsoStore, updateConnection }),
    [ssoStore, setSsoStore, updateConnection],
  );

  if (loadingConnection) {
    return <LoadingScreen />;
  }

  if (
    updateState.type === 'failed' &&
    updateState.value?.errorCode === 'ConnectionNotFound'
  ) {
    return <Navigate replace to="/not-found" />;
  }
  return <SsoContext.Provider value={context}>{children}</SsoContext.Provider>;
};

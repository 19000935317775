import { Button, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../card';
import { Feedback } from '../feedback';

interface NextStepProps {
  buttonText?: string;
  disabled?: boolean;
  isLoading?: boolean;
  label: string;
  onClick?: () => void;
}

export const NextStep: FC<Readonly<NextStepProps>> = ({
  buttonText = 'Continue to Next Step',
  disabled,
  isLoading,
  label,
  onClick: handleClick,
}) => (
  <div>
    <Card>
      <div className="flex items-center justify-between">
        <Text as="p">{label}</Text>
        <Button disabled={disabled} isLoading={isLoading} onClick={handleClick}>
          {buttonText}
        </Button>
      </div>
    </Card>
    <Feedback />
  </div>
);

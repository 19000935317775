import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've added the WorkOS App integration and entered the External Key.">
      <Text>
        In your Okta Admin Dashboard, click on "Applications" in the sidebar.
        Then, select "Browse App Catalog", search for "WorkOS", and click on the
        application.
      </Text>

      <Img
        className="my-8"
        height={1750}
        src="/images/b1d045d0-7529-4b94-9a21-9e0df7c88274.png"
        width={2860}
      />

      <Text>Click "Add Integration".</Text>

      <Img
        className="my-8"
        height={1750}
        src="/images/a9302954-4078-4e6c-86be-594957d5cdb4.png"
        width={2860}
      />

      <Text>
        Enter a label for the WorkOS app integration, then click "Done".
      </Text>

      <Img
        className="my-8"
        height={2090}
        src="/images/c07d66d2-2134-4b11-9dd6-d56ad45ba6e1.png"
        width={2826}
      />

      <Text>
        On the next page, click on the "Sign On" tab, and then "Edit". Enter the
        "External Key" below into the matching input field in the Sign On
        settings for the integration. Finally, click "Save".
      </Text>

      <CopyInput
        id="connection-external-key"
        label="Copy WorkOS External Key"
        value={connection.externalKey}
      />

      <Img
        className="my-8"
        height={2732}
        src="/images/728e7a9d-3fc7-4427-bdea-75cac76ba70e.png"
        width={3068}
      />
    </StepContent>
  );
};

export const createWorkosOinAppIntegration: StepData = {
  title: 'Add WorkOS App Integration',
  component: Component,
};

import { DirectoryFragment } from '../../../../graphql/generated';
import { RecordStateDispatcher } from '../../../hooks/use-record-state';
import { PortalProgressionStore } from '../../../shared/portal-progression-provider';
import { FormDataV1 } from '../../../shared/types';
import { graphql } from '../../../utils/graphql';

export const setDirectoryPortalProgression = async ({
  directory,
  currentStepIdentifier,
  formData,
  lastCompletedStepNumber,
  setPortalProgressionStore,
}: {
  directory: DirectoryFragment;
  currentStepIdentifier: string;
  formData: FormDataV1 | undefined;
  lastCompletedStepNumber: number;
  setPortalProgressionStore: RecordStateDispatcher<PortalProgressionStore>;
}): Promise<void> => {
  const { data } = await graphql().SetDirectoryPortalProgression({
    input: {
      directoryId: directory.id,
      currentStepIdentifier,
      lastCompletedStepNumber,
      formData,
    },
  });

  if (
    data?.portal_setDirectoryPortalProgression.__typename ===
    'PortalProgressionSet'
  ) {
    setPortalProgressionStore({
      portalProgression:
        data.portal_setDirectoryPortalProgression.portalProgression,
    });
  }
};

import { ConnectionFragment } from '../../../../graphql/generated';
import { RecordStateDispatcher } from '../../../hooks/use-record-state';
import { PortalProgressionStore } from '../../../shared/portal-progression-provider';
import { FormDataV1 } from '../../../shared/types';
import { graphql } from '../../../utils/graphql';

export const setConnectionPortalProgression = async ({
  connection,
  currentStepIdentifier,
  formData,
  lastCompletedStepNumber,
  setPortalProgressionStore,
}: {
  connection: ConnectionFragment;
  currentStepIdentifier: string;
  formData: FormDataV1 | undefined;
  lastCompletedStepNumber: number;
  setPortalProgressionStore: RecordStateDispatcher<PortalProgressionStore>;
}): Promise<void> => {
  const { data } = await graphql().SetConnectionPortalProgression({
    input: {
      connectionId: connection.id,
      currentStepIdentifier,
      lastCompletedStepNumber,
      formData,
    },
  });

  if (
    data?.portal_setConnectionPortalProgression.__typename ===
    'PortalProgressionSet'
  ) {
    setPortalProgressionStore({
      portalProgression:
        data.portal_setConnectionPortalProgression.portalProgression,
    });
  }
};

import { InputGroup } from '@workos-inc/component-library';
import { unreachable } from '@workos-inc/standard';
import { PropsWithChildren } from 'react';
import { SelectedConfiguration } from '../../../../interfaces/step-props';
import { Card } from '../../../components/card';
import { Feedback } from '../../../components/feedback';
import { Form } from '../../../components/form';
import { useFormContext } from '../../../shared/form-data-provider/form-data-provider';
import { useStepsStore } from '../../../shared/step-navigation/steps-store-provider';
import { SsoFormMetadata } from '../interfaces/sso-form-metadata';
import { useSsoStore } from '../sso-store-provider';
import { ManualMetadataStep } from './manual-metadata-step';

export interface MetadataUrlStepProps {
  metadataUrlPlaceholder: string;
  idpEntityIdPlaceholder: string;
  idpSsoUrlPlaceholder: string;
}

export const MetadataUrlStep = <TFormData extends SsoFormMetadata>({
  children,
  idpEntityIdPlaceholder,
  idpSsoUrlPlaceholder,
  metadataUrlPlaceholder,
}: PropsWithChildren<MetadataUrlStepProps>) => {
  const { selectedConfiguration, setSsoStore } = useSsoStore();

  const { handleInputChange, handleNextStep, isUpdating } = useStepsStore();
  const { formData, formErrors } = useFormContext<TFormData>();

  const setSelectedConfiguration = (
    selectedConfiguration: SelectedConfiguration,
  ) => setSsoStore({ selectedConfiguration });

  const metadataUrlBaseJsx = (() => {
    switch (selectedConfiguration) {
      case 'dynamic':
        return (
          <>
            {children}

            <Card>
              <Form
                disabled={!formData?.saml_idp_metadata_url}
                isLoading={isUpdating}
                isUpdate={!!formErrors?.saml_idp_metadata_url}
                onSubmit={handleNextStep}
                secondaryAction={() => setSelectedConfiguration('manual')}
                secondaryText="Configure Manually"
              >
                <InputGroup
                  data-testid="saml-idp-metadata-url-input"
                  error={formErrors?.saml_idp_metadata_url?.message}
                  id="saml_idp_metadata_url"
                  label="IdP Metadata URL"
                  name="saml_idp_metadata_url"
                  onChange={handleInputChange}
                  placeholder={metadataUrlPlaceholder}
                  value={formData?.saml_idp_metadata_url ?? ''}
                />
              </Form>
            </Card>
          </>
        );
      case 'manual':
        return (
          <ManualMetadataStep<TFormData>
            idpEntityIdPlaceholder={idpEntityIdPlaceholder}
            idpSsoUrlPlaceholder={idpSsoUrlPlaceholder}
            onConfigurationTypeChange={(selectedConfiguration) =>
              setSelectedConfiguration(selectedConfiguration)
            }
          />
        );
      default:
        return unreachable(selectedConfiguration);
    }
  })();
  return (
    <>
      {metadataUrlBaseJsx}
      <Feedback />
    </>
  );
};

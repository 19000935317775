/* eslint-disable no-console */
import pino from 'pino';
import { sendBugsnagError } from './bugsnag';

const isDev = process.env.NODE_ENV === 'development';
const isNode = typeof window === 'undefined';

export const logger = pino({
  level: 'debug',
  ...(isDev
    ? {
        transport: {
          target: 'pino-pretty',
          options: {
            colorize: true,
          },
        },
      }
    : {}),
});

export const logError = (error: unknown): void => {
  let errorString: string | undefined;

  try {
    errorString = JSON.stringify(error);
  } catch (e) {
    console.error(e);
  }

  if (isNode && errorString) {
    logger.error(errorString);
  }
  if (isDev) {
    console.error(error);
  } else {
    const notifiableError = error instanceof Error ? error : errorString;

    if (notifiableError) {
      sendBugsnagError(notifiableError);
    }
  }
};

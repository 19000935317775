import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AddAdpConnectionSslCertificateInput = {
  connectionId: Scalars['String'];
  privateKey: Scalars['String'];
  value: Scalars['String'];
};

export type AddAdpConnectionSslCertificateResult =
  | AdpConnectionSslCertificateAdded
  | ConnectionNotFound
  | InvalidAdpConnectionSslCertificate;

export type AddBillingAddressInput = {
  billingAddress: BillingAddressInput;
};

export type AddBillingAddressResult = BillingAddressAdded;

export type AddPaymentMethodPayload = {
  __typename?: 'AddPaymentMethodPayload';
  team: Team;
};

export type AddUsersToTeamInput = {
  users: Array<UserInviteInput>;
};

export type AddUsersToTeamPayload = {
  __typename?: 'AddUsersToTeamPayload';
  /** The list of memberships of the users that were just invited to the team. */
  invitedMembers?: Maybe<Array<UsersTeam>>;
};

export type AdpConnectionSslCertificate = {
  __typename?: 'AdpConnectionSslCertificate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type AdpConnectionSslCertificateAdded = {
  __typename?: 'AdpConnectionSslCertificateAdded';
  adpConnectionSslCertificate: AdpConnectionSslCertificate;
};

export type AdpConnectionSslCertificateDeleted = {
  __typename?: 'AdpConnectionSslCertificateDeleted';
  certificateId: Scalars['String'];
};

export type AdpConnectionSslCertificateNotFound = {
  __typename?: 'AdpConnectionSslCertificateNotFound';
  certificateId: Scalars['String'];
};

export type AssignRoleToInternalDirectoryGroup =
  | InternalDirectoryGroupNotFound
  | RoleAssignedToInternalDirectoryGroup;

export type AssignRoleToInternalDirectoryGroupInput = {
  internalDirectoryGroupId: Scalars['String'];
  role: UsersOrganizationsRole;
};

export type AttributeMap = {
  __typename?: 'AttributeMap';
  attributes: Attributes;
  id: Scalars['ID'];
};

export type Attributes = {
  __typename?: 'Attributes';
  email: Scalars['String'];
  firstName: Scalars['String'];
  idpId: Scalars['String'];
  lastName: Scalars['String'];
};

export type AuditLogActionAlreadyExists = {
  __typename?: 'AuditLogActionAlreadyExists';
  action: Scalars['String'];
  message: Scalars['String'];
};

export type AuditLogEvent = {
  __typename?: 'AuditLogEvent';
  action: Scalars['String'];
  actor: AuditLogEventActor;
  auditLogTrail: AuditLogTrail;
  createdAt: Scalars['DateTime'];
  data: Scalars['JSON'];
  id: Scalars['ID'];
  occurredAt: Scalars['DateTime'];
  targets: Array<AuditLogEventTarget>;
  updatedAt: Scalars['DateTime'];
};

export type AuditLogEventActor = {
  __typename?: 'AuditLogEventActor';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type AuditLogEventList = {
  __typename?: 'AuditLogEventList';
  data: Array<AuditLogEvent>;
  listMetadata: ListMetadata;
};

export type AuditLogEventTarget = {
  __typename?: 'AuditLogEventTarget';
  type: Scalars['String'];
};

export type AuditLogExport = {
  __typename?: 'AuditLogExport';
  id: Scalars['ID'];
  state: AuditLogExportState;
  url?: Maybe<Scalars['String']>;
};

export enum AuditLogExportState {
  Error = 'Error',
  Pending = 'Pending',
  Ready = 'Ready',
}

export type AuditLogSchemaPreview = {
  __typename?: 'AuditLogSchemaPreview';
  schema: Scalars['JSON'];
};

export type AuditLogStream = {
  __typename?: 'AuditLogStream';
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  state: AuditLogStreamState;
  type: AuditLogStreamDestination;
};

export type AuditLogStreamConfigured = {
  __typename?: 'AuditLogStreamConfigured';
  auditLogStream: AuditLogStream;
};

export type AuditLogStreamDeleted = {
  __typename?: 'AuditLogStreamDeleted';
  auditLogStream: AuditLogStream;
};

export type AuditLogStreamDestination =
  | DatadogLogStream
  | S3LogStream
  | SplunkLogStream;

export type AuditLogStreamNotFound = {
  __typename?: 'AuditLogStreamNotFound';
  auditLogStreamId: Scalars['String'];
};

export enum AuditLogStreamState {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum AuditLogStreamType {
  Datadog = 'Datadog',
  Splunk = 'Splunk',
}

export type AuditLogTarget = {
  __typename?: 'AuditLogTarget';
  createdAt: Scalars['DateTime'];
  environmentId: Scalars['String'];
  id: Scalars['ID'];
  target: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AuditLogTargetList = {
  __typename?: 'AuditLogTargetList';
  data: Array<AuditLogTarget>;
  listMetadata: ListMetadata;
};

export type AuditLogTrail = {
  __typename?: 'AuditLogTrail';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  updatedAt: Scalars['DateTime'];
};

export type AuditLogValidator = {
  __typename?: 'AuditLogValidator';
  action: Scalars['String'];
  createdAt: Scalars['DateTime'];
  environmentId: Scalars['String'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  versions: Array<AuditLogValidatorVersion>;
};

export type AuditLogValidatorCreated = {
  __typename?: 'AuditLogValidatorCreated';
  auditLogValidator: AuditLogValidator;
};

export type AuditLogValidatorDeleted = {
  __typename?: 'AuditLogValidatorDeleted';
  auditLogValidator: AuditLogValidator;
};

export type AuditLogValidatorList = {
  __typename?: 'AuditLogValidatorList';
  data: Array<AuditLogValidator>;
  listMetadata: ListMetadata;
};

export type AuditLogValidatorNotFound = {
  __typename?: 'AuditLogValidatorNotFound';
  auditLogValidatorId: Scalars['String'];
};

export type AuditLogValidatorVersion = {
  __typename?: 'AuditLogValidatorVersion';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  schema: Scalars['JSON'];
  targets: Array<AuditLogTarget>;
  updatedAt: Scalars['DateTime'];
  version: Scalars['Float'];
};

export type AuditLogValidatorVersionCreated = {
  __typename?: 'AuditLogValidatorVersionCreated';
  auditLogValidatorVersion: AuditLogValidatorVersion;
};

export type AuthenticationFactor = {
  __typename?: 'AuthenticationFactor';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  type: AuthenticationFactorType;
  updatedAt: Scalars['DateTime'];
};

export type AuthenticationFactorList = {
  __typename?: 'AuthenticationFactorList';
  data: Array<AuthenticationFactor>;
  listMetadata: ListMetadata;
};

export type AuthenticationFactorType =
  | GenericAuthenticationFactorType
  | SmsAuthenticationFactorType
  | TotpAuthenticationFactorType;

export type BambooHrDirectory = {
  __typename?: 'BambooHrDirectory';
  apiKey?: Maybe<Scalars['String']>;
  customFields?: Maybe<Array<Scalars['String']>>;
  subdomain?: Maybe<Scalars['String']>;
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type BillingAddressAdded = {
  __typename?: 'BillingAddressAdded';
  team: Team;
};

export type BillingAddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  line1: Scalars['String'];
  line2: Scalars['String'];
  name: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type BreatheHrDirectory = {
  __typename?: 'BreatheHrDirectory';
  apiKey?: Maybe<Scalars['String']>;
};

export type CezanneHrDirectory = {
  __typename?: 'CezanneHrDirectory';
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  customFields?: Maybe<Array<Scalars['String']>>;
};

export type ConfigureAuditLogStreamResult =
  | AuditLogStreamConfigured
  | AuditLogStreamNotFound
  | InvalidAuditLogStreamCredentials;

export type ConfigureDatadogLogStreamInput = {
  apiKey: Scalars['String'];
  auditLogStreamId: Scalars['String'];
};

export type ConfigureSplunkLogStreamInput = {
  auditLogStreamId: Scalars['String'];
  hecToken: Scalars['String'];
  url: Scalars['String'];
};

export type Connection = {
  __typename?: 'Connection';
  adpConnectionSslCertificates: Array<AdpConnectionSslCertificate>;
  attributeMap?: Maybe<AttributeMap>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  oauthCredential?: Maybe<OAuthCredential>;
  oauthSessions: OAuthSessionsList;
  oidcSessions: OidcSessionsList;
  oidc_client_id?: Maybe<Scalars['String']>;
  oidc_client_secret?: Maybe<Scalars['String']>;
  oidc_discovery_endpoint?: Maybe<Scalars['String']>;
  oidc_redirect_uri?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizationDomains: Array<OrganizationDomain>;
  saml: SamlConnection;
  samlSessions: SamlSessionsList;
  samlX509Certificates: Array<SamlX509Certificate>;
  saml_acs_url: Scalars['String'];
  saml_entity_id?: Maybe<Scalars['String']>;
  saml_idp_metadata_url?: Maybe<Scalars['String']>;
  saml_idp_url?: Maybe<Scalars['String']>;
  saml_sp_metadata_url: Scalars['String'];
  state: ConnectionState;
  type: ConnectionType;
  updatedAt: Scalars['DateTime'];
};

export type ConnectionOauthSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<OAuthSessionState>;
};

export type ConnectionOidcSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<OidcSessionState>;
};

export type ConnectionSamlSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<SamlSessionState>;
};

export type ConnectionCreated = {
  __typename?: 'ConnectionCreated';
  connection: Connection;
};

export type ConnectionDomain = {
  __typename?: 'ConnectionDomain';
  domain: Scalars['String'];
  id: Scalars['ID'];
};

export type ConnectionError = {
  __typename?: 'ConnectionError';
  error: Scalars['String'];
  field: Scalars['String'];
  solution: Scalars['String'];
};

export type ConnectionForEnvironmentCreated = {
  __typename?: 'ConnectionForEnvironmentCreated';
  connection: Connection;
};

export type ConnectionIsAlreadyTyped = {
  __typename?: 'ConnectionIsAlreadyTyped';
  connectionId: Scalars['String'];
};

export type ConnectionNotFound = {
  __typename?: 'ConnectionNotFound';
  connectionId: Scalars['String'];
};

export type ConnectionOrUntypedConnection = Connection | UntypedConnection;

export type ConnectionOrUntypedConnectionList = {
  __typename?: 'ConnectionOrUntypedConnectionList';
  data: Array<ConnectionOrUntypedConnection>;
  listMetadata: ListMetadata;
};

export type ConnectionSession = OAuthSession | OidcSession | SamlSession;

export type ConnectionSessionError =
  | DecryptionFailedError
  | IdpInitiatedExpiredResponseError
  | IdpInitiatedWithInResponseToError
  | InvalidAttributesError
  | InvalidX509CertError
  | MalformedSamlResponseError
  | ProfileNotAllowedOutsideOrganizationError;

/** Enum represents the error code of a Connection Session Error. */
export enum ConnectionSessionErrorCode {
  DecryptionFailed = 'DecryptionFailed',
  IdpInitiatedExpiredResponse = 'IdpInitiatedExpiredResponse',
  IdpInitiatedWithInResponseTo = 'IdpInitiatedWithInResponseTo',
  InvalidAttributes = 'InvalidAttributes',
  InvalidX509Cert = 'InvalidX509Cert',
  MalformedSamlResponse = 'MalformedSamlResponse',
  ProfileNotAllowedOutsideOrganization = 'ProfileNotAllowedOutsideOrganization',
}

/** Enum represents the state of the connection. */
export enum ConnectionState {
  Active = 'Active',
  Inactive = 'Inactive',
}

/** Enum represents the type of connection. */
export enum ConnectionType {
  Adfssaml = 'ADFSSAML',
  AdpOidc = 'AdpOidc',
  Auth0Saml = 'Auth0SAML',
  AzureSaml = 'AzureSAML',
  CasSaml = 'CasSAML',
  ClassLinkSaml = 'ClassLinkSAML',
  CloudflareSaml = 'CloudflareSAML',
  CyberArkSaml = 'CyberArkSAML',
  DuoSaml = 'DuoSAML',
  GenericOidc = 'GenericOIDC',
  GenericSaml = 'GenericSAML',
  GoogleOAuth = 'GoogleOAuth',
  GoogleSaml = 'GoogleSAML',
  JumpCloudSaml = 'JumpCloudSAML',
  KeycloakSaml = 'KeycloakSAML',
  LastPassSaml = 'LastPassSAML',
  MagicLink = 'MagicLink',
  MicrosoftOAuth = 'MicrosoftOAuth',
  MiniOrangeSaml = 'MiniOrangeSAML',
  NetIqSaml = 'NetIqSAML',
  OktaSaml = 'OktaSAML',
  OneLoginSaml = 'OneLoginSAML',
  OracleSaml = 'OracleSAML',
  PingFederateSaml = 'PingFederateSAML',
  PingOneSaml = 'PingOneSAML',
  RipplingSaml = 'RipplingSAML',
  SalesforceSaml = 'SalesforceSAML',
  ShibbolethGenericSaml = 'ShibbolethGenericSAML',
  ShibbolethSaml = 'ShibbolethSAML',
  SimpleSamlPhpSaml = 'SimpleSamlPhpSAML',
  VMwareSaml = 'VMwareSAML',
}

export type ConnectionUpdated = {
  __typename?: 'ConnectionUpdated';
  connection: Connection;
};

export type ConnectionUpdatedFromMetadataUrl = {
  __typename?: 'ConnectionUpdatedFromMetadataUrl';
  connection: Connection;
};

export type ConnectionUpdatedFromMetadataXml = {
  __typename?: 'ConnectionUpdatedFromMetadataXml';
  connection: Connection;
};

export type ConnectionsMigratedToEnvironmentLevel = {
  __typename?: 'ConnectionsMigratedToEnvironmentLevel';
  environment: Environment;
};

export type CreateAuditLogExportInput = {
  actions?: InputMaybe<Array<Scalars['String']>>;
  actors?: InputMaybe<Array<Scalars['String']>>;
  organizationId: Scalars['String'];
  rangeEnd: Scalars['DateTime'];
  rangeStart: Scalars['DateTime'];
  targets?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateAuditLogExportResult =
  | AuditLogExport
  | InvalidExportDateRange
  | NoAuditLogEventsFound;

export type CreateAuditLogStreamInput = {
  organizationId: Scalars['String'];
  type: AuditLogStreamType;
};

export type CreateAuditLogStreamResult = AuditLogStream;

export type CreateAuditLogValidatorInput = {
  action: Scalars['String'];
  environmentId: Scalars['String'];
  schema?: InputMaybe<Scalars['JSON']>;
  targets: Array<Scalars['String']>;
};

export type CreateAuditLogValidatorResult =
  | AuditLogActionAlreadyExists
  | AuditLogValidatorCreated
  | InvalidAuditLogAction
  | InvalidAuditLogSchema
  | InvalidAuditLogTargets;

export type CreateAuditLogValidatorVersionInput = {
  auditLogValidatorId: Scalars['String'];
  schema?: InputMaybe<Scalars['JSON']>;
  targets: Array<Scalars['String']>;
};

export type CreateAuditLogValidatorVersionResult =
  | AuditLogValidatorNotFound
  | AuditLogValidatorVersionCreated
  | InvalidAuditLogSchema
  | InvalidAuditLogTargets;

export type CreateBillingPortalSessionPayload = {
  __typename?: 'CreateBillingPortalSessionPayload';
  billingPortalSessionUrl: Scalars['String'];
};

export type CreateConnectionForEnvironmentInput = {
  environmentId: Scalars['String'];
  name: Scalars['String'];
  type: ConnectionType;
};

export type CreateConnectionForEnvironmentResult =
  | ConnectionForEnvironmentCreated
  | OauthConnectionMissingCredentials;

export type CreateConnectionInput = {
  name: Scalars['String'];
  organizationId: Scalars['String'];
  type: ConnectionType;
};

export type CreateConnectionResult =
  | ConnectionCreated
  | OauthConnectionMissingCredentials
  | OrganizationNotFound;

export type CreateDirectoryCustomAttributeInput = {
  environmentId: Scalars['String'];
  isRequired: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreateDirectoryCustomAttributeResult =
  | DirectoryCustomAttributeCreated
  | DirectoryCustomAttributeNotValid;

export type CreateKeyInput = {
  environmentId: Scalars['String'];
  expireAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
};

export type CreateKeyResult = KeyCreated;

export type CreateOauthCredentialsInput = {
  environmentId: Scalars['String'];
  provider: ConnectionType;
};

export type CreateOauthCredentialsPayload = {
  __typename?: 'CreateOauthCredentialsPayload';
  oauthCredentials?: Maybe<OAuthCredential>;
};

export type CreateOrganizationInput = {
  allowProfilesOutsideOrganization?: InputMaybe<Scalars['Boolean']>;
  domains?: InputMaybe<Array<Scalars['String']>>;
  environmentId: Scalars['String'];
  name: Scalars['String'];
};

export type CreateOrganizationResult =
  | EnvironmentNotFound
  | OrganizationCreated
  | OrganizationDomainAlreadyInUse
  | OrganizationMissingDomains;

export type CustomDomainRegistered = {
  __typename?: 'CustomDomainRegistered';
  /** The custom domain that was registered. */
  customDomain: Scalars['String'];
};

export type DatadogLogStream = {
  __typename?: 'DatadogLogStream';
  apiKey?: Maybe<Scalars['String']>;
};

export type DecryptionFailedError = {
  __typename?: 'DecryptionFailedError';
  code: ConnectionSessionErrorCode;
  reason: Scalars['String'];
};

export type DefaultPaymentMethod = {
  __typename?: 'DefaultPaymentMethod';
  brand?: Maybe<Scalars['String']>;
  expirationMonth?: Maybe<Scalars['String']>;
  expirationYear?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
};

export type DeleteAdpConnectionSslCertificateInput = {
  certificateId: Scalars['String'];
};

export type DeleteAdpConnectionSslCertificateResult =
  | AdpConnectionSslCertificateDeleted
  | AdpConnectionSslCertificateNotFound;

export type DeleteAuditLogStreamInput = {
  id: Scalars['String'];
};

export type DeleteAuditLogStreamResult =
  | AuditLogStreamDeleted
  | AuditLogStreamNotFound;

export type DeleteAuditLogValidatorInput = {
  id: Scalars['String'];
};

export type DeleteAuditLogValidatorResult =
  | AuditLogValidatorDeleted
  | AuditLogValidatorNotFound;

export type DeleteDirectoryCustomAttributeInput = {
  directoryCustomAttributeId: Scalars['ID'];
};

export type DeleteDirectoryCustomAttributeResult =
  | DirectoryCustomAttributeDeleted
  | DirectoryCustomAttributeNotFound;

export type DeleteDirectoryInput = {
  directoryId: Scalars['String'];
};

export type DeleteDirectoryPayload = {
  __typename?: 'DeleteDirectoryPayload';
  directory?: Maybe<Directory>;
};

export type DeleteOrganizationInput = {
  organizationId: Scalars['String'];
};

export type DeleteOrganizationPayload = {
  __typename?: 'DeleteOrganizationPayload';
  organization?: Maybe<Organization>;
};

export type DeleteSamlX509CertificateInput = {
  samlX509CertificateId: Scalars['String'];
};

export type DeleteSamlX509CertificateResult =
  | SamlX509CertificateDeleted
  | SamlX509CertificateNotFound;

export type Directory = {
  __typename?: 'Directory';
  attributeMap?: Maybe<DirectoryAttributeMap>;
  bamboo_hr_api_key?: Maybe<Scalars['String']>;
  bamboo_hr_custom_fields?: Maybe<Array<Scalars['String']>>;
  bamboo_hr_subdomain?: Maybe<Scalars['String']>;
  bearerToken?: Maybe<Scalars['String']>;
  breatheHrApiKey?: Maybe<Scalars['String']>;
  cezanneHrClientId?: Maybe<Scalars['String']>;
  cezanneHrClientSecret?: Maybe<Scalars['String']>;
  cezanne_custom_fields?: Maybe<Array<Scalars['String']>>;
  client_channel_token?: Maybe<Scalars['String']>;
  client_refresh_token?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  directoryGroups: DirectoryGroupsList;
  directorySyncRun: DirectorySyncRun;
  directorySyncRuns: DirectorySyncRunsList;
  directoryUser: DirectoryUser;
  directoryUsers: DirectoryUsersList;
  endpoint?: Maybe<Scalars['String']>;
  fourthHrOrganizationId?: Maybe<Scalars['String']>;
  fourthHrPassword?: Maybe<Scalars['String']>;
  fourthHrUsername?: Maybe<Scalars['String']>;
  gusto_company_id?: Maybe<Scalars['String']>;
  gusto_refresh_token?: Maybe<Scalars['String']>;
  hibobApiToken?: Maybe<Scalars['String']>;
  hibobServiceUserId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  peopleHrApiKey?: Maybe<Scalars['String']>;
  primaryDomain?: Maybe<OrganizationDomain>;
  provider?: Maybe<DirectoryProvider>;
  rippling_api_key?: Maybe<Scalars['String']>;
  setup_url?: Maybe<Scalars['String']>;
  state: DirectoryState;
  summary: DirectorySummary;
  type: DirectoryType;
  updatedAt: Scalars['DateTime'];
  workday_raas_endpoint?: Maybe<Scalars['String']>;
  workday_raas_group_endpoint?: Maybe<Scalars['String']>;
  workday_raas_password?: Maybe<Scalars['String']>;
  workday_raas_username?: Maybe<Scalars['String']>;
};

export type DirectoryDirectoryGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type DirectoryDirectorySyncRunArgs = {
  id: Scalars['String'];
};

export type DirectoryDirectorySyncRunsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<DirectorySyncRunState>;
};

export type DirectoryDirectoryUserArgs = {
  id: Scalars['String'];
};

export type DirectoryDirectoryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type DirectoryAttributeMap = {
  __typename?: 'DirectoryAttributeMap';
  attributes: DirectoryAttributes;
  id: Scalars['ID'];
};

export type DirectoryAttributes = {
  __typename?: 'DirectoryAttributes';
  emails?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type DirectoryCustomAttribute = {
  __typename?: 'DirectoryCustomAttribute';
  createdAt: Scalars['DateTime'];
  environment: Environment;
  id: Scalars['ID'];
  isRequired: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DirectoryCustomAttributeCreated = {
  __typename?: 'DirectoryCustomAttributeCreated';
  directoryCustomAttribute: DirectoryCustomAttribute;
};

export type DirectoryCustomAttributeDeleted = {
  __typename?: 'DirectoryCustomAttributeDeleted';
  directoryCustomAttribute: DirectoryCustomAttribute;
};

export type DirectoryCustomAttributeList = {
  __typename?: 'DirectoryCustomAttributeList';
  data: Array<DirectoryCustomAttribute>;
};

export type DirectoryCustomAttributeMapping = {
  __typename?: 'DirectoryCustomAttributeMapping';
  attribute: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customAttribute: DirectoryCustomAttribute;
  directory: Directory;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type DirectoryCustomAttributeMappingInput = {
  attribute: Scalars['String'];
  customAttributeId: Scalars['ID'];
};

export type DirectoryCustomAttributeMappingList = {
  __typename?: 'DirectoryCustomAttributeMappingList';
  data: Array<DirectoryCustomAttributeMapping>;
};

export type DirectoryCustomAttributeMappingsSet = {
  __typename?: 'DirectoryCustomAttributeMappingsSet';
  mappings: Array<DirectoryCustomAttributeMapping>;
};

export type DirectoryCustomAttributeNotFound = {
  __typename?: 'DirectoryCustomAttributeNotFound';
  directoryCustomAttributeId: Scalars['String'];
};

export type DirectoryCustomAttributeNotValid = {
  __typename?: 'DirectoryCustomAttributeNotValid';
  reason: Scalars['String'];
};

export type DirectoryCustomAttributeUpdated = {
  __typename?: 'DirectoryCustomAttributeUpdated';
  directoryCustomAttribute: DirectoryCustomAttribute;
};

export type DirectoryGroup = {
  __typename?: 'DirectoryGroup';
  id: Scalars['String'];
  name: Scalars['String'];
  summary: DirectoryGroupSummary;
};

export type DirectoryGroupSummary = {
  __typename?: 'DirectoryGroupSummary';
  memberCount: Scalars['Int'];
};

export type DirectoryGroupsList = {
  __typename?: 'DirectoryGroupsList';
  data: Array<DirectoryGroup>;
  listMetadata: ListMetadata;
};

export type DirectoryIsAlreadyTyped = {
  __typename?: 'DirectoryIsAlreadyTyped';
  directoryId: Scalars['String'];
};

export type DirectoryNotFound = {
  __typename?: 'DirectoryNotFound';
  directoryId: Scalars['String'];
};

export type DirectoryOrUntypedDirectory = Directory | UntypedDirectory;

export type DirectoryOrUntypedDirectoryList = {
  __typename?: 'DirectoryOrUntypedDirectoryList';
  data: Array<DirectoryOrUntypedDirectory>;
  listMetadata: ListMetadata;
};

export type DirectoryProvider =
  | BambooHrDirectory
  | BreatheHrDirectory
  | CezanneHrDirectory
  | FourthHrDirectory
  | GustoDirectory
  | HibobDirectory
  | PeopleHrDirectory
  | RipplingDirectory
  | WorkdayDirectory;

/** An error pertaining to the directory provider. */
export type DirectoryProviderError = {
  __typename?: 'DirectoryProviderError';
  message: Scalars['String'];
};

export type DirectoryProviderErrors = {
  __typename?: 'DirectoryProviderErrors';
  errors: Array<DirectoryProviderError>;
};

/** Enum represents the state of the Directory. */
export enum DirectoryState {
  Deleting = 'Deleting',
  InvalidCredentials = 'InvalidCredentials',
  Linked = 'Linked',
  Unlinked = 'Unlinked',
  Validating = 'Validating',
}

export type DirectorySummary = {
  __typename?: 'DirectorySummary';
  groupCount: Scalars['Int'];
  userCount: Scalars['Int'];
};

export type DirectorySyncRun = {
  __typename?: 'DirectorySyncRun';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  directoryId: Scalars['String'];
  errors: Array<DirectorySyncRunError>;
  groupCounts: DirectorySyncRunResourceCounts;
  id: Scalars['String'];
  state: DirectorySyncRunState;
  updatedAt: Scalars['DateTime'];
  userCounts: DirectorySyncRunResourceCounts;
};

export type DirectorySyncRunError =
  | MalformedDirectoryGroupError
  | MalformedDirectoryUserError;

/** Enum represents the error code of a Directory Sync Run. */
export enum DirectorySyncRunErrorCode {
  MalformedDirectoryGroup = 'MalformedDirectoryGroup',
  MalformedDirectoryUser = 'MalformedDirectoryUser',
}

export type DirectorySyncRunResourceCounts = {
  __typename?: 'DirectorySyncRunResourceCounts';
  created: Scalars['Float'];
  deleted: Scalars['Float'];
  processed: Scalars['Float'];
  updated: Scalars['Float'];
};

/** Enum represents the state of the directory sync run. */
export enum DirectorySyncRunState {
  Completed = 'Completed',
  Error = 'Error',
  New = 'New',
  Running = 'Running',
}

export type DirectorySyncRunsList = {
  __typename?: 'DirectorySyncRunsList';
  data: Array<DirectorySyncRun>;
  listMetadata: ListMetadata;
};

/** Enum represents the type of Directory. */
export enum DirectoryType {
  AzureScimv2_0 = 'AzureSCIMV2_0',
  BambooHr = 'BambooHR',
  BreatheHr = 'BreatheHr',
  CezanneHr = 'CezanneHr',
  CyberArkScimV2_0 = 'CyberArkScimV2_0',
  FourthHr = 'FourthHr',
  GenericScimv1_1 = 'GenericSCIMV1_1',
  GenericScimv2_0 = 'GenericSCIMV2_0',
  GoogleWorkspace = 'GoogleWorkspace',
  Gusto = 'Gusto',
  Hibob = 'Hibob',
  JumpCloudScimV2_0 = 'JumpCloudScimV2_0',
  OktaScimv1_1 = 'OktaSCIMV1_1',
  OktaScimv2_0 = 'OktaSCIMV2_0',
  OneLoginScimV2_0 = 'OneLoginScimV2_0',
  PeopleHr = 'PeopleHr',
  PingFederateScimV2_0 = 'PingFederateScimV2_0',
  Rippling = 'Rippling',
  RipplingScimV2_0 = 'RipplingScimV2_0',
  S3 = 'S3',
  Workday = 'Workday',
}

export type DirectoryUpdated = {
  __typename?: 'DirectoryUpdated';
  directory: Directory;
};

export type DirectoryUser = {
  __typename?: 'DirectoryUser';
  directoryGroups: Array<DirectoryGroup>;
  emails?: Maybe<DirectoryUserEmail>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  idpId: Scalars['String'];
  /** The JSON representation of the Directory User, as returned by the REST API. */
  json: Scalars['JSON'];
  lastName?: Maybe<Scalars['String']>;
  state: DirectoryUserState;
  username?: Maybe<Scalars['String']>;
};

export type DirectoryUserEmail = {
  __typename?: 'DirectoryUserEmail';
  primary: Scalars['Boolean'];
  type: Scalars['String'];
  value: Scalars['String'];
};

/** Enum represents the state of the directory user. */
export enum DirectoryUserState {
  Active = 'Active',
  Inactive = 'Inactive',
  Suspended = 'Suspended',
}

export type DirectoryUsersList = {
  __typename?: 'DirectoryUsersList';
  data: Array<DirectoryUser>;
  listMetadata: ListMetadata;
};

export type DomainStatusChecked = {
  __typename?: 'DomainStatusChecked';
  success: Scalars['Boolean'];
};

/** Represents the email categories that can be unsubscribed from. */
export enum EmailNotificationCategory {
  Connection = 'Connection',
  Error = 'Error',
}

export type EmailNotificationPreference = {
  __typename?: 'EmailNotificationPreference';
  category: EmailNotificationCategory;
  preference: EmailNotificationPreferenceConfiguration;
};

/** Represents the possible configurations for email notification preferences. */
export enum EmailNotificationPreferenceConfiguration {
  Subscribed = 'Subscribed',
  Unsubscribed = 'Unsubscribed',
}

export type EmailNotificationPreferences = {
  __typename?: 'EmailNotificationPreferences';
  preferences: Array<EmailNotificationPreference>;
};

export type EnrollTotpUserAuthenticationFactorResult =
  TotpUserAuthenticationFactorEnrolled;

export type EnterTeamDetailsPayload = {
  __typename?: 'EnterTeamDetailsPayload';
  team?: Maybe<Team>;
  user?: Maybe<User>;
};

export type Environment = {
  __typename?: 'Environment';
  allowProfilesOutsideOrganization?: Maybe<Scalars['Boolean']>;
  clientId: Scalars['String'];
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  oauthAndMagicLinkMigrationStatuses: OauthAndMagicLinkMigrationStatuses;
  portalSettings: PortalSettings;
  projectV2: ProjectV2;
  sandbox: Scalars['Boolean'];
  ssoDemoApplication?: Maybe<SsoDemoApplication>;
};

export type EnvironmentNotFound = {
  __typename?: 'EnvironmentNotFound';
  environmentId: Scalars['String'];
};

/** The type of an environment. */
export enum EnvironmentType {
  Production = 'Production',
  Sandbox = 'Sandbox',
}

export type Event = {
  __typename?: 'Event';
  action: EventAction;
  actor: EventActor;
  group: Scalars['String'];
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  location: Scalars['String'];
  longitude?: Maybe<Scalars['Float']>;
  metadata?: Maybe<Scalars['JSON']>;
  occurredAt: Scalars['DateTime'];
  target: EventTarget;
  type?: Maybe<Scalars['String']>;
};

export type EventAction = {
  __typename?: 'EventAction';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EventActor = {
  __typename?: 'EventActor';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type EventList = {
  __typename?: 'EventList';
  data: Array<Event>;
  listMetadata: ListMetadata;
};

export type EventTarget = {
  __typename?: 'EventTarget';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ExpectedAttributes = {
  __typename?: 'ExpectedAttributes';
  email_attribute: Scalars['String'];
  first_name_attribute: Scalars['String'];
  idp_id_attribute: Scalars['String'];
  last_name_attribute: Scalars['String'];
};

export type ExpireKeyInput = {
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  keyId: Scalars['String'];
};

export type ExpireKeyResult = KeyAlreadyExpired | KeyExpired | KeyNotFound;

export type FourthHrDirectory = {
  __typename?: 'FourthHrDirectory';
  organizationId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type GeneratePortalLinkInput = {
  intent: GeneratePortalLinkIntent;
};

/** Enum represents the intent of the Admin Portal link request. */
export enum GeneratePortalLinkIntent {
  DSync = 'DSync',
  Sso = 'SSO',
}

export type GeneratePortalLinkResult =
  | OrganizationNotFound
  | PortalLinkGenerated
  | SsoAlreadyConnectedForDomain
  | UserNotAuthorized;

export type GeneratePortalSetupLinkInput = {
  intent: PortalSetupLinkIntent;
  organizationId: Scalars['String'];
};

export type GeneratePortalSetupLinkResult =
  | OrganizationNotFound
  | PortalSetupLinkGenerated;

export type GenerateSharedSlackChannelResult = SharedSlackChannelGenerated;

export type GenericAuthenticationFactorType = {
  __typename?: 'GenericAuthenticationFactorType';
  /** Placeholder field to allow empty @ObjectType for GenericAuthenticationFactor */
  _placeholder?: Maybe<Scalars['Boolean']>;
};

export type GustoDirectory = {
  __typename?: 'GustoDirectory';
  companyId?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type HibobDirectory = {
  __typename?: 'HibobDirectory';
  apiToken?: Maybe<Scalars['String']>;
  serviceUserId?: Maybe<Scalars['String']>;
};

export type IdpInitiatedExpiredResponseError = {
  __typename?: 'IdpInitiatedExpiredResponseError';
  code: ConnectionSessionErrorCode;
  reason: Scalars['String'];
};

export type IdpInitiatedWithInResponseToError = {
  __typename?: 'IdpInitiatedWithInResponseToError';
  code: ConnectionSessionErrorCode;
  reason: Scalars['String'];
};

export type InternalDirectory = {
  __typename?: 'InternalDirectory';
  groups: InternalDirectoryGroupList;
  id: Scalars['ID'];
  isSyncing: Scalars['Boolean'];
  name: Scalars['String'];
  state: DirectoryState;
  type: DirectoryType;
};

export type InternalDirectoryGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type InternalDirectoryGroup = {
  __typename?: 'InternalDirectoryGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  role?: Maybe<UsersOrganizationsRole>;
};

export type InternalDirectoryGroupList = {
  __typename?: 'InternalDirectoryGroupList';
  data: Array<InternalDirectoryGroup>;
  listMetadata: ListMetadata;
};

export type InternalDirectoryGroupNotFound = {
  __typename?: 'InternalDirectoryGroupNotFound';
  internalDirectoryGroupId: Scalars['String'];
};

export type InvalidAdpConnectionSslCertificate = {
  __typename?: 'InvalidAdpConnectionSslCertificate';
  privateKey: Scalars['String'];
  value: Scalars['String'];
};

export type InvalidAttributesError = {
  __typename?: 'InvalidAttributesError';
  code: ConnectionSessionErrorCode;
  expected_attributes: ExpectedAttributes;
  reason: Scalars['String'];
  received_attributes: Array<ReceivedAttribute>;
};

export type InvalidAuditLogAction = {
  __typename?: 'InvalidAuditLogAction';
  action: Scalars['String'];
  message: Scalars['String'];
};

export type InvalidAuditLogSchema = {
  __typename?: 'InvalidAuditLogSchema';
  message: Scalars['String'];
};

export type InvalidAuditLogStreamCredentials = {
  __typename?: 'InvalidAuditLogStreamCredentials';
  message: Scalars['String'];
};

export type InvalidAuditLogTargets = {
  __typename?: 'InvalidAuditLogTargets';
  message: Scalars['String'];
  targets: Array<Scalars['String']>;
};

export type InvalidDomain = {
  __typename?: 'InvalidDomain';
  domain: Scalars['String'];
};

export type InvalidExportDateRange = {
  __typename?: 'InvalidExportDateRange';
  message: Scalars['String'];
};

export type InvalidOauthCredentials = {
  __typename?: 'InvalidOauthCredentials';
  errors: Array<OauthCredentialsError>;
};

export type InvalidRedirectUri = {
  __typename?: 'InvalidRedirectUri';
  invalidFields: Scalars['JSON'];
  validationError: Scalars['String'];
};

export type InvalidRedirectUriProcotol = {
  __typename?: 'InvalidRedirectUriProcotol';
  message: Scalars['String'];
  uri: Scalars['String'];
};

export type InvalidSamlX509Certificate = {
  __typename?: 'InvalidSamlX509Certificate';
  validationError: SamlX509CertificateValidationErrorCode;
};

export type InvalidWildcardRedirectUri = {
  __typename?: 'InvalidWildcardRedirectUri';
  message: Scalars['String'];
  uri: Scalars['String'];
};

export type InvalidX509CertError = {
  __typename?: 'InvalidX509CertError';
  code: ConnectionSessionErrorCode;
  reason: Scalars['String'];
};

export type Key = {
  __typename?: 'Key';
  createdAt: Scalars['DateTime'];
  displayValue: Scalars['String'];
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: KeyType;
  updatedAt: Scalars['DateTime'];
};

export type KeyAlreadyExpired = {
  __typename?: 'KeyAlreadyExpired';
  key: Key;
};

export type KeyCreated = {
  __typename?: 'KeyCreated';
  key: NewlyCreatedKey;
};

export type KeyExpired = {
  __typename?: 'KeyExpired';
  expiredKey: Key;
  newKey?: Maybe<NewlyCreatedKey>;
};

export type KeyList = {
  __typename?: 'KeyList';
  data: Array<Key>;
  listMetadata: ListMetadata;
};

export type KeyNotFound = {
  __typename?: 'KeyNotFound';
  keyId: Scalars['String'];
};

/** Enum represents a scope of API keys that can be returned. */
export enum KeyScope {
  Active = 'Active',
  All = 'All',
  RecentlyExpired = 'RecentlyExpired',
}

/** Enum representing a type of key. */
export enum KeyType {
  Secret = 'SECRET',
}

export type ListMetadata = {
  __typename?: 'ListMetadata';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};

export type MalformedDirectoryGroupError = {
  __typename?: 'MalformedDirectoryGroupError';
  code: DirectorySyncRunErrorCode;
  reason: Scalars['String'];
};

export type MalformedDirectoryUserError = {
  __typename?: 'MalformedDirectoryUserError';
  code: DirectorySyncRunErrorCode;
  reason: Scalars['String'];
};

export type MalformedSamlResponseError = {
  __typename?: 'MalformedSamlResponseError';
  code: ConnectionSessionErrorCode;
  reason: Scalars['String'];
};

export type MetadataFetchFailed = {
  __typename?: 'MetadataFetchFailed';
  reason: Scalars['String'];
};

export type MetadataParseFailed = {
  __typename?: 'MetadataParseFailed';
  reason: Scalars['String'];
};

export type MigrateConnectionsToEnvironmentLevelInput = {
  environmentId: Scalars['String'];
};

export type MigrateConnectionsToEnvironmentLevelResult =
  | ConnectionsMigratedToEnvironmentLevel
  | EnvironmentNotFound;

export type Mutation = {
  __typename?: 'Mutation';
  addAdpConnectionSslCertificate: AddAdpConnectionSslCertificateResult;
  addBillingAddress: AddBillingAddressResult;
  addPaymentMethod: AddPaymentMethodPayload;
  addUserToTeam: UsersTeam;
  addUsersToTeam: AddUsersToTeamPayload;
  assignRoleToInternalDirectoryGroup: AssignRoleToInternalDirectoryGroup;
  changeRole: UsersTeam;
  configureDatadogLogStream: ConfigureAuditLogStreamResult;
  configureSplunkLogStream: ConfigureAuditLogStreamResult;
  createAuditLogExport: CreateAuditLogExportResult;
  createAuditLogStream: CreateAuditLogStreamResult;
  createAuditLogValidator: CreateAuditLogValidatorResult;
  createAuditLogValidatorVersion: CreateAuditLogValidatorVersionResult;
  createBillingPortalSession: CreateBillingPortalSessionPayload;
  createConnection: CreateConnectionResult;
  createConnectionForEnvironment: CreateConnectionForEnvironmentResult;
  createDirectory: Directory;
  createDirectoryCustomAttribute: CreateDirectoryCustomAttributeResult;
  createKey: CreateKeyResult;
  createOauthCredentials: CreateOauthCredentialsPayload;
  createOrganization: CreateOrganizationResult;
  createRedirectUri: RedirectUri;
  createWebhookEndpoint: WebhookEndpoint;
  deleteAdpConnectionSslCertificate: DeleteAdpConnectionSslCertificateResult;
  deleteAuditLogStream: DeleteAuditLogStreamResult;
  deleteAuditLogValidator: DeleteAuditLogValidatorResult;
  deleteConnection: Scalars['String'];
  deleteDirectory: DeleteDirectoryPayload;
  deleteDirectoryCustomAttribute: DeleteDirectoryCustomAttributeResult;
  deleteOrganization: DeleteOrganizationPayload;
  deleteRedirectUri: Scalars['String'];
  deleteSamlX509Certificate: DeleteSamlX509CertificateResult;
  deleteWebhookEndpoint: Scalars['String'];
  enrollTotpAuthenticationFactor: EnrollTotpUserAuthenticationFactorResult;
  expireKey: ExpireKeyResult;
  generateEventsCSV: Scalars['String'];
  generatePortalLink: GeneratePortalLinkResult;
  generatePortalSetupLink: GeneratePortalSetupLinkResult;
  generateSharedSlackChannel: GenerateSharedSlackChannelResult;
  migrateConnectionsToEnvironmentLevel: MigrateConnectionsToEnvironmentLevelResult;
  onboarding_completeStep: OnboardingCompleteStepPayload;
  onboarding_enterTeamDetails: EnterTeamDetailsPayload;
  portalIdentityProviderRequest: Scalars['Boolean'];
  portal_addAdpConnectionSslCertificate: Portal_AddAdpConnectionSslCertificateResult;
  portal_createAuditLogExport: Portal_CreateAuditLogExportResult;
  portal_createConnection: Portal_Connection;
  portal_createConnectionFromUntyped: Portal_UpdateUntypedConnectionResult;
  portal_createDirectory: Portal_Directory;
  portal_createDirectoryFromUntyped: Portal_UpdateUntypedDirectoryResult;
  portal_deleteDirectory: Portal_DeleteDirectoryPayload;
  portal_deleteDraftConnection: Scalars['String'];
  portal_deleteSamlX509Certificate: Portal_DeleteSamlX509CertificateResult;
  portal_loaded: SuccessResponse;
  portal_ready: SuccessResponse;
  portal_setConnectionPortalProgression: SetConnectionPortalProgressionResult;
  portal_setDirectoryCustomAttributeMappings: Portal_SetDirectoryCustomAttributeMappingsResult;
  portal_setDirectoryPortalProgression: SetDirectoryPortalProgressionResult;
  portal_trackEvent: SuccessResponse;
  portal_updateAttributeMap?: Maybe<AttributeMap>;
  portal_updateConnection: Portal_UpdateConnectionResult;
  portal_updateConnectionFromMetadataUrl: Portal_UpdateConnectionFromMetadataUrlResult;
  portal_updateConnectionFromMetadataXml: Portal_UpdateConnectionFromMetadataXmlResult;
  portal_updateDirectory: Portal_Directory;
  portal_updateDirectoryAttributeMap?: Maybe<Portal_UpdateDirectoryAttributeMapPayload>;
  registerCustomDomain: RegisterCustomDomainResult;
  removeUserFromTeam: Scalars['String'];
  resendWebhookEvent: ResendWebhookEventResult;
  resetUserMfaSettings: ResetUserMfaSettingsResult;
  setDirectoryCustomAttributeMappings: SetDirectoryCustomAttributeMappingsResult;
  setDomainsForOrganization: SetDomainsForOrganizationPayload;
  setEmailNotificationPreferences: SetEmailNotificationPreferencesResult;
  setRedirectUris: SetRedirectUrisResult;
  setTeamDefaultRole: SetTeamDefaultRoleResult;
  startSyncingInternalDirectory: StartSyncingInternalDirectoryResult;
  testWebhookEndpoint: Webhook;
  unassignRoleFromInternalDirectoryGroup: UnassignRoleFromInternalDirectoryGroupResult;
  updateAttributeMap?: Maybe<AttributeMap>;
  updateConnection: UpdateConnectionResult;
  updateConnectionFromMetadataUrl: UpdateConnectionFromMetadataUrlResult;
  updateConnectionFromMetadataXml: UpdateConnectionFromMetadataXmlResult;
  updateDirectory: UpdateDirectoryResult;
  updateDirectoryAttributeMap?: Maybe<DirectoryAttributeMap>;
  updateDirectoryCustomAttribute: UpdateDirectoryCustomAttributeResult;
  updateMyself: UpdateMyselfResult;
  /** @deprecated Please use `updateOauthCredentials` instead. */
  updateOAuthCredential?: Maybe<OAuthCredential>;
  updateOauthCredentials: UpdateOauthCredentialsResult;
  updateOrganization: UpdateOrganizationPayload;
  updatePortalSettings: PortalSettings;
  updatePortalSettingsV2: UpdatePortalSettingsResult;
  updateRedirectUri: RedirectUri;
  updateSession?: Maybe<Session>;
  updateTeamMfaRequirement: UpdateTeamMfaRequirementResult;
  updateWebhookEndpoint: WebhookEndpoint;
  upsertSamlX509Certificate: UpsertSamlX509CertificateResult;
  verifyAuthenticationFactorEnrollment: VerifyAuthenticationFactorEnrollmentResult;
  verifyDomainStatus: VerifyDomainStatusResult;
  verifyRedirectUri: VerifyRedirectUriResult;
};

export type MutationAddAdpConnectionSslCertificateArgs = {
  input: AddAdpConnectionSslCertificateInput;
};

export type MutationAddBillingAddressArgs = {
  input: AddBillingAddressInput;
};

export type MutationAddPaymentMethodArgs = {
  billingEmail: Scalars['String'];
  stripePaymentMethodId: Scalars['String'];
};

export type MutationAddUserToTeamArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  role: UsersOrganizationsRole;
};

export type MutationAddUsersToTeamArgs = {
  input: AddUsersToTeamInput;
};

export type MutationAssignRoleToInternalDirectoryGroupArgs = {
  input: AssignRoleToInternalDirectoryGroupInput;
};

export type MutationChangeRoleArgs = {
  role: UsersOrganizationsRole;
  usersOrganizationsId: Scalars['String'];
};

export type MutationConfigureDatadogLogStreamArgs = {
  input: ConfigureDatadogLogStreamInput;
};

export type MutationConfigureSplunkLogStreamArgs = {
  input: ConfigureSplunkLogStreamInput;
};

export type MutationCreateAuditLogExportArgs = {
  input: CreateAuditLogExportInput;
};

export type MutationCreateAuditLogStreamArgs = {
  input: CreateAuditLogStreamInput;
};

export type MutationCreateAuditLogValidatorArgs = {
  input: CreateAuditLogValidatorInput;
};

export type MutationCreateAuditLogValidatorVersionArgs = {
  input: CreateAuditLogValidatorVersionInput;
};

export type MutationCreateBillingPortalSessionArgs = {
  returnUrl: Scalars['String'];
};

export type MutationCreateConnectionArgs = {
  input: CreateConnectionInput;
};

export type MutationCreateConnectionForEnvironmentArgs = {
  input: CreateConnectionForEnvironmentInput;
};

export type MutationCreateDirectoryArgs = {
  domain?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organization_id: Scalars['String'];
  type: DirectoryType;
};

export type MutationCreateDirectoryCustomAttributeArgs = {
  input: CreateDirectoryCustomAttributeInput;
};

export type MutationCreateKeyArgs = {
  input: CreateKeyInput;
};

export type MutationCreateOauthCredentialsArgs = {
  input: CreateOauthCredentialsInput;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type MutationCreateRedirectUriArgs = {
  environmentId: Scalars['String'];
  isDefault: Scalars['Boolean'];
  uri: Scalars['String'];
};

export type MutationCreateWebhookEndpointArgs = {
  endpointUrl: Scalars['String'];
  environmentId: Scalars['String'];
  events: Array<Scalars['String']>;
};

export type MutationDeleteAdpConnectionSslCertificateArgs = {
  input: DeleteAdpConnectionSslCertificateInput;
};

export type MutationDeleteAuditLogStreamArgs = {
  input: DeleteAuditLogStreamInput;
};

export type MutationDeleteAuditLogValidatorArgs = {
  input: DeleteAuditLogValidatorInput;
};

export type MutationDeleteConnectionArgs = {
  id: Scalars['String'];
};

export type MutationDeleteDirectoryArgs = {
  input: DeleteDirectoryInput;
};

export type MutationDeleteDirectoryCustomAttributeArgs = {
  input: DeleteDirectoryCustomAttributeInput;
};

export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};

export type MutationDeleteRedirectUriArgs = {
  id: Scalars['String'];
};

export type MutationDeleteSamlX509CertificateArgs = {
  input: DeleteSamlX509CertificateInput;
};

export type MutationDeleteWebhookEndpointArgs = {
  id: Scalars['String'];
};

export type MutationExpireKeyArgs = {
  input: ExpireKeyInput;
};

export type MutationGenerateEventsCsvArgs = {
  action?: InputMaybe<Array<Scalars['String']>>;
  action_type?: InputMaybe<Array<Scalars['String']>>;
  actor_id?: InputMaybe<Array<Scalars['String']>>;
  actor_name?: InputMaybe<Array<Scalars['String']>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  group?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  occurred_at?: InputMaybe<Scalars['DateTime']>;
  occurred_at_gt?: InputMaybe<Scalars['DateTime']>;
  occurred_at_gte?: InputMaybe<Scalars['DateTime']>;
  occurred_at_lt?: InputMaybe<Scalars['DateTime']>;
  occurred_at_lte?: InputMaybe<Scalars['DateTime']>;
  search?: InputMaybe<Scalars['String']>;
  target_id?: InputMaybe<Array<Scalars['String']>>;
  target_name?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationGeneratePortalLinkArgs = {
  input: GeneratePortalLinkInput;
};

export type MutationGeneratePortalSetupLinkArgs = {
  input: GeneratePortalSetupLinkInput;
};

export type MutationMigrateConnectionsToEnvironmentLevelArgs = {
  input: MigrateConnectionsToEnvironmentLevelInput;
};

export type MutationOnboarding_CompleteStepArgs = {
  input: OnboardingCompleteStepInput;
};

export type MutationOnboarding_EnterTeamDetailsArgs = {
  input: OnboardingEnterTeamDetailsInput;
};

export type MutationPortalIdentityProviderRequestArgs = {
  identityProvider: Scalars['String'];
  pageRequestedFrom?: InputMaybe<Scalars['String']>;
};

export type MutationPortal_AddAdpConnectionSslCertificateArgs = {
  input: Portal_AddAdpConnectionSslCertificateInput;
};

export type MutationPortal_CreateAuditLogExportArgs = {
  input: Portal_CreateAuditLogExportInput;
};

export type MutationPortal_CreateConnectionArgs = {
  connection_type: ConnectionType;
  domains?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
};

export type MutationPortal_CreateConnectionFromUntypedArgs = {
  input: Portal_CreateConnectionFromUntypedInput;
};

export type MutationPortal_CreateDirectoryArgs = {
  domain?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type: DirectoryType;
};

export type MutationPortal_CreateDirectoryFromUntypedArgs = {
  input: Portal_CreateDirectoryFromUntypedInput;
};

export type MutationPortal_DeleteDirectoryArgs = {
  input: Portal_DeleteDirectoryInput;
};

export type MutationPortal_DeleteDraftConnectionArgs = {
  id: Scalars['String'];
};

export type MutationPortal_DeleteSamlX509CertificateArgs = {
  input: Portal_DeleteSamlX509CertificateInput;
};

export type MutationPortal_LoadedArgs = {
  legacy?: InputMaybe<Scalars['Boolean']>;
  started_at: Scalars['Float'];
};

export type MutationPortal_ReadyArgs = {
  legacy?: InputMaybe<Scalars['Boolean']>;
  started_at: Scalars['Float'];
};

export type MutationPortal_SetConnectionPortalProgressionArgs = {
  input: SetConnectionPortalProgressionInput;
};

export type MutationPortal_SetDirectoryCustomAttributeMappingsArgs = {
  input: Portal_SetDirectoryCustomAttributeMappingsInput;
};

export type MutationPortal_SetDirectoryPortalProgressionArgs = {
  input: SetDirectoryPortalProgressionInput;
};

export type MutationPortal_TrackEventArgs = {
  attributes?: InputMaybe<Scalars['JSON']>;
  event_name: Scalars['String'];
};

export type MutationPortal_UpdateAttributeMapArgs = {
  attribute_map_id: Scalars['String'];
  email_attribute?: InputMaybe<Scalars['String']>;
  first_name_attribute?: InputMaybe<Scalars['String']>;
  idp_id_attribute?: InputMaybe<Scalars['String']>;
  last_name_attribute?: InputMaybe<Scalars['String']>;
};

export type MutationPortal_UpdateConnectionArgs = {
  input: Portal_UpdateConnectionInput;
};

export type MutationPortal_UpdateConnectionFromMetadataUrlArgs = {
  input: Portal_UpdateConnectionFromMetadataUrlInput;
};

export type MutationPortal_UpdateConnectionFromMetadataXmlArgs = {
  input: Portal_UpdateConnectionFromMetadataXmlInput;
};

export type MutationPortal_UpdateDirectoryArgs = {
  bamboo_hr_api_key?: InputMaybe<Scalars['String']>;
  bamboo_hr_subdomain?: InputMaybe<Scalars['String']>;
  bob_api_token?: InputMaybe<Scalars['String']>;
  bob_service_user_id?: InputMaybe<Scalars['String']>;
  breatheHrApiKey?: InputMaybe<Scalars['String']>;
  cezanneHrClientId?: InputMaybe<Scalars['String']>;
  cezanneHrClientSecret?: InputMaybe<Scalars['String']>;
  directory_id: Scalars['String'];
  domain?: InputMaybe<Scalars['String']>;
  fourthHrOrganizationId?: InputMaybe<Scalars['String']>;
  fourthHrPassword?: InputMaybe<Scalars['String']>;
  fourthHrUsername?: InputMaybe<Scalars['String']>;
  peopleHrApiKey?: InputMaybe<Scalars['String']>;
  rippling_api_key?: InputMaybe<Scalars['String']>;
  workday_raas_endpoint?: InputMaybe<Scalars['String']>;
  workday_raas_group_endpoint?: InputMaybe<Scalars['String']>;
  workday_raas_password?: InputMaybe<Scalars['String']>;
  workday_raas_username?: InputMaybe<Scalars['String']>;
};

export type MutationPortal_UpdateDirectoryAttributeMapArgs = {
  input: Portal_UpdateDirectoryAttributeMapInput;
};

export type MutationRegisterCustomDomainArgs = {
  input: RegisterCustomDomainInput;
};

export type MutationRemoveUserFromTeamArgs = {
  usersOrganizationsId: Scalars['String'];
};

export type MutationResendWebhookEventArgs = {
  input: ResendWebhookEventInput;
};

export type MutationResetUserMfaSettingsArgs = {
  input: ResetUserMfaSettingsInput;
};

export type MutationSetDirectoryCustomAttributeMappingsArgs = {
  input: SetDirectoryCustomAttributeMappingsInput;
};

export type MutationSetDomainsForOrganizationArgs = {
  input: SetDomainsForOrganizationInput;
};

export type MutationSetEmailNotificationPreferencesArgs = {
  input: SetEmailNotificationPreferencesInput;
};

export type MutationSetRedirectUrisArgs = {
  input: SetRedirectUrisInput;
};

export type MutationSetTeamDefaultRoleArgs = {
  input: SetTeamDefaultRoleInput;
};

export type MutationStartSyncingInternalDirectoryArgs = {
  input: StartSyncingInternalDirectoryInput;
};

export type MutationTestWebhookEndpointArgs = {
  event: WebhookEventFixtures;
  id: Scalars['String'];
};

export type MutationUnassignRoleFromInternalDirectoryGroupArgs = {
  input: UnassignRoleFromInternalDirectoryGroupInput;
};

export type MutationUpdateAttributeMapArgs = {
  attribute_map_id: Scalars['String'];
  email_attribute?: InputMaybe<Scalars['String']>;
  first_name_attribute?: InputMaybe<Scalars['String']>;
  idp_id_attribute?: InputMaybe<Scalars['String']>;
  last_name_attribute?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateConnectionArgs = {
  input: UpdateConnectionInput;
};

export type MutationUpdateConnectionFromMetadataUrlArgs = {
  input: UpdateConnectionFromMetadataUrlInput;
};

export type MutationUpdateConnectionFromMetadataXmlArgs = {
  input: UpdateConnectionFromMetadataXmlInput;
};

export type MutationUpdateDirectoryArgs = {
  input: UpdateDirectoryInput;
};

export type MutationUpdateDirectoryAttributeMapArgs = {
  directory_attribute_map_id: Scalars['String'];
  emails_attribute?: InputMaybe<Scalars['String']>;
  external_id_attribute?: InputMaybe<Scalars['String']>;
  first_name_attribute?: InputMaybe<Scalars['String']>;
  group_name_attribute?: InputMaybe<Scalars['String']>;
  job_title_attribute?: InputMaybe<Scalars['String']>;
  last_name_attribute?: InputMaybe<Scalars['String']>;
  username_attribute?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateDirectoryCustomAttributeArgs = {
  input: UpdateDirectoryCustomAttributeInput;
};

export type MutationUpdateMyselfArgs = {
  input: UpdateMyselfInput;
};

export type MutationUpdateOAuthCredentialArgs = {
  client_id?: InputMaybe<Scalars['String']>;
  client_secret?: InputMaybe<Scalars['String']>;
  oauth_credential_id: Scalars['String'];
  redirect_uri?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateOauthCredentialsArgs = {
  input: UpdateOauthCredentialsInput;
};

export type MutationUpdateOrganizationArgs = {
  allowProfilesOutsideOrganization?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type MutationUpdatePortalSettingsArgs = {
  default_redirect_link: Scalars['String'];
  portal_settings_id: Scalars['String'];
};

export type MutationUpdatePortalSettingsV2Args = {
  input: UpdatePortalSettingsInput;
};

export type MutationUpdateRedirectUriArgs = {
  isDefault?: InputMaybe<Scalars['Boolean']>;
  redirectUriId: Scalars['String'];
  uri?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateSessionArgs = {
  session: UpdateSessionInput;
};

export type MutationUpdateTeamMfaRequirementArgs = {
  input: UpdateTeamMfaRequirementInput;
};

export type MutationUpdateWebhookEndpointArgs = {
  endpointUrl?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<Array<Scalars['String']>>;
  state?: InputMaybe<WebhookEndpointState>;
  webhookEndpointId: Scalars['String'];
};

export type MutationUpsertSamlX509CertificateArgs = {
  input: UpsertSamlX509CertificateInput;
};

export type MutationVerifyAuthenticationFactorEnrollmentArgs = {
  input: VerifyAuthenticationFactorEnrollmentInput;
};

export type MutationVerifyDomainStatusArgs = {
  input: VerifyDomainStatusInput;
};

export type MutationVerifyRedirectUriArgs = {
  input: VerifyRedirectUriInput;
};

export type MyselfUpdated = {
  __typename?: 'MyselfUpdated';
  me: User;
};

export type NewlyCreatedKey = {
  __typename?: 'NewlyCreatedKey';
  key: Key;
  /** The value of the key. For API keys in production environments, this will be the only time the key's value is visible. */
  value: Scalars['String'];
};

export type NoAuditLogEventsFound = {
  __typename?: 'NoAuditLogEventsFound';
  message: Scalars['String'];
};

export type OAuthCredential = {
  __typename?: 'OAuthCredential';
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  externalKey: Scalars['String'];
  id: Scalars['ID'];
  isUsingCustomDomain: Scalars['Boolean'];
  redirectUri?: Maybe<Scalars['String']>;
  state: OAuthCredentialState;
  type: ConnectionType;
};

export type OAuthCredentialList = {
  __typename?: 'OAuthCredentialList';
  data: Array<OAuthCredential>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the OAuth Credential. */
export enum OAuthCredentialState {
  Invalid = 'Invalid',
  Valid = 'Valid',
}

export type OAuthSession = {
  __typename?: 'OAuthSession';
  createdAt: Scalars['DateTime'];
  errors: Array<ConnectionSessionError>;
  id: Scalars['ID'];
  profile?: Maybe<Profile>;
  state: OAuthSessionState;
  stateParameter: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Enum represents the state of the OAuth Session. */
export enum OAuthSessionState {
  Authorized = 'Authorized',
  Failed = 'Failed',
  Started = 'Started',
  Successful = 'Successful',
}

export type OAuthSessionsList = {
  __typename?: 'OAuthSessionsList';
  data: Array<OAuthSession>;
  listMetadata: ListMetadata;
};

export type OauthAndMagicLinkMigrationStatus = {
  __typename?: 'OauthAndMagicLinkMigrationStatus';
  connectionsToMigrate: Scalars['Int'];
  needsMigration: Scalars['Boolean'];
};

export type OauthAndMagicLinkMigrationStatuses = {
  __typename?: 'OauthAndMagicLinkMigrationStatuses';
  magicLink: OauthAndMagicLinkMigrationStatus;
  oauth: OauthAndMagicLinkMigrationStatus;
};

export type OauthConnectionMissingCredentials = {
  __typename?: 'OauthConnectionMissingCredentials';
  connectionId?: Maybe<Scalars['String']>;
};

/** An error pertaining to OAuth credentials. */
export type OauthCredentialsError = {
  __typename?: 'OauthCredentialsError';
  field?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type OauthCredentialsNotFound = {
  __typename?: 'OauthCredentialsNotFound';
  environmentId: Scalars['String'];
};

export type OauthCredentialsUpdated = {
  __typename?: 'OauthCredentialsUpdated';
  credentials: OAuthCredential;
};

export type OidcSession = {
  __typename?: 'OidcSession';
  createdAt: Scalars['DateTime'];
  errors: Array<ConnectionSessionError>;
  id: Scalars['ID'];
  profile?: Maybe<Profile>;
  state: OidcSessionState;
  stateParameter?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Enum represents the state of the OIDC Session. */
export enum OidcSessionState {
  Authorized = 'Authorized',
  Failed = 'Failed',
  Started = 'Started',
  Successful = 'Successful',
}

export type OidcSessionsList = {
  __typename?: 'OidcSessionsList';
  data: Array<OidcSession>;
  listMetadata: ListMetadata;
};

export type OnboardingCompanyDetailsInput = {
  name: Scalars['String'];
  size: Scalars['String'];
};

export type OnboardingCompleteStepInput = {
  /** The next step in the onboarding process, if one exists. */
  nextStep?: InputMaybe<Scalars['String']>;
};

export type OnboardingCompleteStepPayload = {
  __typename?: 'OnboardingCompleteStepPayload';
  team?: Maybe<Team>;
};

export type OnboardingEnterTeamDetailsInput = {
  company: OnboardingCompanyDetailsInput;
  /** If the user wants to create a Slack Connect channel */
  createSharedSlackChannel?: InputMaybe<Scalars['Boolean']>;
  /** Whether the CRM integration should be disabled. */
  noCrm?: InputMaybe<Scalars['Boolean']>;
  urgency: Scalars['String'];
  user: OnboardingUserDetailsInput;
};

export type OnboardingUserDetailsInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  allowProfilesOutsideOrganization: Scalars['Boolean'];
  auditLogStream?: Maybe<AuditLogStream>;
  connections: ConnectionOrUntypedConnectionList;
  createdAt: Scalars['DateTime'];
  directories: DirectoryOrUntypedDirectoryList;
  domains: Array<OrganizationDomain>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OrganizationConnectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<ConnectionState>;
  type?: InputMaybe<ConnectionType>;
};

export type OrganizationDirectoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type OrganizationCreated = {
  __typename?: 'OrganizationCreated';
  organization: Organization;
};

export type OrganizationDomain = {
  __typename?: 'OrganizationDomain';
  domain: Scalars['String'];
  id: Scalars['ID'];
};

export type OrganizationDomainAlreadyInUse = {
  __typename?: 'OrganizationDomainAlreadyInUse';
  domain: Scalars['String'];
  organization: Organization;
};

export type OrganizationList = {
  __typename?: 'OrganizationList';
  data: Array<Organization>;
  listMetadata: ListMetadata;
};

/** An error indicating that the Organization is missing required domains. For example, an Organization that does not allow Profiles outside of it requires at least one User Email Domain. */
export type OrganizationMissingDomains = {
  __typename?: 'OrganizationMissingDomains';
  /** The ID of the impacted Organization. This will be `null` if this error is encountered while creating a new Organization. */
  organizationId?: Maybe<Scalars['String']>;
};

export type OrganizationNotFound = {
  __typename?: 'OrganizationNotFound';
  organizationId: Scalars['String'];
};

export type PasswordlessSession = {
  __typename?: 'PasswordlessSession';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  profile?: Maybe<Profile>;
  state: PasswordlessSessionState;
  updatedAt: Scalars['DateTime'];
};

export type PasswordlessSessionList = {
  __typename?: 'PasswordlessSessionList';
  data: Array<PasswordlessSession>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the Passwordless Session. */
export enum PasswordlessSessionState {
  Delivered = 'Delivered',
  Expired = 'Expired',
  New = 'New',
  Pending = 'Pending',
  Verified = 'Verified',
}

export type PeopleHrDirectory = {
  __typename?: 'PeopleHrDirectory';
  apiKey?: Maybe<Scalars['String']>;
};

export type PortalLinkGenerated = {
  __typename?: 'PortalLinkGenerated';
  portalLink: Scalars['String'];
};

export type PortalProgressionSet = {
  __typename?: 'PortalProgressionSet';
  portalProgression: Portal_PortalProgression;
};

/** Enum represents the state of an Admin Portal session. */
export enum PortalSessionState {
  Exchanged = 'Exchanged',
  Pending = 'Pending',
}

export type PortalSettings = {
  __typename?: 'PortalSettings';
  defaultConnectionSuccessLink?: Maybe<Scalars['String']>;
  defaultDirectorySuccessLink?: Maybe<Scalars['String']>;
  defaultRedirectLink: Scalars['String'];
  faviconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
};

export type PortalSettingsUpdated = {
  __typename?: 'PortalSettingsUpdated';
  portalSettings: PortalSettings;
};

export type PortalSetupLink = {
  __typename?: 'PortalSetupLink';
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  intent: PortalSetupLinkIntent;
  state: PortalSetupLinkState;
  token: Scalars['String'];
  url: Scalars['String'];
};

export type PortalSetupLinkGenerated = {
  __typename?: 'PortalSetupLinkGenerated';
  portalSetupLink: PortalSetupLink;
};

/** Enum represents the intent for a given of an Admin Portal setup link. */
export enum PortalSetupLinkIntent {
  Dsync = 'Dsync',
  LogStreams = 'LogStreams',
  Sso = 'Sso',
}

export type PortalSetupLinkList = {
  __typename?: 'PortalSetupLinkList';
  data: Array<PortalSetupLink>;
};

/** Enum represents the state of an Admin Portal setup link. */
export enum PortalSetupLinkState {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type Portal_AddAdpConnectionSslCertificateInput = {
  connectionId: Scalars['String'];
  privateKey: Scalars['String'];
  value: Scalars['String'];
};

export type Portal_AddAdpConnectionSslCertificateResult =
  | ConnectionNotFound
  | Portal_AdpConnectionSslCertificateAdded
  | Portal_InvalidAdpConnectionSslCertificate;

export type Portal_AdpConnectionSslCertificate = {
  __typename?: 'Portal_AdpConnectionSslCertificate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type Portal_AdpConnectionSslCertificateAdded = {
  __typename?: 'Portal_AdpConnectionSslCertificateAdded';
  adpConnectionSslCertificate: Portal_AdpConnectionSslCertificate;
};

export type Portal_ConnectionCreated = {
  __typename?: 'Portal_ConnectionCreated';
  connection: Portal_Connection;
};

export type Portal_ConnectionOrUntypedConnection =
  | Portal_Connection
  | Portal_UntypedConnection;

export type Portal_ConnectionUpdated = {
  __typename?: 'Portal_ConnectionUpdated';
  connection: Portal_Connection;
};

export type Portal_ConnectionUpdatedFromMetadataUrl = {
  __typename?: 'Portal_ConnectionUpdatedFromMetadataUrl';
  connection: Portal_Connection;
};

export type Portal_ConnectionUpdatedFromMetadataXml = {
  __typename?: 'Portal_ConnectionUpdatedFromMetadataXml';
  connection: Portal_Connection;
};

export type Portal_CreateConnectionFromUntypedInput = {
  connectionId: Scalars['String'];
  type: ConnectionType;
};

export type Portal_CreateDirectoryFromUntypedInput = {
  directoryId: Scalars['String'];
  domain?: InputMaybe<Scalars['String']>;
  type: DirectoryType;
};

export type Portal_DeleteDirectoryInput = {
  id: Scalars['String'];
};

export type Portal_DeleteDirectoryPayload = {
  __typename?: 'Portal_DeleteDirectoryPayload';
  directory?: Maybe<Portal_Directory>;
};

export type Portal_DeleteSamlX509CertificateInput = {
  samlX509CertificateId: Scalars['String'];
};

export type Portal_DeleteSamlX509CertificateResult =
  | Portal_SamlX509CertificateDeleted
  | SamlX509CertificateNotFound;

export type Portal_DirectoryCountsSummary = {
  __typename?: 'Portal_DirectoryCountsSummary';
  groupCount: Scalars['Int'];
  userCount: Scalars['Int'];
};

export type Portal_DirectoryCreated = {
  __typename?: 'Portal_DirectoryCreated';
  directory: Portal_Directory;
};

export type Portal_DirectoryCustomAttribute = {
  __typename?: 'Portal_DirectoryCustomAttribute';
  id: Scalars['ID'];
  isRequired: Scalars['Boolean'];
  name: Scalars['String'];
};

export type Portal_DirectoryCustomAttributeList = {
  __typename?: 'Portal_DirectoryCustomAttributeList';
  data: Array<Portal_DirectoryCustomAttribute>;
};

export type Portal_DirectoryCustomAttributeMapping = {
  __typename?: 'Portal_DirectoryCustomAttributeMapping';
  attribute: Scalars['String'];
  customAttributeId: Scalars['ID'];
  id: Scalars['ID'];
};

export type Portal_DirectoryCustomAttributeMappingInput = {
  attribute: Scalars['String'];
  customAttributeId: Scalars['ID'];
};

export type Portal_DirectoryCustomAttributeMappingsSet = {
  __typename?: 'Portal_DirectoryCustomAttributeMappingsSet';
  mappings: Array<Portal_DirectoryCustomAttributeMapping>;
};

export type Portal_DirectoryOrUntypedDirectory =
  | Portal_Directory
  | Portal_UntypedDirectory;

export type Portal_DirectorySyncRun = {
  __typename?: 'Portal_DirectorySyncRun';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  directoryId: Scalars['String'];
  errors: Array<DirectorySyncRunError>;
  groupCounts: Portal_DirectorySyncRunResourceCounts;
  id: Scalars['String'];
  state: DirectorySyncRunState;
  updatedAt: Scalars['DateTime'];
  userCounts: Portal_DirectorySyncRunResourceCounts;
};

export type Portal_DirectorySyncRunResourceCounts = {
  __typename?: 'Portal_DirectorySyncRunResourceCounts';
  created: Scalars['Float'];
  deleted: Scalars['Float'];
  processed: Scalars['Float'];
  updated: Scalars['Float'];
};

export type Portal_InvalidAdpConnectionSslCertificate = {
  __typename?: 'Portal_InvalidAdpConnectionSslCertificate';
  privateKey: Scalars['String'];
  value: Scalars['String'];
};

export type Portal_MetadataFetchFailed = {
  __typename?: 'Portal_MetadataFetchFailed';
  reason: Scalars['String'];
};

export type Portal_MetadataParseFailed = {
  __typename?: 'Portal_MetadataParseFailed';
  reason: Scalars['String'];
};

export type Portal_SamlConnection = {
  __typename?: 'Portal_SamlConnection';
  idpEntityId?: Maybe<Scalars['String']>;
};

export type Portal_SamlX509CertificateDeleted = {
  __typename?: 'Portal_SamlX509CertificateDeleted';
  samlX509Certificate: Portal_SamlX509Certificate;
};

export type Portal_SetDirectoryCustomAttributeMappingsInput = {
  directoryId: Scalars['ID'];
  mappings: Array<Portal_DirectoryCustomAttributeMappingInput>;
};

export type Portal_SetDirectoryCustomAttributeMappingsResult =
  | DirectoryCustomAttributeNotFound
  | DirectoryNotFound
  | Portal_DirectoryCustomAttributeMappingsSet;

export type Portal_UpdateConnectionFromMetadataUrlInput = {
  connectionId: Scalars['String'];
  metadataUrl: Scalars['String'];
};

export type Portal_UpdateConnectionFromMetadataUrlResult =
  | ConnectionNotFound
  | InvalidSamlX509Certificate
  | Portal_ConnectionUpdatedFromMetadataUrl
  | Portal_MetadataFetchFailed;

export type Portal_UpdateConnectionFromMetadataXmlInput = {
  connectionId: Scalars['String'];
  metadataXml: Scalars['String'];
};

export type Portal_UpdateConnectionFromMetadataXmlResult =
  | ConnectionNotFound
  | InvalidSamlX509Certificate
  | Portal_ConnectionUpdatedFromMetadataXml
  | Portal_MetadataParseFailed;

export type Portal_UpdateConnectionInput = {
  connectionId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  oidc?: InputMaybe<Portal_UpdateOidcConnectionInput>;
  saml?: InputMaybe<Portal_UpdateSamlConnectionInput>;
};

export type Portal_UpdateConnectionResult =
  | ConnectionNotFound
  | InvalidSamlX509Certificate
  | Portal_ConnectionUpdated
  | VerifyConnectionFailed;

export type Portal_UpdateDirectoryAttributeMapInput = {
  directoryAttributeMapId: Scalars['String'];
  emailsAttribute?: InputMaybe<Scalars['String']>;
  externalIdAttribute?: InputMaybe<Scalars['String']>;
  firstNameAttribute?: InputMaybe<Scalars['String']>;
  groupNameAttribute?: InputMaybe<Scalars['String']>;
  jobTitleAttribute?: InputMaybe<Scalars['String']>;
  lastNameAttribute?: InputMaybe<Scalars['String']>;
  usernameAttribute?: InputMaybe<Scalars['String']>;
};

export type Portal_UpdateDirectoryAttributeMapPayload = {
  __typename?: 'Portal_UpdateDirectoryAttributeMapPayload';
  directoryAttributeMap?: Maybe<DirectoryAttributeMap>;
};

export type Portal_UpdateOidcConnectionInput = {
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  discoveryEndpoint?: InputMaybe<Scalars['String']>;
};

export type Portal_UpdateSamlConnectionInput = {
  idpEntityId?: InputMaybe<Scalars['String']>;
  idpMetadataUrl?: InputMaybe<Scalars['String']>;
  idpUrl?: InputMaybe<Scalars['String']>;
  x509Certs?: InputMaybe<Array<Scalars['String']>>;
};

export type Portal_UpdateUntypedConnectionResult =
  | ConnectionIsAlreadyTyped
  | ConnectionNotFound
  | Portal_ConnectionCreated;

export type Portal_UpdateUntypedDirectoryResult =
  | DirectoryIsAlreadyTyped
  | DirectoryNotFound
  | Portal_DirectoryCreated;

export type Profile = {
  __typename?: 'Profile';
  connectionType: ConnectionType;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idpId?: Maybe<Scalars['String']>;
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  rawAttributes?: Maybe<Scalars['JSON']>;
  state: ProfileState;
  updatedAt: Scalars['DateTime'];
};

export type ProfileByIdpIdInput = {
  connectionId: Scalars['String'];
  idpId: Scalars['String'];
};

export type ProfileNotAllowedOutsideOrganizationError = {
  __typename?: 'ProfileNotAllowedOutsideOrganizationError';
  code: ConnectionSessionErrorCode;
  organizationDomains: Array<Scalars['String']>;
  profileDomain: Scalars['String'];
  reason: Scalars['String'];
};

/** Enum represents the state of the profile. */
export enum ProfileState {
  Invalid = 'Invalid',
  Valid = 'Valid',
}

export type ProjectV2 = {
  __typename?: 'ProjectV2';
  environments: Array<Environment>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProjectV2EnvironmentsArgs = {
  type?: InputMaybe<EnvironmentType>;
};

export type Query = {
  __typename?: 'Query';
  activePortalSetupLinks: PortalSetupLinkList;
  auditLogEvent: AuditLogEvent;
  auditLogEvents: AuditLogEventList;
  auditLogExport: AuditLogExport;
  auditLogSchemaPreview: AuditLogSchemaPreview;
  auditLogStream?: Maybe<AuditLogStream>;
  auditLogTargets: AuditLogTargetList;
  auditLogValidator: AuditLogValidator;
  auditLogValidators: AuditLogValidatorList;
  authenticationFactors: AuthenticationFactorList;
  connection: ConnectionOrUntypedConnection;
  connectionSession: ConnectionSession;
  currentEnvironment: Environment;
  currentTeam?: Maybe<Team>;
  directory: DirectoryOrUntypedDirectory;
  directoryAttributeMap: DirectoryAttributeMap;
  directoryCustomAttributeMappings: DirectoryCustomAttributeMappingList;
  directoryCustomAttributes: DirectoryCustomAttributeList;
  directorySyncRun: DirectorySyncRun;
  directoryUser: DirectoryUser;
  emailNotificationPreferences: EmailNotificationPreferences;
  environment: Environment;
  event: Event;
  eventActions: Array<EventAction>;
  events: EventList;
  internalDirectory: InternalDirectory;
  isMfaEligible: Scalars['Boolean'];
  keys: KeyList;
  me: User;
  oauthCredentialsByType: OAuthCredentialList;
  organization: Organization;
  organizations: OrganizationList;
  passwordlessSessions: PasswordlessSessionList;
  portal_auditLogEvent: Portal_AuditLogEvent;
  portal_auditLogEvents: Portal_AuditLogEventList;
  portal_auditLogExport: Portal_AuditLogExport;
  portal_auditLogTargets: Portal_AuditLogTargetList;
  portal_auditLogValidators: Portal_AuditLogValidatorList;
  portal_connectionSession: ConnectionSession;
  portal_connections: Portal_ConnectionList;
  portal_directories: Portal_DirectoryList;
  portal_directory: Portal_Directory;
  portal_directoryCustomAttributes: Portal_DirectoryCustomAttributeList;
  portal_portalSession: Portal_PortalSession;
  profile: Profile;
  profileByIdpId: Profile;
  redirectUris: RedirectUriList;
  request: Request;
  requests: RequestList;
  session: Session;
  systemMappedDirectoryCustomAttributes: Array<SystemMappedDirectoryCustomAttribute>;
  userAuthenticationFactors: UserAuthenticationFactorList;
  webhook: Webhook;
  webhookEndpoint: WebhookEndpoint;
  webhookEndpoints: WebhookEndpointList;
  webhookEvents: Array<WebhookEvent>;
};

export type QueryActivePortalSetupLinksArgs = {
  organizationId: Scalars['String'];
};

export type QueryAuditLogEventArgs = {
  id: Scalars['String'];
};

export type QueryAuditLogEventsArgs = {
  actions?: InputMaybe<Array<Scalars['String']>>;
  actors?: InputMaybe<Array<Scalars['String']>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  targets?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryAuditLogExportArgs = {
  id: Scalars['String'];
};

export type QueryAuditLogSchemaPreviewArgs = {
  action: Scalars['String'];
  environmentId?: InputMaybe<Scalars['String']>;
  schema?: InputMaybe<Scalars['JSON']>;
  targets: Array<Scalars['String']>;
};

export type QueryAuditLogStreamArgs = {
  id: Scalars['String'];
};

export type QueryAuditLogTargetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  target?: InputMaybe<Scalars['String']>;
};

export type QueryAuditLogValidatorArgs = {
  id: Scalars['String'];
};

export type QueryAuditLogValidatorsArgs = {
  action?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryAuthenticationFactorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryConnectionArgs = {
  id: Scalars['String'];
};

export type QueryConnectionSessionArgs = {
  id: Scalars['String'];
};

export type QueryDirectoryArgs = {
  id: Scalars['String'];
};

export type QueryDirectoryAttributeMapArgs = {
  id: Scalars['String'];
};

export type QueryDirectoryCustomAttributeMappingsArgs = {
  directoryId: Scalars['String'];
};

export type QueryDirectoryCustomAttributesArgs = {
  environmentId: Scalars['String'];
};

export type QueryDirectorySyncRunArgs = {
  id: Scalars['String'];
};

export type QueryDirectoryUserArgs = {
  id: Scalars['String'];
};

export type QueryEnvironmentArgs = {
  id: Scalars['String'];
};

export type QueryEventArgs = {
  id: Scalars['String'];
};

export type QueryEventActionsArgs = {
  environmentId: Scalars['String'];
};

export type QueryEventsArgs = {
  action?: InputMaybe<Array<Scalars['String']>>;
  action_type?: InputMaybe<Array<Scalars['String']>>;
  actor_id?: InputMaybe<Array<Scalars['String']>>;
  actor_name?: InputMaybe<Array<Scalars['String']>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  group?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  occurred_at?: InputMaybe<Scalars['DateTime']>;
  occurred_at_gt?: InputMaybe<Scalars['DateTime']>;
  occurred_at_gte?: InputMaybe<Scalars['DateTime']>;
  occurred_at_lt?: InputMaybe<Scalars['DateTime']>;
  occurred_at_lte?: InputMaybe<Scalars['DateTime']>;
  search?: InputMaybe<Scalars['String']>;
  target_id?: InputMaybe<Array<Scalars['String']>>;
  target_name?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryInternalDirectoryArgs = {
  id: Scalars['String'];
};

export type QueryKeysArgs = {
  environmentId: Scalars['String'];
  scope?: InputMaybe<KeyScope>;
};

export type QueryOauthCredentialsByTypeArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  type: ConnectionType;
};

export type QueryOrganizationArgs = {
  id: Scalars['String'];
};

export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  domains?: InputMaybe<Array<Scalars['String']>>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryPasswordlessSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  connectionId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryPortal_AuditLogEventArgs = {
  id: Scalars['String'];
};

export type QueryPortal_AuditLogEventsArgs = {
  actions?: InputMaybe<Array<Scalars['String']>>;
  actors?: InputMaybe<Array<Scalars['String']>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  targets?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryPortal_AuditLogExportArgs = {
  id: Scalars['String'];
};

export type QueryPortal_AuditLogTargetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  target?: InputMaybe<Scalars['String']>;
};

export type QueryPortal_AuditLogValidatorsArgs = {
  action?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryPortal_ConnectionSessionArgs = {
  id: Scalars['String'];
};

export type QueryPortal_ConnectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryPortal_DirectoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryPortal_DirectoryArgs = {
  id: Scalars['String'];
};

export type QueryPortal_DirectoryCustomAttributesArgs = {
  directoryId: Scalars['String'];
};

export type QueryProfileArgs = {
  id: Scalars['String'];
};

export type QueryProfileByIdpIdArgs = {
  input: ProfileByIdpIdInput;
};

export type QueryRedirectUrisArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryRequestArgs = {
  id: Scalars['String'];
};

export type QueryRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  method?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
};

export type QueryWebhookArgs = {
  id: Scalars['String'];
};

export type QueryWebhookEndpointArgs = {
  id: Scalars['String'];
};

export type QueryWebhookEndpointsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type ReceivedAttribute = {
  __typename?: 'ReceivedAttribute';
  attribute: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type RedirectUri = {
  __typename?: 'RedirectURI';
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  uri: Scalars['String'];
};

export type RedirectUriChecked = {
  __typename?: 'RedirectUriChecked';
  success: Scalars['Boolean'];
};

export type RedirectUriList = {
  __typename?: 'RedirectUriList';
  data: Array<RedirectUri>;
  listMetadata: ListMetadata;
};

export type RedirectUrisSet = {
  __typename?: 'RedirectUrisSet';
  redirectUris: Array<RedirectUri>;
};

export type RegisterCustomDomainInput = {
  domain: Scalars['String'];
};

export type RegisterCustomDomainResult =
  | CustomDomainRegistered
  | InvalidDomain
  | OauthCredentialsNotFound;

export type Request = {
  __typename?: 'Request';
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  method: Scalars['String'];
  path: Scalars['String'];
  requestBody?: Maybe<Scalars['JSON']>;
  requestHeaders: Scalars['JSON'];
  requestId?: Maybe<Scalars['String']>;
  requestQuery?: Maybe<Scalars['JSON']>;
  responseBody?: Maybe<Scalars['JSON']>;
  source?: Maybe<Scalars['String']>;
  status: Scalars['Float'];
};

export type RequestList = {
  __typename?: 'RequestList';
  data: Array<Request>;
  listMetadata: ListMetadata;
};

export type ResendNotAllowedForEnvironment = {
  __typename?: 'ResendNotAllowedForEnvironment';
  environmentId: Scalars['String'];
};

export type ResendWebhookEventFailed = {
  __typename?: 'ResendWebhookEventFailed';
  webhookId: Scalars['String'];
};

export type ResendWebhookEventInput = {
  webhookId: Scalars['String'];
};

export type ResendWebhookEventResult =
  | ResendNotAllowedForEnvironment
  | ResendWebhookEventFailed
  | ResendWebhookEventSuccessful
  | WebhookEventNotFound;

export type ResendWebhookEventSuccessful = {
  __typename?: 'ResendWebhookEventSuccessful';
  webhookId: Scalars['String'];
};

export type ResetUserMfaSettingsInput = {
  userId: Scalars['String'];
};

export type ResetUserMfaSettingsResult = UserMfaSettingsReset;

export type RipplingDirectory = {
  __typename?: 'RipplingDirectory';
  apiKey?: Maybe<Scalars['String']>;
};

export type RoleAssignedToInternalDirectoryGroup = {
  __typename?: 'RoleAssignedToInternalDirectoryGroup';
  internalDirectoryGroup: InternalDirectoryGroup;
};

export type RoleUnassignedFromInternalDirectoryGroup = {
  __typename?: 'RoleUnassignedFromInternalDirectoryGroup';
  internalDirectoryGroup: InternalDirectoryGroup;
};

export type S3LogStream = {
  __typename?: 'S3LogStream';
  accountId?: Maybe<Scalars['String']>;
  bucketName?: Maybe<Scalars['String']>;
  bucketPath?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
};

export type SamlSession = {
  __typename?: 'SAMLSession';
  createdAt: Scalars['DateTime'];
  errors: Array<ConnectionSessionError>;
  id: Scalars['ID'];
  initiator?: Maybe<SamlSessionInitiator>;
  profile?: Maybe<Profile>;
  redirectUri?: Maybe<Scalars['String']>;
  relayState?: Maybe<Scalars['String']>;
  samlRequest?: Maybe<Scalars['String']>;
  samlResponse?: Maybe<Scalars['String']>;
  state: SamlSessionState;
  stateParameter?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Enum represents the state of the SAML Session. */
export enum SamlSessionState {
  Authorized = 'Authorized',
  Failed = 'Failed',
  Started = 'Started',
  Successful = 'Successful',
}

export type SamlSessionsList = {
  __typename?: 'SAMLSessionsList';
  data: Array<SamlSession>;
  listMetadata: ListMetadata;
};

export type SsoDemoApplication = {
  __typename?: 'SSODemoApplication';
  connection?: Maybe<Connection>;
  connectionType: ConnectionType;
  id: Scalars['ID'];
  idpId?: Maybe<Scalars['String']>;
};

export type SsoDemoUser = {
  __typename?: 'SSODemoUser';
  connectionType: ConnectionType;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idpId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type SamlConnection = {
  __typename?: 'SamlConnection';
  idpEntityId?: Maybe<Scalars['String']>;
};

/** Enum represents how the SAML Session was initiated. */
export enum SamlSessionInitiator {
  AdminPortalTest = 'AdminPortalTest',
  IdentityProvider = 'IdentityProvider',
  ServiceProvider = 'ServiceProvider',
}

export type SamlX509Certificate = {
  __typename?: 'SamlX509Certificate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  notAfter?: Maybe<Scalars['DateTime']>;
  notBefore?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type SamlX509CertificateDeleted = {
  __typename?: 'SamlX509CertificateDeleted';
  samlX509Certificate: SamlX509Certificate;
};

export type SamlX509CertificateNotFound = {
  __typename?: 'SamlX509CertificateNotFound';
  samlX509CertificateId: Scalars['String'];
};

export type SamlX509CertificateUpserted = {
  __typename?: 'SamlX509CertificateUpserted';
  samlX509Certificate: SamlX509Certificate;
};

/** Enum represents why the SAML X509 Certificate was not valid. */
export enum SamlX509CertificateValidationErrorCode {
  ExpiredCertificate = 'ExpiredCertificate',
  MalformedCertificate = 'MalformedCertificate',
}

export type Session = {
  __typename?: 'Session';
  currentEnvironment: Environment;
};

export type SetConnectionPortalProgressionInput = {
  connectionId: Scalars['String'];
  currentStepIdentifier: Scalars['String'];
  formData?: InputMaybe<Scalars['JSON']>;
  lastCompletedStepNumber: Scalars['Float'];
  portalData?: InputMaybe<Scalars['JSON']>;
};

export type SetConnectionPortalProgressionResult =
  | ConnectionNotFound
  | PortalProgressionSet;

export type SetDirectoryCustomAttributeMappingsInput = {
  directoryId: Scalars['ID'];
  mappings: Array<DirectoryCustomAttributeMappingInput>;
};

export type SetDirectoryCustomAttributeMappingsResult =
  | DirectoryCustomAttributeMappingsSet
  | DirectoryCustomAttributeNotFound
  | DirectoryNotFound;

export type SetDirectoryPortalProgressionInput = {
  currentStepIdentifier: Scalars['String'];
  directoryId: Scalars['String'];
  formData?: InputMaybe<Scalars['JSON']>;
  lastCompletedStepNumber: Scalars['Float'];
  portalData?: InputMaybe<Scalars['JSON']>;
};

export type SetDirectoryPortalProgressionResult =
  | DirectoryNotFound
  | PortalProgressionSet;

export type SetDomainsForOrganizationInput = {
  domains: Array<Scalars['String']>;
  organizationId: Scalars['String'];
};

export type SetDomainsForOrganizationPayload = {
  __typename?: 'SetDomainsForOrganizationPayload';
  organization?: Maybe<Organization>;
};

export type SetEmailNotificationPreferenceInput = {
  category: EmailNotificationCategory;
  preference: EmailNotificationPreferenceConfiguration;
};

export type SetEmailNotificationPreferencesInput = {
  preferences: Array<SetEmailNotificationPreferenceInput>;
};

export type SetEmailNotificationPreferencesResult =
  EmailNotificationPreferences;

export type SetRedirectUriInput = {
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  uri: Scalars['String'];
};

export type SetRedirectUrisInput = {
  environmentId: Scalars['String'];
  redirectUris: Array<SetRedirectUriInput>;
};

export type SetRedirectUrisResult =
  | InvalidRedirectUriProcotol
  | InvalidWildcardRedirectUri
  | RedirectUrisSet;

export type SetTeamDefaultRoleInput = {
  defaultRole: UsersOrganizationsRole;
};

export type SetTeamDefaultRoleResult = TeamDefaultRoleSet;

export type SharedSlackChannelGenerated = {
  __typename?: 'SharedSlackChannelGenerated';
  slackChannel: SlackChannel;
};

export type SlackChannel = {
  __typename?: 'SlackChannel';
  externalChannelId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  slackTeamId?: Maybe<Scalars['String']>;
};

export type SmsAuthenticationFactorType = {
  __typename?: 'SmsAuthenticationFactorType';
  phoneNumber: Scalars['String'];
};

export type SplunkLogStream = {
  __typename?: 'SplunkLogStream';
  hecToken?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SsoAlreadyConnectedForDomain = {
  __typename?: 'SsoAlreadyConnectedForDomain';
  domain: Scalars['String'];
};

export type StartSyncingInternalDirectoryInput = {
  id: Scalars['String'];
};

export type StartSyncingInternalDirectoryResult = SyncingInternalDirectory;

export type StripeBillingData = {
  __typename?: 'StripeBillingData';
  billingAddress: BillingAddress;
  defaultPaymentMethod: DefaultPaymentMethod;
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success: Scalars['Boolean'];
};

export type SyncingInternalDirectory = {
  __typename?: 'SyncingInternalDirectory';
  internalDirectory: InternalDirectory;
};

export type SystemMappedDirectoryCustomAttribute = {
  __typename?: 'SystemMappedDirectoryCustomAttribute';
  description: Scalars['String'];
  name: Scalars['String'];
};

export type Team = {
  __typename?: 'Team';
  billingDetails: TeamBillingDetails;
  connection?: Maybe<Connection>;
  defaultUserProvisioningRole?: Maybe<UsersOrganizationsRole>;
  endpoint_url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internalDirectory?: Maybe<InternalDirectory>;
  isMfaRequired: Scalars['Boolean'];
  isUsingConsumerDomain: Scalars['Boolean'];
  name: Scalars['String'];
  onboarding: TeamOnboardingStatus;
  projectsV2: Array<ProjectV2>;
  sdk_language?: Maybe<Scalars['String']>;
  slackChannels: Array<SlackChannel>;
  ssoDemoUser?: Maybe<SsoDemoUser>;
  sso_onboarding_step?: Maybe<Scalars['Int']>;
  stripeBillingData: StripeBillingData;
  stripe_customer_id?: Maybe<Scalars['String']>;
  users_teams: Array<UsersTeam>;
};

export type TeamBillingDetails = {
  __typename?: 'TeamBillingDetails';
  connections: TeamConnectionStats;
  email?: Maybe<Scalars['String']>;
  /** Whether the team has a billing address saved to Stripe. */
  hasBillingAddress: Scalars['Boolean'];
  /** Whether the team has a payment method setup. */
  hasPaymentMethod: Scalars['Boolean'];
};

export type TeamConnectionStats = {
  __typename?: 'TeamConnectionStats';
  enterpriseConnectionCount: Scalars['Float'];
};

export type TeamDefaultRoleSet = {
  __typename?: 'TeamDefaultRoleSet';
  team: Team;
};

export type TeamMfaIneligible = {
  __typename?: 'TeamMfaIneligible';
  teamId: Scalars['String'];
};

export type TeamMfaRequirementUpdated = {
  __typename?: 'TeamMfaRequirementUpdated';
  team: Team;
};

export type TeamOnboarded = {
  __typename?: 'TeamOnboarded';
  status: Scalars['String'];
};

export type TeamOnboarding = {
  __typename?: 'TeamOnboarding';
  /** The next onboarding step the team needs to go through. */
  nextStep: Scalars['String'];
  status: Scalars['String'];
};

export type TeamOnboardingStatus = TeamOnboarded | TeamOnboarding;

export type TotpAuthenticationFactorType = {
  __typename?: 'TotpAuthenticationFactorType';
  issuer: Scalars['String'];
  secret: Scalars['String'];
  user: Scalars['String'];
};

export type TotpUserAuthenticationFactorEnrolled = {
  __typename?: 'TotpUserAuthenticationFactorEnrolled';
  challengeId: Scalars['String'];
  qrCode: Scalars['String'];
  secret: Scalars['String'];
  userAuthenticationFactor: UserAuthenticationFactor;
};

export type UnassignRoleFromInternalDirectoryGroupInput = {
  internalDirectoryGroupId: Scalars['String'];
};

export type UnassignRoleFromInternalDirectoryGroupResult =
  | InternalDirectoryGroupNotFound
  | RoleUnassignedFromInternalDirectoryGroup;

/** A Connection that has not yet been assigned a type. */
export type UntypedConnection = {
  __typename?: 'UntypedConnection';
  environment: Environment;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
};

/** A Directory that has not yet been assigned a type. */
export type UntypedDirectory = {
  __typename?: 'UntypedDirectory';
  environment: Environment;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
};

export type UpdateBambooHrDirectoryInput = {
  apiKey?: InputMaybe<Scalars['String']>;
  subdomain?: InputMaybe<Scalars['String']>;
};

export type UpdateBreatheHrDirectoryInput = {
  apiKey?: InputMaybe<Scalars['String']>;
};

export type UpdateCezanneHrDirectoryInput = {
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
};

export type UpdateConnectionFromMetadataUrlInput = {
  connectionId: Scalars['String'];
  metadataUrl: Scalars['String'];
};

export type UpdateConnectionFromMetadataUrlResult =
  | ConnectionNotFound
  | ConnectionUpdatedFromMetadataUrl
  | InvalidSamlX509Certificate
  | MetadataFetchFailed;

export type UpdateConnectionFromMetadataXmlInput = {
  connectionId: Scalars['String'];
  metadataXml: Scalars['String'];
};

export type UpdateConnectionFromMetadataXmlResult =
  | ConnectionNotFound
  | ConnectionUpdatedFromMetadataXml
  | InvalidSamlX509Certificate
  | MetadataParseFailed;

export type UpdateConnectionInput = {
  connectionId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  oidc?: InputMaybe<UpdateOidcConnectionInput>;
  saml?: InputMaybe<UpdateSamlConnectionInput>;
};

export type UpdateConnectionResult =
  | ConnectionNotFound
  | ConnectionUpdated
  | InvalidSamlX509Certificate;

export type UpdateDirectoryCustomAttributeInput = {
  directoryCustomAttributeId: Scalars['ID'];
  isRequired?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateDirectoryCustomAttributeResult =
  | DirectoryCustomAttributeNotFound
  | DirectoryCustomAttributeNotValid
  | DirectoryCustomAttributeUpdated;

export type UpdateDirectoryInput = {
  bambooHr?: InputMaybe<UpdateBambooHrDirectoryInput>;
  breatheHr?: InputMaybe<UpdateBreatheHrDirectoryInput>;
  cezanneHr?: InputMaybe<UpdateCezanneHrDirectoryInput>;
  directoryId: Scalars['String'];
  fourthHr?: InputMaybe<UpdateFourthHrDirectoryInput>;
  hibob?: InputMaybe<UpdateHibobDirectoryInput>;
  name?: InputMaybe<Scalars['String']>;
  peopleHr?: InputMaybe<UpdatePeopleHrDirectoryInput>;
  rippling?: InputMaybe<UpdateRipplingDirectoryInput>;
  workday?: InputMaybe<UpdateWorkdayDirectoryInput>;
};

export type UpdateDirectoryResult =
  | DirectoryNotFound
  | DirectoryProviderErrors
  | DirectoryUpdated;

export type UpdateFourthHrDirectoryInput = {
  organizationId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpdateHibobDirectoryInput = {
  apiToken?: InputMaybe<Scalars['String']>;
  serviceUserId?: InputMaybe<Scalars['String']>;
};

export type UpdateMyselfInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UpdateMyselfResult = MyselfUpdated;

export type UpdateOauthCredentialsInput = {
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  oauthCredentialId: Scalars['String'];
  redirectUri?: InputMaybe<Scalars['String']>;
};

export type UpdateOauthCredentialsResult =
  | InvalidOauthCredentials
  | OauthCredentialsNotFound
  | OauthCredentialsUpdated;

export type UpdateOidcConnectionInput = {
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  discoveryEndpoint?: InputMaybe<Scalars['String']>;
};

export type UpdateOrganizationPayload = {
  __typename?: 'UpdateOrganizationPayload';
  organization?: Maybe<Organization>;
};

export type UpdatePeopleHrDirectoryInput = {
  apiKey?: InputMaybe<Scalars['String']>;
};

export type UpdatePortalSettingsInput = {
  defaultConnectionSuccessLink?: InputMaybe<Scalars['String']>;
  defaultDirectorySuccessLink?: InputMaybe<Scalars['String']>;
  defaultRedirectLink?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpdatePortalSettingsResult =
  | InvalidRedirectUri
  | PortalSettingsUpdated;

export type UpdateRipplingDirectoryInput = {
  apiKey?: InputMaybe<Scalars['String']>;
};

export type UpdateSamlConnectionInput = {
  idpEntityId?: InputMaybe<Scalars['String']>;
  idpMetadataUrl?: InputMaybe<Scalars['String']>;
  idpUrl?: InputMaybe<Scalars['String']>;
  x509Certs?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateSessionInput = {
  currentEnvironmentId?: InputMaybe<Scalars['String']>;
  currentProjectID?: InputMaybe<Scalars['String']>;
};

export type UpdateTeamMfaRequirementInput = {
  requireMfa: Scalars['Boolean'];
};

export type UpdateTeamMfaRequirementResult =
  | TeamMfaIneligible
  | TeamMfaRequirementUpdated;

export type UpdateWorkdayDirectoryInput = {
  groupEndpoint?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  userEndpoint?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpsertSamlX509CertificateInput = {
  connectionId: Scalars['String'];
  value: Scalars['String'];
};

export type UpsertSamlX509CertificateResult =
  | ConnectionNotFound
  | SamlX509CertificateUpserted;

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isMfaConfigured: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  users_teams: Array<UsersTeam>;
};

export type UserAuthenticationFactor = {
  __typename?: 'UserAuthenticationFactor';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  state: UserAuthenticationFactorState;
  type: UserAuthenticationFactorType;
  updatedAt: Scalars['DateTime'];
};

export type UserAuthenticationFactorList = {
  __typename?: 'UserAuthenticationFactorList';
  data: Array<UserAuthenticationFactor>;
};

/** Represents if the factor has been activated. */
export enum UserAuthenticationFactorState {
  Active = 'Active',
  Pending = 'Pending',
}

/** Represents the possible types of additional authentication factors. */
export enum UserAuthenticationFactorType {
  Sms = 'Sms',
  Totp = 'Totp',
}

export type UserAuthenticationFactorVerified = {
  __typename?: 'UserAuthenticationFactorVerified';
  valid: Scalars['Boolean'];
};

export type UserInviteInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  role: UsersOrganizationsRole;
};

export type UserMfaSettingsReset = {
  __typename?: 'UserMfaSettingsReset';
  userId: Scalars['String'];
};

export type UserNotAuthorized = {
  __typename?: 'UserNotAuthorized';
  user: User;
};

/** Roles a user can have in an organization. */
export enum UsersOrganizationsRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Support = 'SUPPORT',
}

/** States a user can have in an organization. */
export enum UsersOrganizationsState {
  Accepted = 'ACCEPTED',
  Invited = 'INVITED',
}

export type UsersTeam = {
  __typename?: 'UsersTeam';
  id: Scalars['ID'];
  isManagedByDirectory: Scalars['Boolean'];
  role: UsersOrganizationsRole;
  state: UsersOrganizationsState;
  team: Team;
  user: User;
  user_id: Scalars['String'];
};

export type VerifyAuthenticationFactorEnrollmentInput = {
  challengeId: Scalars['String'];
  code: Scalars['String'];
};

export type VerifyAuthenticationFactorEnrollmentResult =
  UserAuthenticationFactorVerified;

export type VerifyConnectionFailed = {
  __typename?: 'VerifyConnectionFailed';
  connectionErrors: Array<ConnectionError>;
};

export type VerifyDomainStatusInput = {
  domain: Scalars['String'];
};

export type VerifyDomainStatusResult =
  | DomainStatusChecked
  | OauthCredentialsNotFound;

export type VerifyRedirectUriInput = {
  environmentId: Scalars['String'];
  redirectUri: Scalars['String'];
};

export type VerifyRedirectUriResult =
  | OauthCredentialsNotFound
  | RedirectUriChecked;

export type Webhook = {
  __typename?: 'Webhook';
  attempt: Scalars['Int'];
  deliverAfter?: Maybe<Scalars['DateTime']>;
  event: Scalars['String'];
  id: Scalars['ID'];
  metadata: Scalars['JSON'];
  requestBody?: Maybe<Scalars['JSON']>;
  responseBody?: Maybe<Scalars['JSON']>;
  responseStatusCode?: Maybe<Scalars['Int']>;
  state: WebhookState;
};

export type WebhookEndpoint = {
  __typename?: 'WebhookEndpoint';
  createdAt: Scalars['DateTime'];
  endpointUrl: Scalars['String'];
  events: Array<Scalars['String']>;
  id: Scalars['ID'];
  secret: Scalars['String'];
  state: WebhookEndpointState;
  statistics?: Maybe<WebhookEndpointStatistics>;
  webhooks: WebhookList;
};

export type WebhookEndpointWebhooksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<WebhookState>;
};

export type WebhookEndpointList = {
  __typename?: 'WebhookEndpointList';
  data: Array<WebhookEndpoint>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the webhook endpoint. */
export enum WebhookEndpointState {
  Active = 'Active',
  InActive = 'InActive',
}

export type WebhookEndpointStatistics = {
  __typename?: 'WebhookEndpointStatistics';
  aggregatePeriodDays: Scalars['Int'];
  deliveredCount: Scalars['Int'];
  deliveryRate?: Maybe<Scalars['Float']>;
  failedCount: Scalars['Int'];
  id: Scalars['ID'];
  inProgressCount: Scalars['Int'];
  scheduledCount: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type WebhookEvent = {
  __typename?: 'WebhookEvent';
  category: WebhookEventCategory;
  description: Scalars['String'];
  fixtures: Array<WebhookFixture>;
  id: Scalars['ID'];
};

/** Enum represents event category types of Webhooks */
export enum WebhookEventCategory {
  Connection = 'Connection',
  Directory = 'Directory',
  DirectoryGroup = 'DirectoryGroup',
  DirectoryUser = 'DirectoryUser',
}

/** Enum represents event fixtures of Webhooks */
export enum WebhookEventFixtures {
  ConnectionActivated = 'ConnectionActivated',
  ConnectionDeactivated = 'ConnectionDeactivated',
  ConnectionDeleted = 'ConnectionDeleted',
  DirectoryActivated = 'DirectoryActivated',
  DirectoryDeactivated = 'DirectoryDeactivated',
  DirectoryDeleted = 'DirectoryDeleted',
  GroupCreated = 'GroupCreated',
  GroupDeleted = 'GroupDeleted',
  GroupUpdated = 'GroupUpdated',
  UserAddedToGroup = 'UserAddedToGroup',
  UserCreated = 'UserCreated',
  UserDeleted = 'UserDeleted',
  UserRemovedFromGroup = 'UserRemovedFromGroup',
  UserUpdated = 'UserUpdated',
}

export type WebhookEventNotFound = {
  __typename?: 'WebhookEventNotFound';
  webhookId: Scalars['String'];
};

export type WebhookFixture = {
  __typename?: 'WebhookFixture';
  name: Scalars['String'];
  payload: Scalars['JSON'];
};

export type WebhookList = {
  __typename?: 'WebhookList';
  data: Array<Webhook>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the webhook. */
export enum WebhookState {
  Canceled = 'Canceled',
  Delivered = 'Delivered',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Scheduled = 'Scheduled',
}

export type WorkdayDirectory = {
  __typename?: 'WorkdayDirectory';
  groupEndpoint?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  userEndpoint?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type Portal_AuditLogEvent = {
  __typename?: 'portal_AuditLogEvent';
  action: Scalars['String'];
  actor: AuditLogEventActor;
  createdAt: Scalars['DateTime'];
  data: Scalars['JSON'];
  id: Scalars['ID'];
  occurredAt: Scalars['DateTime'];
  targets: Array<AuditLogEventTarget>;
  updatedAt: Scalars['DateTime'];
};

export type Portal_AuditLogEventList = {
  __typename?: 'portal_AuditLogEventList';
  data: Array<Portal_AuditLogEvent>;
  listMetadata: ListMetadata;
};

export type Portal_AuditLogExport = {
  __typename?: 'portal_AuditLogExport';
  id: Scalars['ID'];
  state: AuditLogExportState;
  url?: Maybe<Scalars['String']>;
};

export type Portal_AuditLogTarget = {
  __typename?: 'portal_AuditLogTarget';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  target: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Portal_AuditLogTargetList = {
  __typename?: 'portal_AuditLogTargetList';
  data: Array<Portal_AuditLogTarget>;
  listMetadata: ListMetadata;
};

export type Portal_AuditLogValidator = {
  __typename?: 'portal_AuditLogValidator';
  action: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  versions: Array<AuditLogValidatorVersion>;
};

export type Portal_AuditLogValidatorList = {
  __typename?: 'portal_AuditLogValidatorList';
  data: Array<Portal_AuditLogValidator>;
  listMetadata: ListMetadata;
};

export type Portal_Connection = {
  __typename?: 'portal_Connection';
  adpConnectionSslCertificates: Array<Portal_AdpConnectionSslCertificate>;
  attributeMap?: Maybe<AttributeMap>;
  connectionDomains: Array<ConnectionDomain>;
  createdAt: Scalars['DateTime'];
  externalKey: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  oauthSessions: OAuthSessionsList;
  oidcSessions: OidcSessionsList;
  oidc_client_id?: Maybe<Scalars['String']>;
  oidc_client_secret?: Maybe<Scalars['String']>;
  oidc_discovery_endpoint?: Maybe<Scalars['String']>;
  oidc_redirect_uri?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizationDomains: Array<OrganizationDomain>;
  portalProgression?: Maybe<Portal_PortalProgression>;
  saml: Portal_SamlConnection;
  samlSessions: SamlSessionsList;
  samlX509Certificates: Array<Portal_SamlX509Certificate>;
  saml_acs_url: Scalars['String'];
  saml_entity_id?: Maybe<Scalars['String']>;
  saml_idp_metadata_url?: Maybe<Scalars['String']>;
  saml_idp_url?: Maybe<Scalars['String']>;
  saml_sp_metadata_url: Scalars['String'];
  state: ConnectionState;
  type: ConnectionType;
};

export type Portal_ConnectionOauthSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<OAuthSessionState>;
};

export type Portal_ConnectionOidcSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<OidcSessionState>;
};

export type Portal_ConnectionSamlSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<SamlSessionState>;
};

export type Portal_ConnectionList = {
  __typename?: 'portal_ConnectionList';
  data: Array<Portal_ConnectionOrUntypedConnection>;
  listMetadata: ListMetadata;
};

export type Portal_CreateAuditLogExportInput = {
  actions?: InputMaybe<Array<Scalars['String']>>;
  actors?: InputMaybe<Array<Scalars['String']>>;
  rangeEnd: Scalars['DateTime'];
  rangeStart: Scalars['DateTime'];
  targets?: InputMaybe<Array<Scalars['String']>>;
};

export type Portal_CreateAuditLogExportResult =
  | Portal_AuditLogExport
  | Portal_InvalidExportDateRange
  | Portal_NoAuditLogEventsFound;

export type Portal_Directory = {
  __typename?: 'portal_Directory';
  attributeMap?: Maybe<DirectoryAttributeMap>;
  bamboo_hr_api_key?: Maybe<Scalars['String']>;
  bamboo_hr_subdomain?: Maybe<Scalars['String']>;
  bearerToken?: Maybe<Scalars['String']>;
  bob_api_token?: Maybe<Scalars['String']>;
  bob_service_user_id?: Maybe<Scalars['String']>;
  breatheHrApiKey?: Maybe<Scalars['String']>;
  cezanneHrClientId?: Maybe<Scalars['String']>;
  cezanneHrClientSecret?: Maybe<Scalars['String']>;
  countsSummary: Portal_DirectoryCountsSummary;
  createdAt: Scalars['DateTime'];
  customAttributeMappings: Array<Portal_DirectoryCustomAttributeMapping>;
  directorySyncRuns: Portal_DirectorySyncRunsList;
  directoryUser: DirectoryUser;
  directoryUsers: DirectoryUsersList;
  endpoint?: Maybe<Scalars['String']>;
  environment: Environment;
  fourthHrOrganizationId?: Maybe<Scalars['String']>;
  fourthHrPassword?: Maybe<Scalars['String']>;
  fourthHrUsername?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  peopleHrApiKey?: Maybe<Scalars['String']>;
  portalProgression?: Maybe<Portal_PortalProgression>;
  primaryDomain?: Maybe<OrganizationDomain>;
  rippling_api_key?: Maybe<Scalars['String']>;
  setup_url?: Maybe<Scalars['String']>;
  state: DirectoryState;
  type: DirectoryType;
  updatedAt: Scalars['DateTime'];
  workday_raas_endpoint?: Maybe<Scalars['String']>;
  workday_raas_group_endpoint?: Maybe<Scalars['String']>;
  workday_raas_password?: Maybe<Scalars['String']>;
  workday_raas_username?: Maybe<Scalars['String']>;
};

export type Portal_DirectoryDirectorySyncRunsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<DirectorySyncRunState>;
};

export type Portal_DirectoryDirectoryUserArgs = {
  id: Scalars['String'];
};

export type Portal_DirectoryDirectoryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type Portal_DirectoryList = {
  __typename?: 'portal_DirectoryList';
  data: Array<Portal_DirectoryOrUntypedDirectory>;
  listMetadata: ListMetadata;
};

export type Portal_DirectorySyncRunsList = {
  __typename?: 'portal_DirectorySyncRunsList';
  data: Array<Portal_DirectorySyncRun>;
  listMetadata: ListMetadata;
};

export type Portal_InvalidExportDateRange = {
  __typename?: 'portal_InvalidExportDateRange';
  message: Scalars['String'];
};

export type Portal_NoAuditLogEventsFound = {
  __typename?: 'portal_NoAuditLogEventsFound';
  message: Scalars['String'];
};

export type Portal_PortalProgression = {
  __typename?: 'portal_PortalProgression';
  currentStepIdentifier: Scalars['String'];
  formData?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  lastCompletedStepNumber: Scalars['Float'];
  portalData?: Maybe<Scalars['JSON']>;
};

export type Portal_PortalSession = {
  __typename?: 'portal_PortalSession';
  appName: Scalars['String'];
  connection?: Maybe<Portal_ConnectionOrUntypedConnection>;
  directory?: Maybe<Portal_DirectoryOrUntypedDirectory>;
  /** @deprecated Please use `directory` instead. */
  directoryV2?: Maybe<Portal_DirectoryOrUntypedDirectory>;
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  portalSettings: PortalSettings;
  returnUrl: Scalars['String'];
  setupLink?: Maybe<Portal_PortalSetupLink>;
  state: PortalSessionState;
  successUrl?: Maybe<Scalars['String']>;
};

export type Portal_PortalSetupLink = {
  __typename?: 'portal_PortalSetupLink';
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  intent: PortalSetupLinkIntent;
  state: PortalSetupLinkState;
  token: Scalars['String'];
};

export type Portal_SamlX509Certificate = {
  __typename?: 'portal_SamlX509Certificate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  notAfter?: Maybe<Scalars['DateTime']>;
  notBefore?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

/** A Connection that has not yet been assigned a type. */
export type Portal_UntypedConnection = {
  __typename?: 'portal_UntypedConnection';
  id: Scalars['ID'];
};

/** A Directory that has not yet been assigned a type. */
export type Portal_UntypedDirectory = {
  __typename?: 'portal_UntypedDirectory';
  id: Scalars['ID'];
};

export type AuditLogEventFragment = {
  __typename?: 'portal_AuditLogEvent';
  id: string;
  createdAt: any;
  occurredAt: any;
  action: string;
  actor: {
    __typename?: 'AuditLogEventActor';
    id: string;
    name?: string | null;
    type: string;
  };
  targets: Array<{ __typename?: 'AuditLogEventTarget'; type: string }>;
};

export type AuditLogTargetFragment = {
  __typename?: 'portal_AuditLogTarget';
  id: string;
  target: string;
};

export type AuditLogValidatorFragment = {
  __typename?: 'portal_AuditLogValidator';
  id: string;
  action: string;
  versions: Array<{
    __typename?: 'AuditLogValidatorVersion';
    version: number;
    targets: Array<{
      __typename?: 'AuditLogTarget';
      id: string;
      target: string;
    }>;
  }>;
};

export type ConnectionDomainFragment = {
  __typename?: 'ConnectionDomain';
  id: string;
  domain: string;
};

export type ConnectionSessionContainerFragment = {
  __typename?: 'portal_Connection';
  samlSessions: {
    __typename?: 'SAMLSessionsList';
    data: Array<{
      __typename: 'SAMLSession';
      createdAt: any;
      id: string;
      samlRequest?: string | null;
      samlResponse?: string | null;
      samlSessionState: SamlSessionState;
      profile?: {
        __typename?: 'Profile';
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      errors: Array<
        | {
            __typename: 'DecryptionFailedError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'IdpInitiatedExpiredResponseError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'IdpInitiatedWithInResponseToError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'InvalidAttributesError';
            code: ConnectionSessionErrorCode;
            reason: string;
            received_attributes: Array<{
              __typename?: 'ReceivedAttribute';
              attribute: string;
              value?: string | null;
            }>;
            expected_attributes: {
              __typename?: 'ExpectedAttributes';
              email_attribute: string;
              first_name_attribute: string;
              idp_id_attribute: string;
              last_name_attribute: string;
            };
          }
        | {
            __typename: 'InvalidX509CertError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'MalformedSamlResponseError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'ProfileNotAllowedOutsideOrganizationError';
            code: ConnectionSessionErrorCode;
            reason: string;
            profileDomain: string;
            organizationDomains: Array<string>;
          }
      >;
    }>;
  };
  oauthSessions: {
    __typename?: 'OAuthSessionsList';
    data: Array<{
      __typename: 'OAuthSession';
      createdAt: any;
      id: string;
      oauthSessionState: OAuthSessionState;
      profile?: {
        __typename?: 'Profile';
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        rawAttributes?: any | null;
      } | null;
      errors: Array<
        | {
            __typename: 'DecryptionFailedError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'IdpInitiatedExpiredResponseError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'IdpInitiatedWithInResponseToError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'InvalidAttributesError';
            code: ConnectionSessionErrorCode;
            reason: string;
            received_attributes: Array<{
              __typename?: 'ReceivedAttribute';
              attribute: string;
              value?: string | null;
            }>;
            expected_attributes: {
              __typename?: 'ExpectedAttributes';
              email_attribute: string;
              first_name_attribute: string;
              idp_id_attribute: string;
              last_name_attribute: string;
            };
          }
        | {
            __typename: 'InvalidX509CertError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'MalformedSamlResponseError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'ProfileNotAllowedOutsideOrganizationError';
            code: ConnectionSessionErrorCode;
            reason: string;
            profileDomain: string;
            organizationDomains: Array<string>;
          }
      >;
    }>;
  };
  oidcSessions: {
    __typename?: 'OidcSessionsList';
    data: Array<{
      __typename: 'OidcSession';
      createdAt: any;
      id: string;
      oidcSessionState: OidcSessionState;
      profile?: {
        __typename?: 'Profile';
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        rawAttributes?: any | null;
      } | null;
      errors: Array<
        | {
            __typename: 'DecryptionFailedError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'IdpInitiatedExpiredResponseError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'IdpInitiatedWithInResponseToError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'InvalidAttributesError';
            code: ConnectionSessionErrorCode;
            reason: string;
            received_attributes: Array<{
              __typename?: 'ReceivedAttribute';
              attribute: string;
              value?: string | null;
            }>;
            expected_attributes: {
              __typename?: 'ExpectedAttributes';
              email_attribute: string;
              first_name_attribute: string;
              idp_id_attribute: string;
              last_name_attribute: string;
            };
          }
        | {
            __typename: 'InvalidX509CertError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'MalformedSamlResponseError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'ProfileNotAllowedOutsideOrganizationError';
            code: ConnectionSessionErrorCode;
            reason: string;
            profileDomain: string;
            organizationDomains: Array<string>;
          }
      >;
    }>;
  };
};

type ConnectionSessionError_DecryptionFailedError_Fragment = {
  __typename: 'DecryptionFailedError';
  code: ConnectionSessionErrorCode;
  reason: string;
};

type ConnectionSessionError_IdpInitiatedExpiredResponseError_Fragment = {
  __typename: 'IdpInitiatedExpiredResponseError';
  code: ConnectionSessionErrorCode;
  reason: string;
};

type ConnectionSessionError_IdpInitiatedWithInResponseToError_Fragment = {
  __typename: 'IdpInitiatedWithInResponseToError';
  code: ConnectionSessionErrorCode;
  reason: string;
};

type ConnectionSessionError_InvalidAttributesError_Fragment = {
  __typename: 'InvalidAttributesError';
  code: ConnectionSessionErrorCode;
  reason: string;
  received_attributes: Array<{
    __typename?: 'ReceivedAttribute';
    attribute: string;
    value?: string | null;
  }>;
  expected_attributes: {
    __typename?: 'ExpectedAttributes';
    email_attribute: string;
    first_name_attribute: string;
    idp_id_attribute: string;
    last_name_attribute: string;
  };
};

type ConnectionSessionError_InvalidX509CertError_Fragment = {
  __typename: 'InvalidX509CertError';
  code: ConnectionSessionErrorCode;
  reason: string;
};

type ConnectionSessionError_MalformedSamlResponseError_Fragment = {
  __typename: 'MalformedSamlResponseError';
  code: ConnectionSessionErrorCode;
  reason: string;
};

type ConnectionSessionError_ProfileNotAllowedOutsideOrganizationError_Fragment =
  {
    __typename: 'ProfileNotAllowedOutsideOrganizationError';
    code: ConnectionSessionErrorCode;
    reason: string;
    profileDomain: string;
    organizationDomains: Array<string>;
  };

export type ConnectionSessionErrorFragment =
  | ConnectionSessionError_DecryptionFailedError_Fragment
  | ConnectionSessionError_IdpInitiatedExpiredResponseError_Fragment
  | ConnectionSessionError_IdpInitiatedWithInResponseToError_Fragment
  | ConnectionSessionError_InvalidAttributesError_Fragment
  | ConnectionSessionError_InvalidX509CertError_Fragment
  | ConnectionSessionError_MalformedSamlResponseError_Fragment
  | ConnectionSessionError_ProfileNotAllowedOutsideOrganizationError_Fragment;

type ConnectionSession_OAuthSession_Fragment = {
  __typename: 'OAuthSession';
  createdAt: any;
  id: string;
  oauthSessionState: OAuthSessionState;
  profile?: {
    __typename?: 'Profile';
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    rawAttributes?: any | null;
  } | null;
  errors: Array<
    | {
        __typename: 'DecryptionFailedError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'IdpInitiatedExpiredResponseError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'IdpInitiatedWithInResponseToError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'InvalidAttributesError';
        code: ConnectionSessionErrorCode;
        reason: string;
        received_attributes: Array<{
          __typename?: 'ReceivedAttribute';
          attribute: string;
          value?: string | null;
        }>;
        expected_attributes: {
          __typename?: 'ExpectedAttributes';
          email_attribute: string;
          first_name_attribute: string;
          idp_id_attribute: string;
          last_name_attribute: string;
        };
      }
    | {
        __typename: 'InvalidX509CertError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'MalformedSamlResponseError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'ProfileNotAllowedOutsideOrganizationError';
        code: ConnectionSessionErrorCode;
        reason: string;
        profileDomain: string;
        organizationDomains: Array<string>;
      }
  >;
};

type ConnectionSession_OidcSession_Fragment = {
  __typename: 'OidcSession';
  createdAt: any;
  id: string;
  oidcSessionState: OidcSessionState;
  profile?: {
    __typename?: 'Profile';
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    rawAttributes?: any | null;
  } | null;
  errors: Array<
    | {
        __typename: 'DecryptionFailedError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'IdpInitiatedExpiredResponseError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'IdpInitiatedWithInResponseToError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'InvalidAttributesError';
        code: ConnectionSessionErrorCode;
        reason: string;
        received_attributes: Array<{
          __typename?: 'ReceivedAttribute';
          attribute: string;
          value?: string | null;
        }>;
        expected_attributes: {
          __typename?: 'ExpectedAttributes';
          email_attribute: string;
          first_name_attribute: string;
          idp_id_attribute: string;
          last_name_attribute: string;
        };
      }
    | {
        __typename: 'InvalidX509CertError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'MalformedSamlResponseError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'ProfileNotAllowedOutsideOrganizationError';
        code: ConnectionSessionErrorCode;
        reason: string;
        profileDomain: string;
        organizationDomains: Array<string>;
      }
  >;
};

type ConnectionSession_SamlSession_Fragment = {
  __typename: 'SAMLSession';
  createdAt: any;
  id: string;
  samlRequest?: string | null;
  samlResponse?: string | null;
  samlSessionState: SamlSessionState;
  profile?: {
    __typename?: 'Profile';
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  errors: Array<
    | {
        __typename: 'DecryptionFailedError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'IdpInitiatedExpiredResponseError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'IdpInitiatedWithInResponseToError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'InvalidAttributesError';
        code: ConnectionSessionErrorCode;
        reason: string;
        received_attributes: Array<{
          __typename?: 'ReceivedAttribute';
          attribute: string;
          value?: string | null;
        }>;
        expected_attributes: {
          __typename?: 'ExpectedAttributes';
          email_attribute: string;
          first_name_attribute: string;
          idp_id_attribute: string;
          last_name_attribute: string;
        };
      }
    | {
        __typename: 'InvalidX509CertError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'MalformedSamlResponseError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'ProfileNotAllowedOutsideOrganizationError';
        code: ConnectionSessionErrorCode;
        reason: string;
        profileDomain: string;
        organizationDomains: Array<string>;
      }
  >;
};

export type ConnectionSessionFragment =
  | ConnectionSession_OAuthSession_Fragment
  | ConnectionSession_OidcSession_Fragment
  | ConnectionSession_SamlSession_Fragment;

export type ConnectionFragment = {
  __typename: 'portal_Connection';
  id: string;
  externalKey: string;
  type: ConnectionType;
  createdAt: any;
  name: string;
  oidc_client_id?: string | null;
  oidc_client_secret?: string | null;
  oidc_discovery_endpoint?: string | null;
  oidc_redirect_uri?: string | null;
  state: ConnectionState;
  saml_acs_url: string;
  saml_entity_id?: string | null;
  saml_idp_url?: string | null;
  saml_idp_metadata_url?: string | null;
  saml_sp_metadata_url: string;
  adpConnectionSslCertificates: Array<{
    __typename?: 'Portal_AdpConnectionSslCertificate';
    id: string;
    value: string;
  }>;
  attributeMap?: { __typename?: 'AttributeMap'; id: string } | null;
  connectionDomains: Array<{
    __typename?: 'ConnectionDomain';
    id: string;
    domain: string;
  }>;
  portalProgression?: {
    __typename?: 'portal_PortalProgression';
    id: string;
    currentStepIdentifier: string;
    formData?: any | null;
    lastCompletedStepNumber: number;
    portalData?: any | null;
  } | null;
  samlX509Certificates: Array<{
    __typename?: 'portal_SamlX509Certificate';
    id: string;
    value: string;
    notBefore?: any | null;
    notAfter?: any | null;
  }>;
  saml: { __typename?: 'Portal_SamlConnection'; idpEntityId?: string | null };
};

export type DirectoryCustomAttributeMappingFragment = {
  __typename?: 'Portal_DirectoryCustomAttributeMapping';
  id: string;
  attribute: string;
  customAttributeId: string;
};

export type DirectoryCustomAttributeFragment = {
  __typename?: 'Portal_DirectoryCustomAttribute';
  id: string;
  isRequired: boolean;
  name: string;
};

export type DirectoryGroupFragment = {
  __typename?: 'DirectoryGroup';
  id: string;
  name: string;
};

export type DirectorySyncRunFragment = {
  __typename?: 'Portal_DirectorySyncRun';
  id: string;
  state: DirectorySyncRunState;
  updatedAt: any;
  errors: Array<
    | {
        __typename: 'MalformedDirectoryGroupError';
        code: DirectorySyncRunErrorCode;
        reason: string;
      }
    | {
        __typename: 'MalformedDirectoryUserError';
        code: DirectorySyncRunErrorCode;
        reason: string;
      }
  >;
};

export type DirectoryUserFragment = {
  __typename?: 'DirectoryUser';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  username?: string | null;
  state: DirectoryUserState;
  directoryGroups: Array<{
    __typename?: 'DirectoryGroup';
    id: string;
    name: string;
  }>;
};

export type DirectoryFragment = {
  __typename: 'portal_Directory';
  id: string;
  name: string;
  type: DirectoryType;
  state: DirectoryState;
  endpoint?: string | null;
  setup_url?: string | null;
  createdAt: any;
  bearerToken?: string | null;
  bamboo_hr_api_key?: string | null;
  breatheHrApiKey?: string | null;
  bamboo_hr_subdomain?: string | null;
  bob_service_user_id?: string | null;
  bob_api_token?: string | null;
  rippling_api_key?: string | null;
  workday_raas_endpoint?: string | null;
  workday_raas_group_endpoint?: string | null;
  workday_raas_password?: string | null;
  workday_raas_username?: string | null;
  fourthHrOrganizationId?: string | null;
  fourthHrPassword?: string | null;
  fourthHrUsername?: string | null;
  peopleHrApiKey?: string | null;
  cezanneHrClientId?: string | null;
  cezanneHrClientSecret?: string | null;
  attributeMap?: {
    __typename?: 'DirectoryAttributeMap';
    id: string;
    attributes: {
      __typename?: 'DirectoryAttributes';
      externalId?: string | null;
      username?: string | null;
      emails?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      jobTitle?: string | null;
      groupName?: string | null;
    };
  } | null;
  customAttributeMappings: Array<{
    __typename?: 'Portal_DirectoryCustomAttributeMapping';
    id: string;
    attribute: string;
    customAttributeId: string;
  }>;
  countsSummary: {
    __typename?: 'Portal_DirectoryCountsSummary';
    userCount: number;
    groupCount: number;
  };
  portalProgression?: {
    __typename?: 'portal_PortalProgression';
    id: string;
    currentStepIdentifier: string;
    formData?: any | null;
    lastCompletedStepNumber: number;
    portalData?: any | null;
  } | null;
  primaryDomain?: {
    __typename?: 'OrganizationDomain';
    id: string;
    domain: string;
  } | null;
};

export type OrganizationFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  domains: Array<{
    __typename?: 'OrganizationDomain';
    id: string;
    domain: string;
  }>;
};

export type PortalProgressionFragment = {
  __typename?: 'portal_PortalProgression';
  id: string;
  currentStepIdentifier: string;
  formData?: any | null;
  lastCompletedStepNumber: number;
  portalData?: any | null;
};

export type PortalSessionFragment = {
  __typename?: 'portal_PortalSession';
  id: string;
  returnUrl: string;
  successUrl?: string | null;
  appName: string;
  connection?:
    | {
        __typename: 'portal_Connection';
        id: string;
        externalKey: string;
        type: ConnectionType;
        createdAt: any;
        name: string;
        oidc_client_id?: string | null;
        oidc_client_secret?: string | null;
        oidc_discovery_endpoint?: string | null;
        oidc_redirect_uri?: string | null;
        state: ConnectionState;
        saml_acs_url: string;
        saml_entity_id?: string | null;
        saml_idp_url?: string | null;
        saml_idp_metadata_url?: string | null;
        saml_sp_metadata_url: string;
        adpConnectionSslCertificates: Array<{
          __typename?: 'Portal_AdpConnectionSslCertificate';
          id: string;
          value: string;
        }>;
        attributeMap?: { __typename?: 'AttributeMap'; id: string } | null;
        connectionDomains: Array<{
          __typename?: 'ConnectionDomain';
          id: string;
          domain: string;
        }>;
        portalProgression?: {
          __typename?: 'portal_PortalProgression';
          id: string;
          currentStepIdentifier: string;
          formData?: any | null;
          lastCompletedStepNumber: number;
          portalData?: any | null;
        } | null;
        samlX509Certificates: Array<{
          __typename?: 'portal_SamlX509Certificate';
          id: string;
          value: string;
          notBefore?: any | null;
          notAfter?: any | null;
        }>;
        saml: {
          __typename?: 'Portal_SamlConnection';
          idpEntityId?: string | null;
        };
      }
    | { __typename: 'portal_UntypedConnection'; id: string }
    | null;
  directory?:
    | {
        __typename: 'portal_Directory';
        id: string;
        name: string;
        type: DirectoryType;
        state: DirectoryState;
        endpoint?: string | null;
        setup_url?: string | null;
        createdAt: any;
        bearerToken?: string | null;
        bamboo_hr_api_key?: string | null;
        breatheHrApiKey?: string | null;
        bamboo_hr_subdomain?: string | null;
        bob_service_user_id?: string | null;
        bob_api_token?: string | null;
        rippling_api_key?: string | null;
        workday_raas_endpoint?: string | null;
        workday_raas_group_endpoint?: string | null;
        workday_raas_password?: string | null;
        workday_raas_username?: string | null;
        fourthHrOrganizationId?: string | null;
        fourthHrPassword?: string | null;
        fourthHrUsername?: string | null;
        peopleHrApiKey?: string | null;
        cezanneHrClientId?: string | null;
        cezanneHrClientSecret?: string | null;
        attributeMap?: {
          __typename?: 'DirectoryAttributeMap';
          id: string;
          attributes: {
            __typename?: 'DirectoryAttributes';
            externalId?: string | null;
            username?: string | null;
            emails?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            jobTitle?: string | null;
            groupName?: string | null;
          };
        } | null;
        customAttributeMappings: Array<{
          __typename?: 'Portal_DirectoryCustomAttributeMapping';
          id: string;
          attribute: string;
          customAttributeId: string;
        }>;
        countsSummary: {
          __typename?: 'Portal_DirectoryCountsSummary';
          userCount: number;
          groupCount: number;
        };
        portalProgression?: {
          __typename?: 'portal_PortalProgression';
          id: string;
          currentStepIdentifier: string;
          formData?: any | null;
          lastCompletedStepNumber: number;
          portalData?: any | null;
        } | null;
        primaryDomain?: {
          __typename?: 'OrganizationDomain';
          id: string;
          domain: string;
        } | null;
      }
    | { __typename: 'portal_UntypedDirectory'; id: string }
    | null;
  organization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    domains: Array<{
      __typename?: 'OrganizationDomain';
      id: string;
      domain: string;
    }>;
  } | null;
  setupLink?: {
    __typename?: 'portal_PortalSetupLink';
    state: PortalSetupLinkState;
    expiresAt: any;
  } | null;
  portalSettings: {
    __typename?: 'PortalSettings';
    logoUrl?: string | null;
    primaryColor?: string | null;
    faviconUrl?: string | null;
  };
};

export type SamlX509CertificateFragment = {
  __typename?: 'portal_SamlX509Certificate';
  id: string;
  value: string;
  notBefore?: any | null;
  notAfter?: any | null;
};

export type UntypedConnectionFragment = {
  __typename: 'portal_UntypedConnection';
  id: string;
};

export type UntypedDirectoryFragment = {
  __typename: 'portal_UntypedDirectory';
  id: string;
};

export type AddAdpConnectionSslCertificateMutationVariables = Exact<{
  input: Portal_AddAdpConnectionSslCertificateInput;
}>;

export type AddAdpConnectionSslCertificateMutation = {
  __typename?: 'Mutation';
  portal_addAdpConnectionSslCertificate:
    | { __typename: 'ConnectionNotFound'; connectionId: string }
    | {
        __typename: 'Portal_AdpConnectionSslCertificateAdded';
        adpConnectionSslCertificate: {
          __typename?: 'Portal_AdpConnectionSslCertificate';
          id: string;
          value: string;
        };
      }
    | { __typename: 'Portal_InvalidAdpConnectionSslCertificate' };
};

export type CreateAuditLogExportMutationVariables = Exact<{
  input: Portal_CreateAuditLogExportInput;
}>;

export type CreateAuditLogExportMutation = {
  __typename?: 'Mutation';
  portal_createAuditLogExport:
    | {
        __typename: 'portal_AuditLogExport';
        id: string;
        state: AuditLogExportState;
        url?: string | null;
      }
    | { __typename: 'portal_InvalidExportDateRange'; message: string }
    | { __typename: 'portal_NoAuditLogEventsFound'; message: string };
};

export type CreateConnectionFromUntypedMutationVariables = Exact<{
  input: Portal_CreateConnectionFromUntypedInput;
}>;

export type CreateConnectionFromUntypedMutation = {
  __typename?: 'Mutation';
  portal_createConnectionFromUntyped:
    | { __typename: 'ConnectionIsAlreadyTyped'; connectionId: string }
    | { __typename: 'ConnectionNotFound'; connectionId: string }
    | {
        __typename: 'Portal_ConnectionCreated';
        connection: {
          __typename: 'portal_Connection';
          id: string;
          externalKey: string;
          type: ConnectionType;
          createdAt: any;
          name: string;
          oidc_client_id?: string | null;
          oidc_client_secret?: string | null;
          oidc_discovery_endpoint?: string | null;
          oidc_redirect_uri?: string | null;
          state: ConnectionState;
          saml_acs_url: string;
          saml_entity_id?: string | null;
          saml_idp_url?: string | null;
          saml_idp_metadata_url?: string | null;
          saml_sp_metadata_url: string;
          adpConnectionSslCertificates: Array<{
            __typename?: 'Portal_AdpConnectionSslCertificate';
            id: string;
            value: string;
          }>;
          attributeMap?: { __typename?: 'AttributeMap'; id: string } | null;
          connectionDomains: Array<{
            __typename?: 'ConnectionDomain';
            id: string;
            domain: string;
          }>;
          portalProgression?: {
            __typename?: 'portal_PortalProgression';
            id: string;
            currentStepIdentifier: string;
            formData?: any | null;
            lastCompletedStepNumber: number;
            portalData?: any | null;
          } | null;
          samlX509Certificates: Array<{
            __typename?: 'portal_SamlX509Certificate';
            id: string;
            value: string;
            notBefore?: any | null;
            notAfter?: any | null;
          }>;
          saml: {
            __typename?: 'Portal_SamlConnection';
            idpEntityId?: string | null;
          };
        };
      };
};

export type CreateConnectionMutationVariables = Exact<{
  name: Scalars['String'];
  type: ConnectionType;
  domains?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type CreateConnectionMutation = {
  __typename?: 'Mutation';
  portal_createConnection: {
    __typename: 'portal_Connection';
    id: string;
    externalKey: string;
    type: ConnectionType;
    createdAt: any;
    name: string;
    oidc_client_id?: string | null;
    oidc_client_secret?: string | null;
    oidc_discovery_endpoint?: string | null;
    oidc_redirect_uri?: string | null;
    state: ConnectionState;
    saml_acs_url: string;
    saml_entity_id?: string | null;
    saml_idp_url?: string | null;
    saml_idp_metadata_url?: string | null;
    saml_sp_metadata_url: string;
    adpConnectionSslCertificates: Array<{
      __typename?: 'Portal_AdpConnectionSslCertificate';
      id: string;
      value: string;
    }>;
    attributeMap?: { __typename?: 'AttributeMap'; id: string } | null;
    connectionDomains: Array<{
      __typename?: 'ConnectionDomain';
      id: string;
      domain: string;
    }>;
    portalProgression?: {
      __typename?: 'portal_PortalProgression';
      id: string;
      currentStepIdentifier: string;
      formData?: any | null;
      lastCompletedStepNumber: number;
      portalData?: any | null;
    } | null;
    samlX509Certificates: Array<{
      __typename?: 'portal_SamlX509Certificate';
      id: string;
      value: string;
      notBefore?: any | null;
      notAfter?: any | null;
    }>;
    saml: { __typename?: 'Portal_SamlConnection'; idpEntityId?: string | null };
  };
};

export type CreateDirectoryFromUntypedMutationVariables = Exact<{
  input: Portal_CreateDirectoryFromUntypedInput;
}>;

export type CreateDirectoryFromUntypedMutation = {
  __typename?: 'Mutation';
  portal_createDirectoryFromUntyped:
    | { __typename: 'DirectoryIsAlreadyTyped'; directoryId: string }
    | { __typename: 'DirectoryNotFound'; directoryId: string }
    | {
        __typename: 'Portal_DirectoryCreated';
        directory: {
          __typename: 'portal_Directory';
          id: string;
          name: string;
          type: DirectoryType;
          state: DirectoryState;
          endpoint?: string | null;
          setup_url?: string | null;
          createdAt: any;
          bearerToken?: string | null;
          bamboo_hr_api_key?: string | null;
          breatheHrApiKey?: string | null;
          bamboo_hr_subdomain?: string | null;
          bob_service_user_id?: string | null;
          bob_api_token?: string | null;
          rippling_api_key?: string | null;
          workday_raas_endpoint?: string | null;
          workday_raas_group_endpoint?: string | null;
          workday_raas_password?: string | null;
          workday_raas_username?: string | null;
          fourthHrOrganizationId?: string | null;
          fourthHrPassword?: string | null;
          fourthHrUsername?: string | null;
          peopleHrApiKey?: string | null;
          cezanneHrClientId?: string | null;
          cezanneHrClientSecret?: string | null;
          attributeMap?: {
            __typename?: 'DirectoryAttributeMap';
            id: string;
            attributes: {
              __typename?: 'DirectoryAttributes';
              externalId?: string | null;
              username?: string | null;
              emails?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              jobTitle?: string | null;
              groupName?: string | null;
            };
          } | null;
          customAttributeMappings: Array<{
            __typename?: 'Portal_DirectoryCustomAttributeMapping';
            id: string;
            attribute: string;
            customAttributeId: string;
          }>;
          countsSummary: {
            __typename?: 'Portal_DirectoryCountsSummary';
            userCount: number;
            groupCount: number;
          };
          portalProgression?: {
            __typename?: 'portal_PortalProgression';
            id: string;
            currentStepIdentifier: string;
            formData?: any | null;
            lastCompletedStepNumber: number;
            portalData?: any | null;
          } | null;
          primaryDomain?: {
            __typename?: 'OrganizationDomain';
            id: string;
            domain: string;
          } | null;
        };
      };
};

export type CreateDirectoryMutationVariables = Exact<{
  name: Scalars['String'];
  type: DirectoryType;
  domain?: InputMaybe<Scalars['String']>;
}>;

export type CreateDirectoryMutation = {
  __typename?: 'Mutation';
  portal_createDirectory: {
    __typename: 'portal_Directory';
    id: string;
    name: string;
    type: DirectoryType;
    state: DirectoryState;
    endpoint?: string | null;
    setup_url?: string | null;
    createdAt: any;
    bearerToken?: string | null;
    bamboo_hr_api_key?: string | null;
    breatheHrApiKey?: string | null;
    bamboo_hr_subdomain?: string | null;
    bob_service_user_id?: string | null;
    bob_api_token?: string | null;
    rippling_api_key?: string | null;
    workday_raas_endpoint?: string | null;
    workday_raas_group_endpoint?: string | null;
    workday_raas_password?: string | null;
    workday_raas_username?: string | null;
    fourthHrOrganizationId?: string | null;
    fourthHrPassword?: string | null;
    fourthHrUsername?: string | null;
    peopleHrApiKey?: string | null;
    cezanneHrClientId?: string | null;
    cezanneHrClientSecret?: string | null;
    attributeMap?: {
      __typename?: 'DirectoryAttributeMap';
      id: string;
      attributes: {
        __typename?: 'DirectoryAttributes';
        externalId?: string | null;
        username?: string | null;
        emails?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        jobTitle?: string | null;
        groupName?: string | null;
      };
    } | null;
    customAttributeMappings: Array<{
      __typename?: 'Portal_DirectoryCustomAttributeMapping';
      id: string;
      attribute: string;
      customAttributeId: string;
    }>;
    countsSummary: {
      __typename?: 'Portal_DirectoryCountsSummary';
      userCount: number;
      groupCount: number;
    };
    portalProgression?: {
      __typename?: 'portal_PortalProgression';
      id: string;
      currentStepIdentifier: string;
      formData?: any | null;
      lastCompletedStepNumber: number;
      portalData?: any | null;
    } | null;
    primaryDomain?: {
      __typename?: 'OrganizationDomain';
      id: string;
      domain: string;
    } | null;
  };
};

export type DeleteConnectionMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteConnectionMutation = {
  __typename?: 'Mutation';
  portal_deleteConnection: string;
};

export type DeleteDirectoryMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteDirectoryMutation = {
  __typename?: 'Mutation';
  portal_deleteDirectory: {
    __typename?: 'Portal_DeleteDirectoryPayload';
    directory?: { __typename?: 'portal_Directory'; id: string } | null;
  };
};

export type DeleteSamlX509CertificateMutationVariables = Exact<{
  input: Portal_DeleteSamlX509CertificateInput;
}>;

export type DeleteSamlX509CertificateMutation = {
  __typename?: 'Mutation';
  portal_deleteSamlX509Certificate:
    | {
        __typename: 'Portal_SamlX509CertificateDeleted';
        samlX509Certificate: {
          __typename?: 'portal_SamlX509Certificate';
          id: string;
        };
      }
    | {
        __typename: 'SamlX509CertificateNotFound';
        samlX509CertificateId: string;
      };
};

export type LoadedMutationVariables = Exact<{
  started_at: Scalars['Float'];
  legacy?: InputMaybe<Scalars['Boolean']>;
}>;

export type LoadedMutation = {
  __typename?: 'Mutation';
  portal_loaded: { __typename?: 'SuccessResponse'; success: boolean };
};

export type ReadyMutationVariables = Exact<{
  started_at: Scalars['Float'];
  legacy?: InputMaybe<Scalars['Boolean']>;
}>;

export type ReadyMutation = {
  __typename?: 'Mutation';
  portal_ready: { __typename?: 'SuccessResponse'; success: boolean };
};

export type RequestIdentityProviderMutationVariables = Exact<{
  identityProvider: Scalars['String'];
  pageRequestedFrom?: InputMaybe<Scalars['String']>;
}>;

export type RequestIdentityProviderMutation = {
  __typename?: 'Mutation';
  portalIdentityProviderRequest: boolean;
};

export type SetConnectionPortalProgressionMutationVariables = Exact<{
  input: SetConnectionPortalProgressionInput;
}>;

export type SetConnectionPortalProgressionMutation = {
  __typename?: 'Mutation';
  portal_setConnectionPortalProgression:
    | { __typename: 'ConnectionNotFound' }
    | {
        __typename: 'PortalProgressionSet';
        portalProgression: {
          __typename?: 'portal_PortalProgression';
          id: string;
          currentStepIdentifier: string;
          formData?: any | null;
          lastCompletedStepNumber: number;
          portalData?: any | null;
        };
      };
};

export type SetDirectoryCustomAttributeMappingsMutationVariables = Exact<{
  input: Portal_SetDirectoryCustomAttributeMappingsInput;
}>;

export type SetDirectoryCustomAttributeMappingsMutation = {
  __typename?: 'Mutation';
  portal_setDirectoryCustomAttributeMappings:
    | { __typename: 'DirectoryCustomAttributeNotFound' }
    | { __typename: 'DirectoryNotFound' }
    | {
        __typename: 'Portal_DirectoryCustomAttributeMappingsSet';
        mappings: Array<{
          __typename?: 'Portal_DirectoryCustomAttributeMapping';
          id: string;
          attribute: string;
          customAttributeId: string;
        }>;
      };
};

export type SetDirectoryPortalProgressionMutationVariables = Exact<{
  input: SetDirectoryPortalProgressionInput;
}>;

export type SetDirectoryPortalProgressionMutation = {
  __typename?: 'Mutation';
  portal_setDirectoryPortalProgression:
    | { __typename: 'DirectoryNotFound' }
    | {
        __typename: 'PortalProgressionSet';
        portalProgression: {
          __typename?: 'portal_PortalProgression';
          id: string;
          currentStepIdentifier: string;
          formData?: any | null;
          lastCompletedStepNumber: number;
          portalData?: any | null;
        };
      };
};

export type TrackEventMutationVariables = Exact<{
  event_name: Scalars['String'];
  attributes?: InputMaybe<Scalars['JSON']>;
}>;

export type TrackEventMutation = {
  __typename?: 'Mutation';
  portal_trackEvent: { __typename?: 'SuccessResponse'; success: boolean };
};

export type UpdateAttributeMapMutationVariables = Exact<{
  id: Scalars['String'];
  idp_id_attribute?: InputMaybe<Scalars['String']>;
  email_attribute?: InputMaybe<Scalars['String']>;
  first_name_attribute?: InputMaybe<Scalars['String']>;
  last_name_attribute?: InputMaybe<Scalars['String']>;
}>;

export type UpdateAttributeMapMutation = {
  __typename?: 'Mutation';
  portal_updateAttributeMap?: {
    __typename?: 'AttributeMap';
    id: string;
  } | null;
};

export type UpdateConnectionFromMetadataUrlMutationVariables = Exact<{
  input: Portal_UpdateConnectionFromMetadataUrlInput;
}>;

export type UpdateConnectionFromMetadataUrlMutation = {
  __typename?: 'Mutation';
  portal_updateConnectionFromMetadataUrl:
    | { __typename: 'ConnectionNotFound'; connectionId: string }
    | {
        __typename: 'InvalidSamlX509Certificate';
        validationError: SamlX509CertificateValidationErrorCode;
      }
    | {
        __typename: 'Portal_ConnectionUpdatedFromMetadataUrl';
        connection: {
          __typename: 'portal_Connection';
          id: string;
          externalKey: string;
          type: ConnectionType;
          createdAt: any;
          name: string;
          oidc_client_id?: string | null;
          oidc_client_secret?: string | null;
          oidc_discovery_endpoint?: string | null;
          oidc_redirect_uri?: string | null;
          state: ConnectionState;
          saml_acs_url: string;
          saml_entity_id?: string | null;
          saml_idp_url?: string | null;
          saml_idp_metadata_url?: string | null;
          saml_sp_metadata_url: string;
          adpConnectionSslCertificates: Array<{
            __typename?: 'Portal_AdpConnectionSslCertificate';
            id: string;
            value: string;
          }>;
          attributeMap?: { __typename?: 'AttributeMap'; id: string } | null;
          connectionDomains: Array<{
            __typename?: 'ConnectionDomain';
            id: string;
            domain: string;
          }>;
          portalProgression?: {
            __typename?: 'portal_PortalProgression';
            id: string;
            currentStepIdentifier: string;
            formData?: any | null;
            lastCompletedStepNumber: number;
            portalData?: any | null;
          } | null;
          samlX509Certificates: Array<{
            __typename?: 'portal_SamlX509Certificate';
            id: string;
            value: string;
            notBefore?: any | null;
            notAfter?: any | null;
          }>;
          saml: {
            __typename?: 'Portal_SamlConnection';
            idpEntityId?: string | null;
          };
        };
      }
    | { __typename: 'Portal_MetadataFetchFailed'; reason: string };
};

export type UpdateConnectionFromMetadataXmlMutationVariables = Exact<{
  input: Portal_UpdateConnectionFromMetadataXmlInput;
}>;

export type UpdateConnectionFromMetadataXmlMutation = {
  __typename?: 'Mutation';
  portal_updateConnectionFromMetadataXml:
    | { __typename: 'ConnectionNotFound'; connectionId: string }
    | {
        __typename: 'InvalidSamlX509Certificate';
        validationError: SamlX509CertificateValidationErrorCode;
      }
    | {
        __typename: 'Portal_ConnectionUpdatedFromMetadataXml';
        connection: {
          __typename: 'portal_Connection';
          id: string;
          externalKey: string;
          type: ConnectionType;
          createdAt: any;
          name: string;
          oidc_client_id?: string | null;
          oidc_client_secret?: string | null;
          oidc_discovery_endpoint?: string | null;
          oidc_redirect_uri?: string | null;
          state: ConnectionState;
          saml_acs_url: string;
          saml_entity_id?: string | null;
          saml_idp_url?: string | null;
          saml_idp_metadata_url?: string | null;
          saml_sp_metadata_url: string;
          adpConnectionSslCertificates: Array<{
            __typename?: 'Portal_AdpConnectionSslCertificate';
            id: string;
            value: string;
          }>;
          attributeMap?: { __typename?: 'AttributeMap'; id: string } | null;
          connectionDomains: Array<{
            __typename?: 'ConnectionDomain';
            id: string;
            domain: string;
          }>;
          portalProgression?: {
            __typename?: 'portal_PortalProgression';
            id: string;
            currentStepIdentifier: string;
            formData?: any | null;
            lastCompletedStepNumber: number;
            portalData?: any | null;
          } | null;
          samlX509Certificates: Array<{
            __typename?: 'portal_SamlX509Certificate';
            id: string;
            value: string;
            notBefore?: any | null;
            notAfter?: any | null;
          }>;
          saml: {
            __typename?: 'Portal_SamlConnection';
            idpEntityId?: string | null;
          };
        };
      }
    | { __typename: 'Portal_MetadataParseFailed'; reason: string };
};

export type UpdateConnectionMutationVariables = Exact<{
  input: Portal_UpdateConnectionInput;
}>;

export type UpdateConnectionMutation = {
  __typename?: 'Mutation';
  portal_updateConnection:
    | { __typename: 'ConnectionNotFound'; connectionId: string }
    | {
        __typename: 'InvalidSamlX509Certificate';
        validationError: SamlX509CertificateValidationErrorCode;
      }
    | {
        __typename: 'Portal_ConnectionUpdated';
        connection: {
          __typename: 'portal_Connection';
          id: string;
          externalKey: string;
          type: ConnectionType;
          createdAt: any;
          name: string;
          oidc_client_id?: string | null;
          oidc_client_secret?: string | null;
          oidc_discovery_endpoint?: string | null;
          oidc_redirect_uri?: string | null;
          state: ConnectionState;
          saml_acs_url: string;
          saml_entity_id?: string | null;
          saml_idp_url?: string | null;
          saml_idp_metadata_url?: string | null;
          saml_sp_metadata_url: string;
          adpConnectionSslCertificates: Array<{
            __typename?: 'Portal_AdpConnectionSslCertificate';
            id: string;
            value: string;
          }>;
          attributeMap?: { __typename?: 'AttributeMap'; id: string } | null;
          connectionDomains: Array<{
            __typename?: 'ConnectionDomain';
            id: string;
            domain: string;
          }>;
          portalProgression?: {
            __typename?: 'portal_PortalProgression';
            id: string;
            currentStepIdentifier: string;
            formData?: any | null;
            lastCompletedStepNumber: number;
            portalData?: any | null;
          } | null;
          samlX509Certificates: Array<{
            __typename?: 'portal_SamlX509Certificate';
            id: string;
            value: string;
            notBefore?: any | null;
            notAfter?: any | null;
          }>;
          saml: {
            __typename?: 'Portal_SamlConnection';
            idpEntityId?: string | null;
          };
        };
      }
    | {
        __typename: 'VerifyConnectionFailed';
        connectionErrors: Array<{
          __typename?: 'ConnectionError';
          error: string;
          field: string;
          solution: string;
        }>;
      };
};

export type UpdateDirectoryAttributeMapMutationVariables = Exact<{
  input: Portal_UpdateDirectoryAttributeMapInput;
}>;

export type UpdateDirectoryAttributeMapMutation = {
  __typename?: 'Mutation';
  portal_updateDirectoryAttributeMap?: {
    __typename?: 'Portal_UpdateDirectoryAttributeMapPayload';
    directoryAttributeMap?: {
      __typename?: 'DirectoryAttributeMap';
      id: string;
      attributes: {
        __typename?: 'DirectoryAttributes';
        externalId?: string | null;
        username?: string | null;
        emails?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        jobTitle?: string | null;
        groupName?: string | null;
      };
    } | null;
  } | null;
};

export type UpdateDirectoryMutationVariables = Exact<{
  id: Scalars['String'];
  bamboo_hr_api_key?: InputMaybe<Scalars['String']>;
  bamboo_hr_subdomain?: InputMaybe<Scalars['String']>;
  breatheHrApiKey?: InputMaybe<Scalars['String']>;
  bob_api_token?: InputMaybe<Scalars['String']>;
  bob_service_user_id?: InputMaybe<Scalars['String']>;
  rippling_api_key?: InputMaybe<Scalars['String']>;
  workday_raas_endpoint?: InputMaybe<Scalars['String']>;
  workday_raas_group_endpoint?: InputMaybe<Scalars['String']>;
  workday_raas_password?: InputMaybe<Scalars['String']>;
  workday_raas_username?: InputMaybe<Scalars['String']>;
  fourthHrOrganizationId?: InputMaybe<Scalars['String']>;
  fourthHrPassword?: InputMaybe<Scalars['String']>;
  fourthHrUsername?: InputMaybe<Scalars['String']>;
  peopleHrApiKey?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  cezanneHrClientId?: InputMaybe<Scalars['String']>;
  cezanneHrClientSecret?: InputMaybe<Scalars['String']>;
}>;

export type UpdateDirectoryMutation = {
  __typename?: 'Mutation';
  portal_updateDirectory: {
    __typename: 'portal_Directory';
    id: string;
    name: string;
    type: DirectoryType;
    state: DirectoryState;
    endpoint?: string | null;
    setup_url?: string | null;
    createdAt: any;
    bearerToken?: string | null;
    bamboo_hr_api_key?: string | null;
    breatheHrApiKey?: string | null;
    bamboo_hr_subdomain?: string | null;
    bob_service_user_id?: string | null;
    bob_api_token?: string | null;
    rippling_api_key?: string | null;
    workday_raas_endpoint?: string | null;
    workday_raas_group_endpoint?: string | null;
    workday_raas_password?: string | null;
    workday_raas_username?: string | null;
    fourthHrOrganizationId?: string | null;
    fourthHrPassword?: string | null;
    fourthHrUsername?: string | null;
    peopleHrApiKey?: string | null;
    cezanneHrClientId?: string | null;
    cezanneHrClientSecret?: string | null;
    attributeMap?: {
      __typename?: 'DirectoryAttributeMap';
      id: string;
      attributes: {
        __typename?: 'DirectoryAttributes';
        externalId?: string | null;
        username?: string | null;
        emails?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        jobTitle?: string | null;
        groupName?: string | null;
      };
    } | null;
    customAttributeMappings: Array<{
      __typename?: 'Portal_DirectoryCustomAttributeMapping';
      id: string;
      attribute: string;
      customAttributeId: string;
    }>;
    countsSummary: {
      __typename?: 'Portal_DirectoryCountsSummary';
      userCount: number;
      groupCount: number;
    };
    portalProgression?: {
      __typename?: 'portal_PortalProgression';
      id: string;
      currentStepIdentifier: string;
      formData?: any | null;
      lastCompletedStepNumber: number;
      portalData?: any | null;
    } | null;
    primaryDomain?: {
      __typename?: 'OrganizationDomain';
      id: string;
      domain: string;
    } | null;
  };
};

export type AuditLogEventQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type AuditLogEventQuery = {
  __typename?: 'Query';
  portal_auditLogEvent: {
    __typename?: 'portal_AuditLogEvent';
    data: any;
    id: string;
    createdAt: any;
    occurredAt: any;
    action: string;
    actor: {
      __typename?: 'AuditLogEventActor';
      id: string;
      name?: string | null;
      type: string;
    };
    targets: Array<{ __typename?: 'AuditLogEventTarget'; type: string }>;
  };
};

export type AuditLogEventsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  actions?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  actors?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  targets?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
}>;

export type AuditLogEventsQuery = {
  __typename?: 'Query';
  portal_auditLogEvents: {
    __typename?: 'portal_AuditLogEventList';
    data: Array<{
      __typename?: 'portal_AuditLogEvent';
      id: string;
      createdAt: any;
      occurredAt: any;
      action: string;
      actor: {
        __typename?: 'AuditLogEventActor';
        id: string;
        name?: string | null;
        type: string;
      };
      targets: Array<{ __typename?: 'AuditLogEventTarget'; type: string }>;
    }>;
    listMetadata: {
      __typename?: 'ListMetadata';
      before?: string | null;
      after?: string | null;
    };
  };
};

export type AuditLogExportQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type AuditLogExportQuery = {
  __typename?: 'Query';
  portal_auditLogExport: {
    __typename?: 'portal_AuditLogExport';
    id: string;
    state: AuditLogExportState;
    url?: string | null;
  };
};

export type AuditLogTargetsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  target?: InputMaybe<Scalars['String']>;
}>;

export type AuditLogTargetsQuery = {
  __typename?: 'Query';
  portal_auditLogTargets: {
    __typename?: 'portal_AuditLogTargetList';
    data: Array<{
      __typename?: 'portal_AuditLogTarget';
      id: string;
      target: string;
    }>;
    listMetadata: {
      __typename?: 'ListMetadata';
      before?: string | null;
      after?: string | null;
    };
  };
};

export type AuditLogValidatorsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  action?: InputMaybe<Scalars['String']>;
}>;

export type AuditLogValidatorsQuery = {
  __typename?: 'Query';
  portal_auditLogValidators: {
    __typename?: 'portal_AuditLogValidatorList';
    data: Array<{
      __typename?: 'portal_AuditLogValidator';
      id: string;
      action: string;
      versions: Array<{
        __typename?: 'AuditLogValidatorVersion';
        version: number;
        targets: Array<{
          __typename?: 'AuditLogTarget';
          id: string;
          target: string;
        }>;
      }>;
    }>;
    listMetadata: {
      __typename?: 'ListMetadata';
      before?: string | null;
      after?: string | null;
    };
  };
};

export type ConnectionSessionQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ConnectionSessionQuery = {
  __typename?: 'Query';
  portal_connectionSession:
    | {
        __typename: 'OAuthSession';
        createdAt: any;
        id: string;
        oauthSessionState: OAuthSessionState;
        profile?: {
          __typename?: 'Profile';
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          rawAttributes?: any | null;
        } | null;
        errors: Array<
          | {
              __typename: 'DecryptionFailedError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'IdpInitiatedExpiredResponseError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'IdpInitiatedWithInResponseToError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'InvalidAttributesError';
              code: ConnectionSessionErrorCode;
              reason: string;
              received_attributes: Array<{
                __typename?: 'ReceivedAttribute';
                attribute: string;
                value?: string | null;
              }>;
              expected_attributes: {
                __typename?: 'ExpectedAttributes';
                email_attribute: string;
                first_name_attribute: string;
                idp_id_attribute: string;
                last_name_attribute: string;
              };
            }
          | {
              __typename: 'InvalidX509CertError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'MalformedSamlResponseError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'ProfileNotAllowedOutsideOrganizationError';
              code: ConnectionSessionErrorCode;
              reason: string;
              profileDomain: string;
              organizationDomains: Array<string>;
            }
        >;
      }
    | {
        __typename: 'OidcSession';
        createdAt: any;
        id: string;
        oidcSessionState: OidcSessionState;
        profile?: {
          __typename?: 'Profile';
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          rawAttributes?: any | null;
        } | null;
        errors: Array<
          | {
              __typename: 'DecryptionFailedError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'IdpInitiatedExpiredResponseError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'IdpInitiatedWithInResponseToError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'InvalidAttributesError';
              code: ConnectionSessionErrorCode;
              reason: string;
              received_attributes: Array<{
                __typename?: 'ReceivedAttribute';
                attribute: string;
                value?: string | null;
              }>;
              expected_attributes: {
                __typename?: 'ExpectedAttributes';
                email_attribute: string;
                first_name_attribute: string;
                idp_id_attribute: string;
                last_name_attribute: string;
              };
            }
          | {
              __typename: 'InvalidX509CertError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'MalformedSamlResponseError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'ProfileNotAllowedOutsideOrganizationError';
              code: ConnectionSessionErrorCode;
              reason: string;
              profileDomain: string;
              organizationDomains: Array<string>;
            }
        >;
      }
    | {
        __typename: 'SAMLSession';
        createdAt: any;
        id: string;
        samlRequest?: string | null;
        samlResponse?: string | null;
        samlSessionState: SamlSessionState;
        profile?: {
          __typename?: 'Profile';
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
        errors: Array<
          | {
              __typename: 'DecryptionFailedError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'IdpInitiatedExpiredResponseError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'IdpInitiatedWithInResponseToError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'InvalidAttributesError';
              code: ConnectionSessionErrorCode;
              reason: string;
              received_attributes: Array<{
                __typename?: 'ReceivedAttribute';
                attribute: string;
                value?: string | null;
              }>;
              expected_attributes: {
                __typename?: 'ExpectedAttributes';
                email_attribute: string;
                first_name_attribute: string;
                idp_id_attribute: string;
                last_name_attribute: string;
              };
            }
          | {
              __typename: 'InvalidX509CertError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'MalformedSamlResponseError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'ProfileNotAllowedOutsideOrganizationError';
              code: ConnectionSessionErrorCode;
              reason: string;
              profileDomain: string;
              organizationDomains: Array<string>;
            }
        >;
      };
};

export type ConnectionSessionsQueryVariables = Exact<{
  samlSessionState?: InputMaybe<SamlSessionState>;
  oauthSessionState?: InputMaybe<OAuthSessionState>;
  oidcSessionState?: InputMaybe<OidcSessionState>;
}>;

export type ConnectionSessionsQuery = {
  __typename?: 'Query';
  portal_connections: {
    __typename?: 'portal_ConnectionList';
    data: Array<
      | {
          __typename: 'portal_Connection';
          samlSessions: {
            __typename?: 'SAMLSessionsList';
            data: Array<{
              __typename: 'SAMLSession';
              createdAt: any;
              id: string;
              samlRequest?: string | null;
              samlResponse?: string | null;
              samlSessionState: SamlSessionState;
              profile?: {
                __typename?: 'Profile';
                email?: string | null;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
              errors: Array<
                | {
                    __typename: 'DecryptionFailedError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                  }
                | {
                    __typename: 'IdpInitiatedExpiredResponseError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                  }
                | {
                    __typename: 'IdpInitiatedWithInResponseToError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                  }
                | {
                    __typename: 'InvalidAttributesError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                    received_attributes: Array<{
                      __typename?: 'ReceivedAttribute';
                      attribute: string;
                      value?: string | null;
                    }>;
                    expected_attributes: {
                      __typename?: 'ExpectedAttributes';
                      email_attribute: string;
                      first_name_attribute: string;
                      idp_id_attribute: string;
                      last_name_attribute: string;
                    };
                  }
                | {
                    __typename: 'InvalidX509CertError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                  }
                | {
                    __typename: 'MalformedSamlResponseError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                  }
                | {
                    __typename: 'ProfileNotAllowedOutsideOrganizationError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                    profileDomain: string;
                    organizationDomains: Array<string>;
                  }
              >;
            }>;
          };
          oauthSessions: {
            __typename?: 'OAuthSessionsList';
            data: Array<{
              __typename: 'OAuthSession';
              createdAt: any;
              id: string;
              oauthSessionState: OAuthSessionState;
              profile?: {
                __typename?: 'Profile';
                email?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                rawAttributes?: any | null;
              } | null;
              errors: Array<
                | {
                    __typename: 'DecryptionFailedError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                  }
                | {
                    __typename: 'IdpInitiatedExpiredResponseError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                  }
                | {
                    __typename: 'IdpInitiatedWithInResponseToError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                  }
                | {
                    __typename: 'InvalidAttributesError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                    received_attributes: Array<{
                      __typename?: 'ReceivedAttribute';
                      attribute: string;
                      value?: string | null;
                    }>;
                    expected_attributes: {
                      __typename?: 'ExpectedAttributes';
                      email_attribute: string;
                      first_name_attribute: string;
                      idp_id_attribute: string;
                      last_name_attribute: string;
                    };
                  }
                | {
                    __typename: 'InvalidX509CertError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                  }
                | {
                    __typename: 'MalformedSamlResponseError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                  }
                | {
                    __typename: 'ProfileNotAllowedOutsideOrganizationError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                    profileDomain: string;
                    organizationDomains: Array<string>;
                  }
              >;
            }>;
          };
          oidcSessions: {
            __typename?: 'OidcSessionsList';
            data: Array<{
              __typename: 'OidcSession';
              createdAt: any;
              id: string;
              oidcSessionState: OidcSessionState;
              profile?: {
                __typename?: 'Profile';
                email?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                rawAttributes?: any | null;
              } | null;
              errors: Array<
                | {
                    __typename: 'DecryptionFailedError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                  }
                | {
                    __typename: 'IdpInitiatedExpiredResponseError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                  }
                | {
                    __typename: 'IdpInitiatedWithInResponseToError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                  }
                | {
                    __typename: 'InvalidAttributesError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                    received_attributes: Array<{
                      __typename?: 'ReceivedAttribute';
                      attribute: string;
                      value?: string | null;
                    }>;
                    expected_attributes: {
                      __typename?: 'ExpectedAttributes';
                      email_attribute: string;
                      first_name_attribute: string;
                      idp_id_attribute: string;
                      last_name_attribute: string;
                    };
                  }
                | {
                    __typename: 'InvalidX509CertError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                  }
                | {
                    __typename: 'MalformedSamlResponseError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                  }
                | {
                    __typename: 'ProfileNotAllowedOutsideOrganizationError';
                    code: ConnectionSessionErrorCode;
                    reason: string;
                    profileDomain: string;
                    organizationDomains: Array<string>;
                  }
              >;
            }>;
          };
        }
      | { __typename: 'portal_UntypedConnection' }
    >;
  };
};

export type ConnectionsQueryVariables = Exact<{ [key: string]: never }>;

export type ConnectionsQuery = {
  __typename?: 'Query';
  portal_connections: {
    __typename?: 'portal_ConnectionList';
    data: Array<
      | {
          __typename: 'portal_Connection';
          id: string;
          externalKey: string;
          type: ConnectionType;
          createdAt: any;
          name: string;
          oidc_client_id?: string | null;
          oidc_client_secret?: string | null;
          oidc_discovery_endpoint?: string | null;
          oidc_redirect_uri?: string | null;
          state: ConnectionState;
          saml_acs_url: string;
          saml_entity_id?: string | null;
          saml_idp_url?: string | null;
          saml_idp_metadata_url?: string | null;
          saml_sp_metadata_url: string;
          adpConnectionSslCertificates: Array<{
            __typename?: 'Portal_AdpConnectionSslCertificate';
            id: string;
            value: string;
          }>;
          attributeMap?: { __typename?: 'AttributeMap'; id: string } | null;
          connectionDomains: Array<{
            __typename?: 'ConnectionDomain';
            id: string;
            domain: string;
          }>;
          portalProgression?: {
            __typename?: 'portal_PortalProgression';
            id: string;
            currentStepIdentifier: string;
            formData?: any | null;
            lastCompletedStepNumber: number;
            portalData?: any | null;
          } | null;
          samlX509Certificates: Array<{
            __typename?: 'portal_SamlX509Certificate';
            id: string;
            value: string;
            notBefore?: any | null;
            notAfter?: any | null;
          }>;
          saml: {
            __typename?: 'Portal_SamlConnection';
            idpEntityId?: string | null;
          };
        }
      | { __typename: 'portal_UntypedConnection'; id: string }
    >;
  };
};

export type DirectoriesQueryVariables = Exact<{ [key: string]: never }>;

export type DirectoriesQuery = {
  __typename?: 'Query';
  directories: {
    __typename?: 'portal_DirectoryList';
    data: Array<
      | {
          __typename: 'portal_Directory';
          id: string;
          name: string;
          type: DirectoryType;
          state: DirectoryState;
          endpoint?: string | null;
          setup_url?: string | null;
          createdAt: any;
          bearerToken?: string | null;
          bamboo_hr_api_key?: string | null;
          breatheHrApiKey?: string | null;
          bamboo_hr_subdomain?: string | null;
          bob_service_user_id?: string | null;
          bob_api_token?: string | null;
          rippling_api_key?: string | null;
          workday_raas_endpoint?: string | null;
          workday_raas_group_endpoint?: string | null;
          workday_raas_password?: string | null;
          workday_raas_username?: string | null;
          fourthHrOrganizationId?: string | null;
          fourthHrPassword?: string | null;
          fourthHrUsername?: string | null;
          peopleHrApiKey?: string | null;
          cezanneHrClientId?: string | null;
          cezanneHrClientSecret?: string | null;
          attributeMap?: {
            __typename?: 'DirectoryAttributeMap';
            id: string;
            attributes: {
              __typename?: 'DirectoryAttributes';
              externalId?: string | null;
              username?: string | null;
              emails?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              jobTitle?: string | null;
              groupName?: string | null;
            };
          } | null;
          customAttributeMappings: Array<{
            __typename?: 'Portal_DirectoryCustomAttributeMapping';
            id: string;
            attribute: string;
            customAttributeId: string;
          }>;
          countsSummary: {
            __typename?: 'Portal_DirectoryCountsSummary';
            userCount: number;
            groupCount: number;
          };
          portalProgression?: {
            __typename?: 'portal_PortalProgression';
            id: string;
            currentStepIdentifier: string;
            formData?: any | null;
            lastCompletedStepNumber: number;
            portalData?: any | null;
          } | null;
          primaryDomain?: {
            __typename?: 'OrganizationDomain';
            id: string;
            domain: string;
          } | null;
        }
      | { __typename: 'portal_UntypedDirectory'; id: string }
    >;
  };
};

export type DirectoryCustomAttributesQueryVariables = Exact<{
  directoryId: Scalars['String'];
}>;

export type DirectoryCustomAttributesQuery = {
  __typename?: 'Query';
  portal_directoryCustomAttributes: {
    __typename?: 'Portal_DirectoryCustomAttributeList';
    data: Array<{
      __typename?: 'Portal_DirectoryCustomAttribute';
      id: string;
      isRequired: boolean;
      name: string;
    }>;
  };
};

export type DirectoryOverviewQueryVariables = Exact<{
  id: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<DirectorySyncRunState>;
}>;

export type DirectoryOverviewQuery = {
  __typename?: 'Query';
  portal_directory: {
    __typename: 'portal_Directory';
    id: string;
    name: string;
    type: DirectoryType;
    state: DirectoryState;
    endpoint?: string | null;
    setup_url?: string | null;
    createdAt: any;
    bearerToken?: string | null;
    bamboo_hr_api_key?: string | null;
    breatheHrApiKey?: string | null;
    bamboo_hr_subdomain?: string | null;
    bob_service_user_id?: string | null;
    bob_api_token?: string | null;
    rippling_api_key?: string | null;
    workday_raas_endpoint?: string | null;
    workday_raas_group_endpoint?: string | null;
    workday_raas_password?: string | null;
    workday_raas_username?: string | null;
    fourthHrOrganizationId?: string | null;
    fourthHrPassword?: string | null;
    fourthHrUsername?: string | null;
    peopleHrApiKey?: string | null;
    cezanneHrClientId?: string | null;
    cezanneHrClientSecret?: string | null;
    directorySyncRuns: {
      __typename?: 'portal_DirectorySyncRunsList';
      data: Array<{
        __typename?: 'Portal_DirectorySyncRun';
        id: string;
        state: DirectorySyncRunState;
        updatedAt: any;
        errors: Array<
          | {
              __typename: 'MalformedDirectoryGroupError';
              code: DirectorySyncRunErrorCode;
              reason: string;
            }
          | {
              __typename: 'MalformedDirectoryUserError';
              code: DirectorySyncRunErrorCode;
              reason: string;
            }
        >;
      }>;
    };
    attributeMap?: {
      __typename?: 'DirectoryAttributeMap';
      id: string;
      attributes: {
        __typename?: 'DirectoryAttributes';
        externalId?: string | null;
        username?: string | null;
        emails?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        jobTitle?: string | null;
        groupName?: string | null;
      };
    } | null;
    customAttributeMappings: Array<{
      __typename?: 'Portal_DirectoryCustomAttributeMapping';
      id: string;
      attribute: string;
      customAttributeId: string;
    }>;
    countsSummary: {
      __typename?: 'Portal_DirectoryCountsSummary';
      userCount: number;
      groupCount: number;
    };
    portalProgression?: {
      __typename?: 'portal_PortalProgression';
      id: string;
      currentStepIdentifier: string;
      formData?: any | null;
      lastCompletedStepNumber: number;
      portalData?: any | null;
    } | null;
    primaryDomain?: {
      __typename?: 'OrganizationDomain';
      id: string;
      domain: string;
    } | null;
  };
};

export type DirectoryUsersQueryVariables = Exact<{
  directoryId: Scalars['String'];
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type DirectoryUsersQuery = {
  __typename?: 'Query';
  portal_directory: {
    __typename?: 'portal_Directory';
    directoryUsers: {
      __typename?: 'DirectoryUsersList';
      data: Array<{
        __typename?: 'DirectoryUser';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        username?: string | null;
        state: DirectoryUserState;
        directoryGroups: Array<{
          __typename?: 'DirectoryGroup';
          id: string;
          name: string;
        }>;
      }>;
      listMetadata: {
        __typename?: 'ListMetadata';
        before?: string | null;
        after?: string | null;
      };
    };
  };
};

export type DirectoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type DirectoryQuery = {
  __typename?: 'Query';
  portal_directory: {
    __typename: 'portal_Directory';
    id: string;
    name: string;
    type: DirectoryType;
    state: DirectoryState;
    endpoint?: string | null;
    setup_url?: string | null;
    createdAt: any;
    bearerToken?: string | null;
    bamboo_hr_api_key?: string | null;
    breatheHrApiKey?: string | null;
    bamboo_hr_subdomain?: string | null;
    bob_service_user_id?: string | null;
    bob_api_token?: string | null;
    rippling_api_key?: string | null;
    workday_raas_endpoint?: string | null;
    workday_raas_group_endpoint?: string | null;
    workday_raas_password?: string | null;
    workday_raas_username?: string | null;
    fourthHrOrganizationId?: string | null;
    fourthHrPassword?: string | null;
    fourthHrUsername?: string | null;
    peopleHrApiKey?: string | null;
    cezanneHrClientId?: string | null;
    cezanneHrClientSecret?: string | null;
    attributeMap?: {
      __typename?: 'DirectoryAttributeMap';
      id: string;
      attributes: {
        __typename?: 'DirectoryAttributes';
        externalId?: string | null;
        username?: string | null;
        emails?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        jobTitle?: string | null;
        groupName?: string | null;
      };
    } | null;
    customAttributeMappings: Array<{
      __typename?: 'Portal_DirectoryCustomAttributeMapping';
      id: string;
      attribute: string;
      customAttributeId: string;
    }>;
    countsSummary: {
      __typename?: 'Portal_DirectoryCountsSummary';
      userCount: number;
      groupCount: number;
    };
    portalProgression?: {
      __typename?: 'portal_PortalProgression';
      id: string;
      currentStepIdentifier: string;
      formData?: any | null;
      lastCompletedStepNumber: number;
      portalData?: any | null;
    } | null;
    primaryDomain?: {
      __typename?: 'OrganizationDomain';
      id: string;
      domain: string;
    } | null;
  };
};

export type InitialPortalQueryVariables = Exact<{ [key: string]: never }>;

export type InitialPortalQuery = {
  __typename?: 'Query';
  portal_portalSession: {
    __typename?: 'portal_PortalSession';
    id: string;
    returnUrl: string;
    successUrl?: string | null;
    appName: string;
    connection?:
      | {
          __typename: 'portal_Connection';
          id: string;
          externalKey: string;
          type: ConnectionType;
          createdAt: any;
          name: string;
          oidc_client_id?: string | null;
          oidc_client_secret?: string | null;
          oidc_discovery_endpoint?: string | null;
          oidc_redirect_uri?: string | null;
          state: ConnectionState;
          saml_acs_url: string;
          saml_entity_id?: string | null;
          saml_idp_url?: string | null;
          saml_idp_metadata_url?: string | null;
          saml_sp_metadata_url: string;
          adpConnectionSslCertificates: Array<{
            __typename?: 'Portal_AdpConnectionSslCertificate';
            id: string;
            value: string;
          }>;
          attributeMap?: { __typename?: 'AttributeMap'; id: string } | null;
          connectionDomains: Array<{
            __typename?: 'ConnectionDomain';
            id: string;
            domain: string;
          }>;
          portalProgression?: {
            __typename?: 'portal_PortalProgression';
            id: string;
            currentStepIdentifier: string;
            formData?: any | null;
            lastCompletedStepNumber: number;
            portalData?: any | null;
          } | null;
          samlX509Certificates: Array<{
            __typename?: 'portal_SamlX509Certificate';
            id: string;
            value: string;
            notBefore?: any | null;
            notAfter?: any | null;
          }>;
          saml: {
            __typename?: 'Portal_SamlConnection';
            idpEntityId?: string | null;
          };
        }
      | { __typename: 'portal_UntypedConnection'; id: string }
      | null;
    directory?:
      | {
          __typename: 'portal_Directory';
          id: string;
          name: string;
          type: DirectoryType;
          state: DirectoryState;
          endpoint?: string | null;
          setup_url?: string | null;
          createdAt: any;
          bearerToken?: string | null;
          bamboo_hr_api_key?: string | null;
          breatheHrApiKey?: string | null;
          bamboo_hr_subdomain?: string | null;
          bob_service_user_id?: string | null;
          bob_api_token?: string | null;
          rippling_api_key?: string | null;
          workday_raas_endpoint?: string | null;
          workday_raas_group_endpoint?: string | null;
          workday_raas_password?: string | null;
          workday_raas_username?: string | null;
          fourthHrOrganizationId?: string | null;
          fourthHrPassword?: string | null;
          fourthHrUsername?: string | null;
          peopleHrApiKey?: string | null;
          cezanneHrClientId?: string | null;
          cezanneHrClientSecret?: string | null;
          attributeMap?: {
            __typename?: 'DirectoryAttributeMap';
            id: string;
            attributes: {
              __typename?: 'DirectoryAttributes';
              externalId?: string | null;
              username?: string | null;
              emails?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              jobTitle?: string | null;
              groupName?: string | null;
            };
          } | null;
          customAttributeMappings: Array<{
            __typename?: 'Portal_DirectoryCustomAttributeMapping';
            id: string;
            attribute: string;
            customAttributeId: string;
          }>;
          countsSummary: {
            __typename?: 'Portal_DirectoryCountsSummary';
            userCount: number;
            groupCount: number;
          };
          portalProgression?: {
            __typename?: 'portal_PortalProgression';
            id: string;
            currentStepIdentifier: string;
            formData?: any | null;
            lastCompletedStepNumber: number;
            portalData?: any | null;
          } | null;
          primaryDomain?: {
            __typename?: 'OrganizationDomain';
            id: string;
            domain: string;
          } | null;
        }
      | { __typename: 'portal_UntypedDirectory'; id: string }
      | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      domains: Array<{
        __typename?: 'OrganizationDomain';
        id: string;
        domain: string;
      }>;
    } | null;
    setupLink?: {
      __typename?: 'portal_PortalSetupLink';
      state: PortalSetupLinkState;
      expiresAt: any;
    } | null;
    portalSettings: {
      __typename?: 'PortalSettings';
      logoUrl?: string | null;
      primaryColor?: string | null;
      faviconUrl?: string | null;
    };
  };
};

export type PortalSessionQueryVariables = Exact<{ [key: string]: never }>;

export type PortalSessionQuery = {
  __typename?: 'Query';
  session: {
    __typename?: 'portal_PortalSession';
    id: string;
    returnUrl: string;
    successUrl?: string | null;
    appName: string;
    connection?:
      | {
          __typename: 'portal_Connection';
          id: string;
          externalKey: string;
          type: ConnectionType;
          createdAt: any;
          name: string;
          oidc_client_id?: string | null;
          oidc_client_secret?: string | null;
          oidc_discovery_endpoint?: string | null;
          oidc_redirect_uri?: string | null;
          state: ConnectionState;
          saml_acs_url: string;
          saml_entity_id?: string | null;
          saml_idp_url?: string | null;
          saml_idp_metadata_url?: string | null;
          saml_sp_metadata_url: string;
          adpConnectionSslCertificates: Array<{
            __typename?: 'Portal_AdpConnectionSslCertificate';
            id: string;
            value: string;
          }>;
          attributeMap?: { __typename?: 'AttributeMap'; id: string } | null;
          connectionDomains: Array<{
            __typename?: 'ConnectionDomain';
            id: string;
            domain: string;
          }>;
          portalProgression?: {
            __typename?: 'portal_PortalProgression';
            id: string;
            currentStepIdentifier: string;
            formData?: any | null;
            lastCompletedStepNumber: number;
            portalData?: any | null;
          } | null;
          samlX509Certificates: Array<{
            __typename?: 'portal_SamlX509Certificate';
            id: string;
            value: string;
            notBefore?: any | null;
            notAfter?: any | null;
          }>;
          saml: {
            __typename?: 'Portal_SamlConnection';
            idpEntityId?: string | null;
          };
        }
      | { __typename: 'portal_UntypedConnection'; id: string }
      | null;
    directory?:
      | {
          __typename: 'portal_Directory';
          id: string;
          name: string;
          type: DirectoryType;
          state: DirectoryState;
          endpoint?: string | null;
          setup_url?: string | null;
          createdAt: any;
          bearerToken?: string | null;
          bamboo_hr_api_key?: string | null;
          breatheHrApiKey?: string | null;
          bamboo_hr_subdomain?: string | null;
          bob_service_user_id?: string | null;
          bob_api_token?: string | null;
          rippling_api_key?: string | null;
          workday_raas_endpoint?: string | null;
          workday_raas_group_endpoint?: string | null;
          workday_raas_password?: string | null;
          workday_raas_username?: string | null;
          fourthHrOrganizationId?: string | null;
          fourthHrPassword?: string | null;
          fourthHrUsername?: string | null;
          peopleHrApiKey?: string | null;
          cezanneHrClientId?: string | null;
          cezanneHrClientSecret?: string | null;
          attributeMap?: {
            __typename?: 'DirectoryAttributeMap';
            id: string;
            attributes: {
              __typename?: 'DirectoryAttributes';
              externalId?: string | null;
              username?: string | null;
              emails?: string | null;
              firstName?: string | null;
              lastName?: string | null;
              jobTitle?: string | null;
              groupName?: string | null;
            };
          } | null;
          customAttributeMappings: Array<{
            __typename?: 'Portal_DirectoryCustomAttributeMapping';
            id: string;
            attribute: string;
            customAttributeId: string;
          }>;
          countsSummary: {
            __typename?: 'Portal_DirectoryCountsSummary';
            userCount: number;
            groupCount: number;
          };
          portalProgression?: {
            __typename?: 'portal_PortalProgression';
            id: string;
            currentStepIdentifier: string;
            formData?: any | null;
            lastCompletedStepNumber: number;
            portalData?: any | null;
          } | null;
          primaryDomain?: {
            __typename?: 'OrganizationDomain';
            id: string;
            domain: string;
          } | null;
        }
      | { __typename: 'portal_UntypedDirectory'; id: string }
      | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      domains: Array<{
        __typename?: 'OrganizationDomain';
        id: string;
        domain: string;
      }>;
    } | null;
    setupLink?: {
      __typename?: 'portal_PortalSetupLink';
      state: PortalSetupLinkState;
      expiresAt: any;
    } | null;
    portalSettings: {
      __typename?: 'PortalSettings';
      logoUrl?: string | null;
      primaryColor?: string | null;
      faviconUrl?: string | null;
    };
  };
};

export const AuditLogEventFragmentDoc = gql`
  fragment AuditLogEvent on portal_AuditLogEvent {
    id
    createdAt
    occurredAt
    action
    actor {
      id
      name
      type
    }
    targets {
      type
    }
  }
`;
export const AuditLogTargetFragmentDoc = gql`
  fragment AuditLogTarget on portal_AuditLogTarget {
    id
    target
  }
`;
export const AuditLogValidatorFragmentDoc = gql`
  fragment AuditLogValidator on portal_AuditLogValidator {
    id
    action
    versions {
      version
      targets {
        id
        target
      }
    }
  }
`;
export const ConnectionSessionErrorFragmentDoc = gql`
  fragment ConnectionSessionError on ConnectionSessionError {
    __typename
    ... on MalformedSamlResponseError {
      code
      reason
    }
    ... on InvalidX509CertError {
      code
      reason
    }
    ... on InvalidAttributesError {
      code
      reason
      received_attributes {
        attribute
        value
      }
      expected_attributes {
        email_attribute
        first_name_attribute
        idp_id_attribute
        last_name_attribute
      }
    }
    ... on ProfileNotAllowedOutsideOrganizationError {
      code
      reason
      profileDomain
      organizationDomains
    }
    ... on DecryptionFailedError {
      code
      reason
    }
    ... on IdpInitiatedExpiredResponseError {
      code
      reason
    }
    ... on IdpInitiatedWithInResponseToError {
      code
      reason
    }
  }
`;
export const ConnectionSessionFragmentDoc = gql`
  fragment ConnectionSession on ConnectionSession {
    __typename
    ... on SAMLSession {
      createdAt
      id
      samlRequest
      samlResponse
      samlSessionState: state
      profile {
        email
        firstName
        lastName
      }
      errors {
        ...ConnectionSessionError
      }
    }
    ... on OAuthSession {
      createdAt
      id
      oauthSessionState: state
      profile {
        email
        firstName
        lastName
        rawAttributes
      }
      errors {
        ...ConnectionSessionError
      }
    }
    ... on OidcSession {
      createdAt
      id
      oidcSessionState: state
      profile {
        email
        firstName
        lastName
        rawAttributes
      }
      errors {
        ...ConnectionSessionError
      }
    }
  }
  ${ConnectionSessionErrorFragmentDoc}
`;
export const ConnectionSessionContainerFragmentDoc = gql`
  fragment ConnectionSessionContainer on portal_Connection {
    samlSessions(state: $samlSessionState) {
      data {
        ...ConnectionSession
      }
    }
    oauthSessions(state: $oauthSessionState) {
      data {
        ...ConnectionSession
      }
    }
    oidcSessions(state: $oidcSessionState) {
      data {
        ...ConnectionSession
      }
    }
  }
  ${ConnectionSessionFragmentDoc}
`;
export const DirectoryCustomAttributeFragmentDoc = gql`
  fragment DirectoryCustomAttribute on Portal_DirectoryCustomAttribute {
    id
    isRequired
    name
  }
`;
export const DirectorySyncRunFragmentDoc = gql`
  fragment DirectorySyncRun on Portal_DirectorySyncRun {
    id
    state
    updatedAt
    errors {
      __typename
      ... on MalformedDirectoryUserError {
        code
        reason
      }
      ... on MalformedDirectoryGroupError {
        code
        reason
      }
    }
  }
`;
export const DirectoryGroupFragmentDoc = gql`
  fragment DirectoryGroup on DirectoryGroup {
    id
    name
  }
`;
export const DirectoryUserFragmentDoc = gql`
  fragment DirectoryUser on DirectoryUser {
    id
    firstName
    lastName
    username
    state
    directoryGroups {
      ...DirectoryGroup
    }
  }
  ${DirectoryGroupFragmentDoc}
`;
export const ConnectionDomainFragmentDoc = gql`
  fragment ConnectionDomain on ConnectionDomain {
    id
    domain
  }
`;
export const PortalProgressionFragmentDoc = gql`
  fragment PortalProgression on portal_PortalProgression {
    id
    currentStepIdentifier
    formData
    lastCompletedStepNumber
    portalData
  }
`;
export const SamlX509CertificateFragmentDoc = gql`
  fragment SamlX509Certificate on portal_SamlX509Certificate {
    id
    value
    notBefore
    notAfter
  }
`;
export const ConnectionFragmentDoc = gql`
  fragment Connection on portal_Connection {
    __typename
    id
    externalKey
    adpConnectionSslCertificates {
      id
      value
    }
    attributeMap {
      id
    }
    type
    connectionDomains {
      ...ConnectionDomain
    }
    createdAt
    name
    portalProgression {
      ...PortalProgression
    }
    oidc_client_id
    oidc_client_secret
    oidc_discovery_endpoint
    oidc_redirect_uri
    state
    saml_acs_url
    saml_entity_id
    saml_idp_url
    samlX509Certificates {
      ...SamlX509Certificate
    }
    saml_idp_metadata_url
    saml_sp_metadata_url
    saml {
      idpEntityId
    }
  }
  ${ConnectionDomainFragmentDoc}
  ${PortalProgressionFragmentDoc}
  ${SamlX509CertificateFragmentDoc}
`;
export const UntypedConnectionFragmentDoc = gql`
  fragment UntypedConnection on portal_UntypedConnection {
    __typename
    id
  }
`;
export const DirectoryCustomAttributeMappingFragmentDoc = gql`
  fragment DirectoryCustomAttributeMapping on Portal_DirectoryCustomAttributeMapping {
    id
    attribute
    customAttributeId
  }
`;
export const DirectoryFragmentDoc = gql`
  fragment Directory on portal_Directory {
    __typename
    id
    attributeMap {
      id
      attributes {
        externalId
        username
        emails
        firstName
        lastName
        jobTitle
        groupName
      }
    }
    customAttributeMappings {
      ...DirectoryCustomAttributeMapping
    }
    countsSummary {
      userCount
      groupCount
    }
    name
    type
    state
    endpoint
    setup_url
    createdAt
    bearerToken
    bamboo_hr_api_key
    breatheHrApiKey
    bamboo_hr_subdomain
    bob_service_user_id
    bob_api_token
    rippling_api_key
    workday_raas_endpoint
    workday_raas_group_endpoint
    workday_raas_password
    workday_raas_username
    fourthHrOrganizationId
    fourthHrPassword
    fourthHrUsername
    peopleHrApiKey
    portalProgression {
      ...PortalProgression
    }
    primaryDomain {
      id
      domain
    }
    cezanneHrClientId
    cezanneHrClientSecret
  }
  ${DirectoryCustomAttributeMappingFragmentDoc}
  ${PortalProgressionFragmentDoc}
`;
export const UntypedDirectoryFragmentDoc = gql`
  fragment UntypedDirectory on portal_UntypedDirectory {
    __typename
    id
  }
`;
export const OrganizationFragmentDoc = gql`
  fragment Organization on Organization {
    id
    name
    domains {
      id
      domain
    }
  }
`;
export const PortalSessionFragmentDoc = gql`
  fragment PortalSession on portal_PortalSession {
    id
    returnUrl
    successUrl
    appName
    connection {
      __typename
      ... on portal_Connection {
        ...Connection
      }
      ... on portal_UntypedConnection {
        ...UntypedConnection
      }
    }
    directory {
      __typename
      ... on portal_Directory {
        ...Directory
      }
      ... on portal_UntypedDirectory {
        ...UntypedDirectory
      }
    }
    organization {
      ...Organization
    }
    setupLink {
      state
      expiresAt
    }
    portalSettings {
      logoUrl
      primaryColor
      faviconUrl
    }
  }
  ${ConnectionFragmentDoc}
  ${UntypedConnectionFragmentDoc}
  ${DirectoryFragmentDoc}
  ${UntypedDirectoryFragmentDoc}
  ${OrganizationFragmentDoc}
`;
export const AddAdpConnectionSslCertificateDocument = gql`
  mutation AddAdpConnectionSslCertificate(
    $input: Portal_AddAdpConnectionSslCertificateInput!
  ) {
    portal_addAdpConnectionSslCertificate(input: $input) {
      __typename
      ... on Portal_AdpConnectionSslCertificateAdded {
        adpConnectionSslCertificate {
          id
          value
        }
      }
      ... on ConnectionNotFound {
        connectionId
      }
    }
  }
`;
export const CreateAuditLogExportDocument = gql`
  mutation createAuditLogExport($input: portal_CreateAuditLogExportInput!) {
    portal_createAuditLogExport(input: $input) {
      __typename
      ... on portal_AuditLogExport {
        id
        state
        url
      }
      ... on portal_NoAuditLogEventsFound {
        message
      }
      ... on portal_InvalidExportDateRange {
        message
      }
    }
  }
`;
export const CreateConnectionFromUntypedDocument = gql`
  mutation CreateConnectionFromUntyped(
    $input: Portal_CreateConnectionFromUntypedInput!
  ) {
    portal_createConnectionFromUntyped(input: $input) {
      __typename
      ... on Portal_ConnectionCreated {
        connection {
          ...Connection
        }
      }
      ... on ConnectionNotFound {
        connectionId
      }
      ... on ConnectionIsAlreadyTyped {
        connectionId
      }
    }
  }
  ${ConnectionFragmentDoc}
`;
export const CreateConnectionDocument = gql`
  mutation CreateConnection(
    $name: String!
    $type: ConnectionType!
    $domains: [String!]
  ) {
    portal_createConnection(
      name: $name
      connection_type: $type
      domains: $domains
    ) {
      ...Connection
    }
  }
  ${ConnectionFragmentDoc}
`;
export const CreateDirectoryFromUntypedDocument = gql`
  mutation CreateDirectoryFromUntyped(
    $input: Portal_CreateDirectoryFromUntypedInput!
  ) {
    portal_createDirectoryFromUntyped(input: $input) {
      __typename
      ... on Portal_DirectoryCreated {
        directory {
          ...Directory
        }
      }
      ... on DirectoryNotFound {
        directoryId
      }
      ... on DirectoryIsAlreadyTyped {
        directoryId
      }
    }
  }
  ${DirectoryFragmentDoc}
`;
export const CreateDirectoryDocument = gql`
  mutation CreateDirectory(
    $name: String!
    $type: DirectoryType!
    $domain: String
  ) {
    portal_createDirectory(name: $name, type: $type, domain: $domain) {
      ...Directory
    }
  }
  ${DirectoryFragmentDoc}
`;
export const DeleteConnectionDocument = gql`
  mutation DeleteConnection($id: String!) {
    portal_deleteConnection: portal_deleteDraftConnection(id: $id)
  }
`;
export const DeleteDirectoryDocument = gql`
  mutation DeleteDirectory($id: String!) {
    portal_deleteDirectory(input: { id: $id }) {
      directory {
        id
      }
    }
  }
`;
export const DeleteSamlX509CertificateDocument = gql`
  mutation DeleteSamlX509Certificate(
    $input: Portal_DeleteSamlX509CertificateInput!
  ) {
    portal_deleteSamlX509Certificate(input: $input) {
      __typename
      ... on Portal_SamlX509CertificateDeleted {
        samlX509Certificate {
          id
        }
      }
      ... on SamlX509CertificateNotFound {
        samlX509CertificateId
      }
    }
  }
`;
export const LoadedDocument = gql`
  mutation Loaded($started_at: Float!, $legacy: Boolean) {
    portal_loaded(started_at: $started_at, legacy: $legacy) {
      success
    }
  }
`;
export const ReadyDocument = gql`
  mutation Ready($started_at: Float!, $legacy: Boolean) {
    portal_ready(started_at: $started_at, legacy: $legacy) {
      success
    }
  }
`;
export const RequestIdentityProviderDocument = gql`
  mutation RequestIdentityProvider(
    $identityProvider: String!
    $pageRequestedFrom: String
  ) {
    portalIdentityProviderRequest(
      identityProvider: $identityProvider
      pageRequestedFrom: $pageRequestedFrom
    )
  }
`;
export const SetConnectionPortalProgressionDocument = gql`
  mutation SetConnectionPortalProgression(
    $input: SetConnectionPortalProgressionInput!
  ) {
    portal_setConnectionPortalProgression(input: $input) {
      __typename
      ... on PortalProgressionSet {
        portalProgression {
          ...PortalProgression
        }
      }
    }
  }
  ${PortalProgressionFragmentDoc}
`;
export const SetDirectoryCustomAttributeMappingsDocument = gql`
  mutation SetDirectoryCustomAttributeMappings(
    $input: Portal_SetDirectoryCustomAttributeMappingsInput!
  ) {
    portal_setDirectoryCustomAttributeMappings(input: $input) {
      __typename
      ... on Portal_DirectoryCustomAttributeMappingsSet {
        mappings {
          ...DirectoryCustomAttributeMapping
        }
      }
    }
  }
  ${DirectoryCustomAttributeMappingFragmentDoc}
`;
export const SetDirectoryPortalProgressionDocument = gql`
  mutation SetDirectoryPortalProgression(
    $input: SetDirectoryPortalProgressionInput!
  ) {
    portal_setDirectoryPortalProgression(input: $input) {
      __typename
      ... on PortalProgressionSet {
        portalProgression {
          ...PortalProgression
        }
      }
    }
  }
  ${PortalProgressionFragmentDoc}
`;
export const TrackEventDocument = gql`
  mutation TrackEvent($event_name: String!, $attributes: JSON) {
    portal_trackEvent(event_name: $event_name, attributes: $attributes) {
      success
    }
  }
`;
export const UpdateAttributeMapDocument = gql`
  mutation UpdateAttributeMap(
    $id: String!
    $idp_id_attribute: String
    $email_attribute: String
    $first_name_attribute: String
    $last_name_attribute: String
  ) {
    portal_updateAttributeMap(
      attribute_map_id: $id
      idp_id_attribute: $idp_id_attribute
      email_attribute: $email_attribute
      first_name_attribute: $first_name_attribute
      last_name_attribute: $last_name_attribute
    ) {
      id
    }
  }
`;
export const UpdateConnectionFromMetadataUrlDocument = gql`
  mutation UpdateConnectionFromMetadataUrl(
    $input: Portal_UpdateConnectionFromMetadataUrlInput!
  ) {
    portal_updateConnectionFromMetadataUrl(input: $input) {
      __typename
      ... on Portal_ConnectionUpdatedFromMetadataUrl {
        connection {
          ...Connection
        }
      }
      ... on ConnectionNotFound {
        connectionId
      }
      ... on Portal_MetadataFetchFailed {
        reason
      }
      ... on InvalidSamlX509Certificate {
        validationError
      }
    }
  }
  ${ConnectionFragmentDoc}
`;
export const UpdateConnectionFromMetadataXmlDocument = gql`
  mutation UpdateConnectionFromMetadataXml(
    $input: Portal_UpdateConnectionFromMetadataXmlInput!
  ) {
    portal_updateConnectionFromMetadataXml(input: $input) {
      __typename
      ... on Portal_ConnectionUpdatedFromMetadataXml {
        connection {
          ...Connection
        }
      }
      ... on ConnectionNotFound {
        connectionId
      }
      ... on Portal_MetadataParseFailed {
        reason
      }
      ... on InvalidSamlX509Certificate {
        validationError
      }
    }
  }
  ${ConnectionFragmentDoc}
`;
export const UpdateConnectionDocument = gql`
  mutation UpdateConnection($input: Portal_UpdateConnectionInput!) {
    portal_updateConnection(input: $input) {
      __typename
      ... on Portal_ConnectionUpdated {
        connection {
          ...Connection
        }
      }
      ... on ConnectionNotFound {
        connectionId
      }
      ... on InvalidSamlX509Certificate {
        validationError
      }
      ... on VerifyConnectionFailed {
        connectionErrors {
          error
          field
          solution
        }
      }
    }
  }
  ${ConnectionFragmentDoc}
`;
export const UpdateDirectoryAttributeMapDocument = gql`
  mutation UpdateDirectoryAttributeMap(
    $input: Portal_UpdateDirectoryAttributeMapInput!
  ) {
    portal_updateDirectoryAttributeMap(input: $input) {
      directoryAttributeMap {
        id
        attributes {
          externalId
          username
          emails
          firstName
          lastName
          jobTitle
          groupName
        }
      }
    }
  }
`;
export const UpdateDirectoryDocument = gql`
  mutation UpdateDirectory(
    $id: String!
    $bamboo_hr_api_key: String
    $bamboo_hr_subdomain: String
    $breatheHrApiKey: String
    $bob_api_token: String
    $bob_service_user_id: String
    $rippling_api_key: String
    $workday_raas_endpoint: String
    $workday_raas_group_endpoint: String
    $workday_raas_password: String
    $workday_raas_username: String
    $fourthHrOrganizationId: String
    $fourthHrPassword: String
    $fourthHrUsername: String
    $peopleHrApiKey: String
    $domain: String
    $cezanneHrClientId: String
    $cezanneHrClientSecret: String
  ) {
    portal_updateDirectory(
      directory_id: $id
      bamboo_hr_api_key: $bamboo_hr_api_key
      bamboo_hr_subdomain: $bamboo_hr_subdomain
      breatheHrApiKey: $breatheHrApiKey
      bob_api_token: $bob_api_token
      bob_service_user_id: $bob_service_user_id
      rippling_api_key: $rippling_api_key
      workday_raas_endpoint: $workday_raas_endpoint
      workday_raas_group_endpoint: $workday_raas_group_endpoint
      workday_raas_password: $workday_raas_password
      workday_raas_username: $workday_raas_username
      fourthHrOrganizationId: $fourthHrOrganizationId
      fourthHrPassword: $fourthHrPassword
      fourthHrUsername: $fourthHrUsername
      peopleHrApiKey: $peopleHrApiKey
      domain: $domain
      cezanneHrClientId: $cezanneHrClientId
      cezanneHrClientSecret: $cezanneHrClientSecret
    ) {
      ...Directory
    }
  }
  ${DirectoryFragmentDoc}
`;
export const AuditLogEventDocument = gql`
  query auditLogEvent($id: String!) {
    portal_auditLogEvent(id: $id) {
      data
      ...AuditLogEvent
    }
  }
  ${AuditLogEventFragmentDoc}
`;
export const AuditLogEventsDocument = gql`
  query auditLogEvents(
    $after: String
    $before: String
    $limit: Int
    $actions: [String!]
    $actors: [String!]
    $targets: [String!]
    $startDate: DateTime
    $endDate: DateTime
  ) {
    portal_auditLogEvents(
      after: $after
      before: $before
      limit: $limit
      actions: $actions
      actors: $actors
      targets: $targets
      startDate: $startDate
      endDate: $endDate
    ) {
      data {
        ...AuditLogEvent
      }
      listMetadata {
        before
        after
      }
    }
  }
  ${AuditLogEventFragmentDoc}
`;
export const AuditLogExportDocument = gql`
  query auditLogExport($id: String!) {
    portal_auditLogExport(id: $id) {
      id
      state
      url
    }
  }
`;
export const AuditLogTargetsDocument = gql`
  query auditLogTargets(
    $after: String
    $before: String
    $limit: Int
    $target: String
  ) {
    portal_auditLogTargets(
      after: $after
      before: $before
      limit: $limit
      target: $target
    ) {
      data {
        ...AuditLogTarget
      }
      listMetadata {
        before
        after
      }
    }
  }
  ${AuditLogTargetFragmentDoc}
`;
export const AuditLogValidatorsDocument = gql`
  query auditLogValidators(
    $after: String
    $before: String
    $limit: Int
    $action: String
  ) {
    portal_auditLogValidators(
      after: $after
      before: $before
      limit: $limit
      action: $action
    ) {
      data {
        ...AuditLogValidator
      }
      listMetadata {
        before
        after
      }
    }
  }
  ${AuditLogValidatorFragmentDoc}
`;
export const ConnectionSessionDocument = gql`
  query ConnectionSession($id: String!) {
    portal_connectionSession(id: $id) {
      ...ConnectionSession
    }
  }
  ${ConnectionSessionFragmentDoc}
`;
export const ConnectionSessionsDocument = gql`
  query ConnectionSessions(
    $samlSessionState: SAMLSessionState
    $oauthSessionState: OAuthSessionState
    $oidcSessionState: OidcSessionState
  ) {
    portal_connections {
      data {
        __typename
        ... on portal_Connection {
          ...ConnectionSessionContainer
        }
      }
    }
  }
  ${ConnectionSessionContainerFragmentDoc}
`;
export const ConnectionsDocument = gql`
  query Connections {
    portal_connections {
      data {
        __typename
        ... on portal_Connection {
          ...Connection
        }
        ... on portal_UntypedConnection {
          ...UntypedConnection
        }
      }
    }
  }
  ${ConnectionFragmentDoc}
  ${UntypedConnectionFragmentDoc}
`;
export const DirectoriesDocument = gql`
  query Directories {
    directories: portal_directories {
      data {
        __typename
        ... on portal_Directory {
          ...Directory
        }
        ... on portal_UntypedDirectory {
          ...UntypedDirectory
        }
      }
    }
  }
  ${DirectoryFragmentDoc}
  ${UntypedDirectoryFragmentDoc}
`;
export const DirectoryCustomAttributesDocument = gql`
  query DirectoryCustomAttributes($directoryId: String!) {
    portal_directoryCustomAttributes(directoryId: $directoryId) {
      data {
        ...DirectoryCustomAttribute
      }
    }
  }
  ${DirectoryCustomAttributeFragmentDoc}
`;
export const DirectoryOverviewDocument = gql`
  query DirectoryOverview(
    $id: String!
    $limit: Int = 1
    $state: DirectorySyncRunState
  ) {
    portal_directory(id: $id) {
      ...Directory
      directorySyncRuns(limit: $limit, state: $state) {
        data {
          ...DirectorySyncRun
        }
      }
    }
  }
  ${DirectoryFragmentDoc}
  ${DirectorySyncRunFragmentDoc}
`;
export const DirectoryUsersDocument = gql`
  query DirectoryUsers(
    $directoryId: String!
    $before: String
    $after: String
    $limit: Int
  ) {
    portal_directory(id: $directoryId) {
      directoryUsers(before: $before, after: $after, limit: $limit) {
        data {
          ...DirectoryUser
        }
        listMetadata {
          before
          after
        }
      }
    }
  }
  ${DirectoryUserFragmentDoc}
`;
export const DirectoryDocument = gql`
  query Directory($id: String!) {
    portal_directory(id: $id) {
      ...Directory
    }
  }
  ${DirectoryFragmentDoc}
`;
export const InitialPortalDocument = gql`
  query InitialPortal {
    portal_portalSession {
      ...PortalSession
    }
  }
  ${PortalSessionFragmentDoc}
`;
export const PortalSessionDocument = gql`
  query PortalSession {
    session: portal_portalSession {
      ...PortalSession
    }
  }
  ${PortalSessionFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType,
) => action();
const AddAdpConnectionSslCertificateDocumentString = print(
  AddAdpConnectionSslCertificateDocument,
);
const CreateAuditLogExportDocumentString = print(CreateAuditLogExportDocument);
const CreateConnectionFromUntypedDocumentString = print(
  CreateConnectionFromUntypedDocument,
);
const CreateConnectionDocumentString = print(CreateConnectionDocument);
const CreateDirectoryFromUntypedDocumentString = print(
  CreateDirectoryFromUntypedDocument,
);
const CreateDirectoryDocumentString = print(CreateDirectoryDocument);
const DeleteConnectionDocumentString = print(DeleteConnectionDocument);
const DeleteDirectoryDocumentString = print(DeleteDirectoryDocument);
const DeleteSamlX509CertificateDocumentString = print(
  DeleteSamlX509CertificateDocument,
);
const LoadedDocumentString = print(LoadedDocument);
const ReadyDocumentString = print(ReadyDocument);
const RequestIdentityProviderDocumentString = print(
  RequestIdentityProviderDocument,
);
const SetConnectionPortalProgressionDocumentString = print(
  SetConnectionPortalProgressionDocument,
);
const SetDirectoryCustomAttributeMappingsDocumentString = print(
  SetDirectoryCustomAttributeMappingsDocument,
);
const SetDirectoryPortalProgressionDocumentString = print(
  SetDirectoryPortalProgressionDocument,
);
const TrackEventDocumentString = print(TrackEventDocument);
const UpdateAttributeMapDocumentString = print(UpdateAttributeMapDocument);
const UpdateConnectionFromMetadataUrlDocumentString = print(
  UpdateConnectionFromMetadataUrlDocument,
);
const UpdateConnectionFromMetadataXmlDocumentString = print(
  UpdateConnectionFromMetadataXmlDocument,
);
const UpdateConnectionDocumentString = print(UpdateConnectionDocument);
const UpdateDirectoryAttributeMapDocumentString = print(
  UpdateDirectoryAttributeMapDocument,
);
const UpdateDirectoryDocumentString = print(UpdateDirectoryDocument);
const AuditLogEventDocumentString = print(AuditLogEventDocument);
const AuditLogEventsDocumentString = print(AuditLogEventsDocument);
const AuditLogExportDocumentString = print(AuditLogExportDocument);
const AuditLogTargetsDocumentString = print(AuditLogTargetsDocument);
const AuditLogValidatorsDocumentString = print(AuditLogValidatorsDocument);
const ConnectionSessionDocumentString = print(ConnectionSessionDocument);
const ConnectionSessionsDocumentString = print(ConnectionSessionsDocument);
const ConnectionsDocumentString = print(ConnectionsDocument);
const DirectoriesDocumentString = print(DirectoriesDocument);
const DirectoryCustomAttributesDocumentString = print(
  DirectoryCustomAttributesDocument,
);
const DirectoryOverviewDocumentString = print(DirectoryOverviewDocument);
const DirectoryUsersDocumentString = print(DirectoryUsersDocument);
const DirectoryDocumentString = print(DirectoryDocument);
const InitialPortalDocumentString = print(InitialPortalDocument);
const PortalSessionDocumentString = print(PortalSessionDocument);
export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    AddAdpConnectionSslCertificate(
      variables: AddAdpConnectionSslCertificateMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: AddAdpConnectionSslCertificateMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<AddAdpConnectionSslCertificateMutation>(
            AddAdpConnectionSslCertificateDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'AddAdpConnectionSslCertificate',
        'mutation',
      );
    },
    createAuditLogExport(
      variables: CreateAuditLogExportMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: CreateAuditLogExportMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<CreateAuditLogExportMutation>(
            CreateAuditLogExportDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'createAuditLogExport',
        'mutation',
      );
    },
    CreateConnectionFromUntyped(
      variables: CreateConnectionFromUntypedMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: CreateConnectionFromUntypedMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<CreateConnectionFromUntypedMutation>(
            CreateConnectionFromUntypedDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'CreateConnectionFromUntyped',
        'mutation',
      );
    },
    CreateConnection(
      variables: CreateConnectionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: CreateConnectionMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<CreateConnectionMutation>(
            CreateConnectionDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'CreateConnection',
        'mutation',
      );
    },
    CreateDirectoryFromUntyped(
      variables: CreateDirectoryFromUntypedMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: CreateDirectoryFromUntypedMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<CreateDirectoryFromUntypedMutation>(
            CreateDirectoryFromUntypedDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'CreateDirectoryFromUntyped',
        'mutation',
      );
    },
    CreateDirectory(
      variables: CreateDirectoryMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: CreateDirectoryMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<CreateDirectoryMutation>(
            CreateDirectoryDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'CreateDirectory',
        'mutation',
      );
    },
    DeleteConnection(
      variables: DeleteConnectionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: DeleteConnectionMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<DeleteConnectionMutation>(
            DeleteConnectionDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DeleteConnection',
        'mutation',
      );
    },
    DeleteDirectory(
      variables: DeleteDirectoryMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: DeleteDirectoryMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<DeleteDirectoryMutation>(
            DeleteDirectoryDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DeleteDirectory',
        'mutation',
      );
    },
    DeleteSamlX509Certificate(
      variables: DeleteSamlX509CertificateMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: DeleteSamlX509CertificateMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<DeleteSamlX509CertificateMutation>(
            DeleteSamlX509CertificateDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DeleteSamlX509Certificate',
        'mutation',
      );
    },
    Loaded(
      variables: LoadedMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: LoadedMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<LoadedMutation>(LoadedDocumentString, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Loaded',
        'mutation',
      );
    },
    Ready(
      variables: ReadyMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: ReadyMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<ReadyMutation>(ReadyDocumentString, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Ready',
        'mutation',
      );
    },
    RequestIdentityProvider(
      variables: RequestIdentityProviderMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: RequestIdentityProviderMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<RequestIdentityProviderMutation>(
            RequestIdentityProviderDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'RequestIdentityProvider',
        'mutation',
      );
    },
    SetConnectionPortalProgression(
      variables: SetConnectionPortalProgressionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: SetConnectionPortalProgressionMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<SetConnectionPortalProgressionMutation>(
            SetConnectionPortalProgressionDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'SetConnectionPortalProgression',
        'mutation',
      );
    },
    SetDirectoryCustomAttributeMappings(
      variables: SetDirectoryCustomAttributeMappingsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: SetDirectoryCustomAttributeMappingsMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<SetDirectoryCustomAttributeMappingsMutation>(
            SetDirectoryCustomAttributeMappingsDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'SetDirectoryCustomAttributeMappings',
        'mutation',
      );
    },
    SetDirectoryPortalProgression(
      variables: SetDirectoryPortalProgressionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: SetDirectoryPortalProgressionMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<SetDirectoryPortalProgressionMutation>(
            SetDirectoryPortalProgressionDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'SetDirectoryPortalProgression',
        'mutation',
      );
    },
    TrackEvent(
      variables: TrackEventMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: TrackEventMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<TrackEventMutation>(
            TrackEventDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'TrackEvent',
        'mutation',
      );
    },
    UpdateAttributeMap(
      variables: UpdateAttributeMapMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: UpdateAttributeMapMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<UpdateAttributeMapMutation>(
            UpdateAttributeMapDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateAttributeMap',
        'mutation',
      );
    },
    UpdateConnectionFromMetadataUrl(
      variables: UpdateConnectionFromMetadataUrlMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: UpdateConnectionFromMetadataUrlMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<UpdateConnectionFromMetadataUrlMutation>(
            UpdateConnectionFromMetadataUrlDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateConnectionFromMetadataUrl',
        'mutation',
      );
    },
    UpdateConnectionFromMetadataXml(
      variables: UpdateConnectionFromMetadataXmlMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: UpdateConnectionFromMetadataXmlMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<UpdateConnectionFromMetadataXmlMutation>(
            UpdateConnectionFromMetadataXmlDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateConnectionFromMetadataXml',
        'mutation',
      );
    },
    UpdateConnection(
      variables: UpdateConnectionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: UpdateConnectionMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<UpdateConnectionMutation>(
            UpdateConnectionDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateConnection',
        'mutation',
      );
    },
    UpdateDirectoryAttributeMap(
      variables: UpdateDirectoryAttributeMapMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: UpdateDirectoryAttributeMapMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<UpdateDirectoryAttributeMapMutation>(
            UpdateDirectoryAttributeMapDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateDirectoryAttributeMap',
        'mutation',
      );
    },
    UpdateDirectory(
      variables: UpdateDirectoryMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: UpdateDirectoryMutation;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<UpdateDirectoryMutation>(
            UpdateDirectoryDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateDirectory',
        'mutation',
      );
    },
    auditLogEvent(
      variables: AuditLogEventQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: AuditLogEventQuery;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<AuditLogEventQuery>(
            AuditLogEventDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'auditLogEvent',
        'query',
      );
    },
    auditLogEvents(
      variables?: AuditLogEventsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: AuditLogEventsQuery;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<AuditLogEventsQuery>(
            AuditLogEventsDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'auditLogEvents',
        'query',
      );
    },
    auditLogExport(
      variables: AuditLogExportQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: AuditLogExportQuery;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<AuditLogExportQuery>(
            AuditLogExportDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'auditLogExport',
        'query',
      );
    },
    auditLogTargets(
      variables?: AuditLogTargetsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: AuditLogTargetsQuery;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<AuditLogTargetsQuery>(
            AuditLogTargetsDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'auditLogTargets',
        'query',
      );
    },
    auditLogValidators(
      variables?: AuditLogValidatorsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: AuditLogValidatorsQuery;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<AuditLogValidatorsQuery>(
            AuditLogValidatorsDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'auditLogValidators',
        'query',
      );
    },
    ConnectionSession(
      variables: ConnectionSessionQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: ConnectionSessionQuery;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<ConnectionSessionQuery>(
            ConnectionSessionDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'ConnectionSession',
        'query',
      );
    },
    ConnectionSessions(
      variables?: ConnectionSessionsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: ConnectionSessionsQuery;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<ConnectionSessionsQuery>(
            ConnectionSessionsDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'ConnectionSessions',
        'query',
      );
    },
    Connections(
      variables?: ConnectionsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: ConnectionsQuery;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<ConnectionsQuery>(
            ConnectionsDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'Connections',
        'query',
      );
    },
    Directories(
      variables?: DirectoriesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: DirectoriesQuery;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<DirectoriesQuery>(
            DirectoriesDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'Directories',
        'query',
      );
    },
    DirectoryCustomAttributes(
      variables: DirectoryCustomAttributesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: DirectoryCustomAttributesQuery;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<DirectoryCustomAttributesQuery>(
            DirectoryCustomAttributesDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DirectoryCustomAttributes',
        'query',
      );
    },
    DirectoryOverview(
      variables: DirectoryOverviewQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: DirectoryOverviewQuery;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<DirectoryOverviewQuery>(
            DirectoryOverviewDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DirectoryOverview',
        'query',
      );
    },
    DirectoryUsers(
      variables: DirectoryUsersQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: DirectoryUsersQuery;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<DirectoryUsersQuery>(
            DirectoryUsersDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DirectoryUsers',
        'query',
      );
    },
    Directory(
      variables: DirectoryQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: DirectoryQuery;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<DirectoryQuery>(
            DirectoryDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'Directory',
        'query',
      );
    },
    InitialPortal(
      variables?: InitialPortalQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: InitialPortalQuery;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<InitialPortalQuery>(
            InitialPortalDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'InitialPortal',
        'query',
      );
    },
    PortalSession(
      variables?: PortalSessionQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data: PortalSessionQuery;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<PortalSessionQuery>(
            PortalSessionDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'PortalSession',
        'query',
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;

import { Button, Text } from '@workos-inc/component-library';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import { FC, useState } from 'react';
import { useFeature } from '../../../utils/feature-flags';

interface FeedbackProps {}

type FeedbackType = 'Positive' | 'Negative';

export const Feedback: FC<Readonly<FeedbackProps>> = ({}) => {
  const [feedbackType, setFeedbackType] = useState<FeedbackType>();
  const feedbackEnabled = useFeature('admimPortalStepsFeedback');
  const [feedback, setFeedback] = useState<string>();
  const [submitState, setSubmitState] = useState<
    'idle' | 'waiting' | 'success'
  >('idle');

  if (!feedbackEnabled) {
    return null;
  }

  if (submitState === 'success') {
    return (
      <motion.div
        animate={{ opacity: 1 }}
        className="h-36"
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
      >
        <Text as="span" size="medium">
          Thank you for helping us improve our services!
        </Text>
      </motion.div>
    );
  }

  if (feedbackType) {
    const feedbackDescription =
      feedbackType === 'Positive'
        ? 'What was most helpful?'
        : 'What can we improve?';

    const handleSubmit = () => {
      if (submitState === 'waiting') {
        return;
      }
      setSubmitState('waiting');

      // TODO:
      // Remove in https://linear.app/workos/issue/ADMIN-514/hook-up-zendesk-mutation-to-ui-when-detailed-feedback-is-submitted
      setTimeout(() => {
        setSubmitState('success');
      }, 2000);
    };

    return (
      <motion.div
        key="feedback-message"
        animate={{ opacity: 1 }}
        className="flex flex-col items-start gap-2 h-36"
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
      >
        <Text as="span" size="medium">
          {feedbackDescription}
        </Text>
        <textarea
          className={classnames(
            'h-20 w-full rounded border transition duration-200 ease-in-out',
            'border-gray-lightmode-200 text-gray-lightmode-400 placeholder:text-gray-lightmode-300',
            'dark:border-gray-darkmode-200 dark:bg-gray-darkmode-50 dark:text-gray-darkmode-400 dark:placeholder:text-gray-darkmode-300',
            'selection:bg-primary-lightest selection:text-gray-lightmode-400 selection:dark:bg-primary selection:dark:text-white',
            'bg-white hover:border-gray-lightmode-300',
            'focus:ring-primary/20 focus:border-primary focus:bg-white focus:outline-none focus:ring-4',
            'p-2 text-xs leading-4',
          )}
          id="feedback"
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Feedback"
        ></textarea>
        <Button
          disabled={submitState === 'waiting' || !feedback}
          isLoading={submitState === 'waiting'}
          onClick={handleSubmit}
          size="small"
        >
          Submit
        </Button>
      </motion.div>
    );
  }

  const handleNegativeFeedback = () => {
    // TODO: https://linear.app/workos/issue/ADMIN-513/hook-up-segment-mutation-to-thumbs-up-down-ui
    setFeedbackType('Negative');
  };
  const handlePositiveFeedback = () => {
    // TODO: https://linear.app/workos/issue/ADMIN-513/hook-up-segment-mutation-to-thumbs-up-down-ui
    setFeedbackType('Positive');
  };

  return (
    <motion.div
      key="feedback-type"
      animate={{ opacity: 1 }}
      className="flex flex-col gap-2 h-36"
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
    >
      <Text as="span" size="medium">
        Was this page helpful?
      </Text>

      <div className="flex gap-2">
        <Button
          appearance="minimal"
          onClick={handleNegativeFeedback}
          size="small"
        >
          No
        </Button>
        <Button
          appearance="minimal"
          onClick={handlePositiveFeedback}
          size="small"
        >
          Yes
        </Button>
      </div>
    </motion.div>
  );
};

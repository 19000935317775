import { PropsWithChildren, ReactElement, useCallback, useState } from 'react';
import { ConnectionState } from '../../../../graphql/generated';
import { AnyObject } from '../../../hooks/use-record-state';
import { usePortalProgressionStore } from '../../../shared/portal-progression-provider';
import {
  HandleSetPortalProgressionOptions,
  Step,
  StepNavigation,
  StepNavigationProps,
  StepWithPath,
} from '../../../shared/step-navigation';
import { useSsoStore } from '../sso-store-provider';
import { SwitchConnectionModal } from '../switch-connection-modal';
import { getSsoClientSideFormErrors } from '../utils/get-sso-client-side-form-errors';
import { setConnectionPortalProgression } from '../utils/set-connection-portal-progression';
import {
  SsoStepCompleteEvent,
  trackSsoStepCompleteEvent,
} from '../utils/track-sso-complete-event';

interface SsoStepNavigationProps<TFormData> {
  steps: Step<TFormData>[];
}

interface SsoStepNavigationEnhancerProps<TFormData extends AnyObject> {
  children: (props: StepNavigationProps<TFormData>) => ReactElement;
  onClickSwitchProvider: () => void;
  steps: Step<TFormData>[];
}

export const SsoStepNavigationEnhancer = <TFormData extends AnyObject>({
  children,
  steps,
  onClickSwitchProvider: handleSwitchProvider,
}: SsoStepNavigationEnhancerProps<TFormData>) => {
  const { connection, updateConnection } = useSsoStore();
  const { setPortalProgressionStore } = usePortalProgressionStore();

  const stepsWithPath: StepWithPath<TFormData>[] = steps.map((step, index) => ({
    ...step,
    path: `/sso/configure/${index + 1}`,
  }));

  const ssoStepsEventTracker = useCallback(
    (stepsEventInfo: Omit<SsoStepCompleteEvent<TFormData>, 'connectionType'>) =>
      trackSsoStepCompleteEvent({
        connectionType: connection.type,
        ...stepsEventInfo,
      }),
    [connection.type],
  );

  const handleSetPortalProgression = async ({
    currentStep,
    currentStepIdentifier,
    formData,
    lastCompletedStepNumber,
  }: HandleSetPortalProgressionOptions<TFormData>): Promise<void> => {
    if (
      currentStep?.confirmationStep &&
      connection.state === ConnectionState.Active
    ) {
      return;
    }

    await setConnectionPortalProgression({
      connection,
      currentStepIdentifier,
      formData,
      lastCompletedStepNumber,
      setPortalProgressionStore,
    });
  };

  const handleUpdateWithChanges = async (formData: AnyObject) => {
    await updateConnection(formData);
  };

  return children({
    clientSideFormValidator: getSsoClientSideFormErrors,
    onClickSwitchProvider: handleSwitchProvider,
    onSetPortalProgression: handleSetPortalProgression,
    onUpdateWithChanges: handleUpdateWithChanges,
    provider: connection.type,
    steps: stepsWithPath,
    stepsCompleteEventTacker: ssoStepsEventTracker,
    stepsUrlBase: '/sso/configure',
    switchProviderText: 'Switch Identity Provider',
  });
};

export const SsoStepNavigation = <TFormData extends AnyObject>({
  children,
  steps,
}: PropsWithChildren<SsoStepNavigationProps<TFormData>>) => {
  const [isSwitchProviderModalOpen, setIsSwitchProviderModalOpen] =
    useState(false);

  return (
    <SsoStepNavigationEnhancer<TFormData>
      onClickSwitchProvider={() => setIsSwitchProviderModalOpen(true)}
      steps={steps}
    >
      {(stepNavigationProps) => (
        <StepNavigation<TFormData> {...stepNavigationProps}>
          {children}

          <SwitchConnectionModal
            open={isSwitchProviderModalOpen}
            setOpen={setIsSwitchProviderModalOpen}
          />
        </StepNavigation>
      )}
    </SsoStepNavigationEnhancer>
  );
};

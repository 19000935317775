import * as E from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import * as jose from 'jose';
import { graphql } from './graphql';
import { QueryParams } from './use-query-string';

const isDate = (date: number): boolean =>
  new Date(date).toString() !== 'Invalid Date' &&
  !isNaN(new Date(date).getTime());

export const getTelemetryData = (
  queryParams: QueryParams,
): { started_at: number; legacy: boolean } | void => {
  const legacy = !Boolean(queryParams.token);
  let startedAt: number | undefined;

  if (queryParams.started_at) {
    startedAt = Number(queryParams.started_at);
  } else if (queryParams.token) {
    try {
      const token = jose.decodeJwt(queryParams.token);

      if (typeof token === 'object') {
        const rawValue = token?.started_at;
        const startedAtValue = t.number.decode(rawValue);

        if (E.isRight(startedAtValue) && isDate(startedAtValue.right)) {
          startedAt = startedAtValue.right;
        }
      }
    } catch (error) {
      return;
    }
  }

  if (startedAt) {
    return { legacy, started_at: startedAt };
  }
};

const getTelemetryHooks = (queryParams: QueryParams) => {
  const telemetryData = getTelemetryData(queryParams);

  if (telemetryData) {
    void graphql().Ready(telemetryData);
  }

  const loaded = () => {
    if (telemetryData) {
      void graphql().Loaded(telemetryData);
    }
  };

  const ready = () => {
    if (telemetryData) {
      void graphql().Ready(telemetryData);
    }
  };

  return { loaded, ready };
};

export const withTelemetry = async <T>(
  queryParams: QueryParams,
  thunk: () => Promise<T>,
) => {
  const { loaded, ready } = getTelemetryHooks(queryParams);

  loaded();

  const response = await thunk();

  ready();

  return response;
};

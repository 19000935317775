import { createContext, FC, useContext, useMemo } from 'react';
import { PortalProgressionFragment } from '../../../graphql/generated';
import {
  RecordStateDispatcher,
  useRecordState,
} from '../../hooks/use-record-state';

export interface PortalProgressionStoreProviderProps {
  children: React.ReactNode;
  initialPortalProgression?: PortalProgressionFragment;
}

export type PortalProgressionStore = {
  portalProgression: PortalProgressionFragment | undefined | null;
};

type PortalProgressionStoreContext = PortalProgressionStore & {
  setPortalProgressionStore: RecordStateDispatcher<PortalProgressionStore>;
};

const PortalProgressionContext =
  createContext<PortalProgressionStoreContext | null>(null);

export const usePortalProgressionStore = () => {
  const context = useContext(PortalProgressionContext);

  if (!context) {
    throw new Error(
      'usePortalProgressionStore can only be used within PortalProgressionContext',
    );
  }

  return context;
};

export const PortalProgressionStoreProvider: FC<
  Readonly<PortalProgressionStoreProviderProps>
> = ({ children, initialPortalProgression }) => {
  const [portalProgressionStore, setPortalProgressionStore] =
    useRecordState<PortalProgressionStore>({
      portalProgression: initialPortalProgression,
    });

  const context = useMemo<PortalProgressionStoreContext>(
    () => ({ ...portalProgressionStore, setPortalProgressionStore }),
    [portalProgressionStore, setPortalProgressionStore],
  );

  return (
    <PortalProgressionContext.Provider value={context}>
      {children}
    </PortalProgressionContext.Provider>
  );
};

import { InputGroup } from '@workos-inc/component-library';
import { FC, Fragment } from 'react';
import { ArrowRight } from 'react-feather';

export type Attribute = {
  name: string;
  isCopyable?: boolean;
};

export type AttributeMappingProps = {
  labels?: string[];
  attributeGroups: Attribute[][];
};

export const AttributeMapping: FC<Readonly<AttributeMappingProps>> = ({
  labels,
  attributeGroups,
}) => (
  <div className="my-4 grid grid-cols-[1fr_min-content_1fr] items-center gap-4">
    {labels &&
      labels.map((label: string, index) => (
        <Fragment key={label}>
          <label className="-mb-2 flex-1 text-base font-medium">{label}</label>
          {index !== labels.length - 1 && <div />}
        </Fragment>
      ))}

    {attributeGroups.map((attributes: Attribute[], index) => (
      <Attributes key={index} attributes={attributes} />
    ))}
  </div>
);

type AttributesProps = {
  attributes?: Attribute[];
};

export const Attributes: FC<Readonly<AttributesProps>> = ({
  attributes = [],
}) => (
  <>
    {attributes.map(({ name, isCopyable = true }: Attribute, index: number) => (
      <Fragment key={name}>
        <InputGroup
          readOnly
          className="max-w-full justify-between"
          id={`attr-${name}`}
          isCopyable={isCopyable}
          name={`attr-${name}`}
          value={name}
        />

        {index !== attributes.length - 1 && (
          <ArrowRight className="mx-4" size={16} />
        )}
      </Fragment>
    ))}
  </>
);

export const getStepIdentifier = (
  stepNumber: number,
  stepTitle: string | undefined,
): string | undefined => {
  if (!stepTitle) {
    return;
  }

  if (typeof window !== 'undefined') {
    return btoa(`${stepNumber}-${stepTitle}`);
  }
};

import is from '@sindresorhus/is';
import axios, { AxiosResponse } from 'axios';
import * as E from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import * as jose from 'jose';
import Cookies from 'js-cookie';
import { ADMIN_PORTAL_API_URL_COOKIE } from './get-cookies';

export const Intent = t.keyof({
  dsync: null,
  sso: null,
  audit_logs: null,
});

export type Intent = t.TypeOf<typeof Intent>;

export const TokenPayload = t.strict({
  api_url: t.union([t.string, t.undefined]),
  intent: Intent,
  started_at: t.number,
  exp: t.number,
});

export const INTENT_COOKIE = '_admin_portal_intent';

export const getIntentFromCookie = (): Intent => {
  const rawCookieValue = Cookies.get(INTENT_COOKIE);

  const cookieValue = Intent.decode(rawCookieValue);

  if (E.isLeft(cookieValue)) {
    throw new Error('Invalid intent cookie');
  }

  return cookieValue.right;
};

export const getIntentFromTokenOrCookie = (
  token: string | undefined,
): Intent | undefined => {
  if (token) {
    return getTokenIntent(token);
  }

  try {
    return getIntentFromCookie();
  } catch {
    return;
  }
};

export const validateToken = async (token: string): Promise<AxiosResponse> => {
  try {
    const decodedToken = jose.decodeJwt(token);
    const tokenPayload = TokenPayload.decode(decodedToken);

    if (E.isLeft(tokenPayload)) {
      throw new Error('Invalid token');
    }

    const { api_url: customBrandedApiUrl, exp, intent } = tokenPayload.right;

    isTokenExpired(exp);

    if (customBrandedApiUrl) {
      Cookies.set(ADMIN_PORTAL_API_URL_COOKIE, customBrandedApiUrl);
    }

    const apiUrl = customBrandedApiUrl || process.env.NEXT_PUBLIC_API_URL;

    const response = await axios.post(
      `${apiUrl}/portal/init_session`,
      { token },
      { withCredentials: true },
    );

    const { csrf } = response.data;

    Cookies.set('_csrf', csrf);
    Cookies.set(INTENT_COOKIE, intent);

    return response;
  } catch (error) {
    if (is.error(error)) {
      throw error;
    }

    throw new Error(String(error));
  }
};

const getTokenIntent = (token: string): Intent => {
  const decoded = jose.decodeJwt(token);
  const tokenPayload = TokenPayload.decode(decoded);
  if (E.isRight(tokenPayload)) {
    return tokenPayload.right.intent;
  }

  throw new Error('Invalid Token');
};

const isTokenExpired = (expirationTime: number): boolean => {
  if (Date.now() >= expirationTime * 1000) {
    throw new Error('Token expired');
  }

  return false;
};
